import { GETPROJECTDETAIL, GETPROJECTLIST, GETTENDERLISTPROJECT, GETUSERLISTPROJECT,GETPROJECTCHILDDETAIL, GETDOCUMENTLISTPROJECT } from '../../reduxConstants/ProjectReducerConstants';
import { AuthFileaxios } from "../../api/axios";

export const GetProjectList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/project_list");
        if(data.code===200){
            dispatch({type:GETPROJECTLIST,payload:data?.project_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const DeleteProject = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/delete_project",param);
         if(data.code===200){
           dispatch(GetProjectList(Axios)) 
           return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddProjectList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_project",param);
        //console.log(data)
        if(data.code===201){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddProjectListNew = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_project_new",param);
        //console.log(data)
        if(data.code===201){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const DocumentDeleteProjectAdd =(Axios,param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/project_file_delete_ADD`,param)
         if(data.code===200){
            return data
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }


export const GetProjectDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/get_project_detail",param);
        //console.log(data)
        if(data.code===200){
            dispatch({type:GETPROJECTDETAIL,payload:data?.Detail[0]})
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateProjectDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_project_detail",param);
        //console.log(data)
        if(data.code===200){
            dispatch(GetProjectList(Axios))
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}


export const FileUploadProject = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/project_documents`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

 export const updateFileUploadProject = async(formData)=>{
    try {
        
         const {data} =await AuthFileaxios.post(`/project_documents_update`,formData)
        //  console.log(data)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }


 //

 export const DocumentDeleteProject =(Axios,param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/project_file_delete`,param)
         if(data.code===200){

             let ParamTwo = {
                "project_id": param.project_id
            }
            return dispatch(GetDocumentsForProjectById(Axios,ParamTwo))
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }


//  export const DocumentDeleteTenderEmpty =(Axios,param) =>async(dispatch)=>{
//     try {
//          const {data} =await AuthFileaxios.post(`/project_file_delete`,param) ;    
//         //   console.log(data)
//          if(data.code===200){
      
//          }
//     } catch (error) {
//      return error?.response?.data
//     }
//  }




export const GetDocumentsForProjectById = (Axios,param) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/project_document_list",param);
        //  console.log(data)
        if(data.code===200){
            dispatch({type:GETDOCUMENTLISTPROJECT,payload:data})
            return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetDocumentsForProjectById2nd = (Axios,param) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/project_document_list",param);
        //  console.log(data)
        if(data.code===200){
            return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}


export const GetChildDetailProject = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/get_project_child_detail",param);

        console.log(data,"datachild")

        if(data.code===200){
            
            dispatch({type:GETPROJECTCHILDDETAIL,payload:data?.Detail})
            return data?.Detail
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const GetChildDetailProject2 = async (Axios,param) => {
    try{
        const {data} = await Axios.post("/get_project_child_detail",param);

        console.log(data,"datachild")

        if(data.code===200){
            return data?.Detail
        }
    }
    catch(error){
        return error?.response?.data
    }
}

 export const AddChildProjectList = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/add_project_child",param);
        if(data.code===201){
            dispatch(GetChildDetailProject(Axios,param))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const UpdateProjectChildDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_project_child_detail",param);
        // console.log(data)
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}



export const GetUserListProject = (Axios) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/all_user_list");
        
        if(data.code===200){
            dispatch({type:GETUSERLISTPROJECT,payload:data?.userList})
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetTenderListProject = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/tender_list");
        
        if(data.code===200){
            dispatch({type:GETTENDERLISTPROJECT,payload:data?.tender_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}