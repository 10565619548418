import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from './styles/ctrlFldsBidFnc.module.css'

const UpdateBidFunction = ({item,handleList}) => {  
    const [bidFunction,setBidFunction]=useState(item);


    const handleChange=(e)=>{
        setBidFunction({
            ...bidFunction,
            bf_name:e.target.value})
        handleList(bidFunction,e.target.value)
    }

    return (
        <div>
            {/* <input className={styles.CtrlFldsItemInput} type="text" name="bf_name" value={bidFunction.bf_name} onChange={handleChange}/> */}
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" autoComplete="off" type="text" name="bf_name" value={bidFunction.bf_name} onChange={handleChange} />
        </div>
    )
}

export default UpdateBidFunction