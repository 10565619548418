import { AuthFileaxios } from "../../api/axios";
import { GETCONSULTANTDOWNLOADLIST, GETCONTRACTORDOWNLOADLIST, GETPROJECTDOWNLOADLIST, GETTENDERDOWNLOADLIST } from "../../reduxConstants/DowloadReducerConstant";

export const GetTenderDownloadList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/download_get_all_tender");
        if(data.code===200){
            dispatch({type:GETTENDERDOWNLOADLIST,payload:data.tender_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetTenderDocumentListByID = async (Axios,param) => {
    try{
        const {data} = await Axios.post("/download_get_documents_by_tender_id",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const DocumentDeleteTenderDownloadsByID = async(param) =>{
    try {
         const {data} = await AuthFileaxios.post(`/tender_file_delete`,param)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

export const GetProjectDownloadList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/download_get_all_project");
        if(data.code===200){
            dispatch({type:GETPROJECTDOWNLOADLIST,payload:data.project_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetProjectDocumentListByID = async (Axios,param) => {
    try{
        const {data} = await Axios.post("/download_get_documents_by_project_id",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const DocumentDeleteProjectDownloadsByID =async(param) =>{
    try {
         const {data} =await AuthFileaxios.post(`/project_file_delete`,param)
         if(data.code===200){
            return data
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }


export const GetContractorDownloadList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/download_get_all_contract");
        if(data.code===200){
            dispatch({type:GETCONTRACTORDOWNLOADLIST,payload:data.contract_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetContractorDocumentListByID = async (Axios,param) => {
    try{
        const {data} = await Axios.post("/download_get_documents_by_contract_id",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const DocumentDeleteContractorDownloadsByID = async(param)=>{
    try {
        console.log(param)
         const {data} =await AuthFileaxios.post(`/contract_file_delete`,param);
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }
 export const DocumentDeleteContractorInvoiceByID =async (param)=> {
    try {
         const {data} =await AuthFileaxios.post(`/contract_file_delete_invoice`,param);
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }


export const GetConsultantDownloadList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/download_get_all_consultant");
        if(data.code===200){
            dispatch({type:GETCONSULTANTDOWNLOADLIST,payload:data.consultant_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetConsultantDocumentListByID = async (Axios,param) => {
    try{
        const {data} = await Axios.post("/download_get_documents_by_consultant_id",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const DocumentDeleteConsultantByID =async(param) =>{
    try {
         const {data} =await AuthFileaxios.post(`/consultant_file_delete`,param)
         if(data.code===200){
            return data
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }