import React, { useState,useEffect } from 'react'
import styles from "./styles/ctrlFldsBidSector.module.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddBidSector from './AddBidSector';
import { useDispatch, useSelector } from 'react-redux'
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateBidSector from './UpdateBidSector';
import { GetBidSectorList, UpdateBidSectorApi } from '../../../actions/controlFields/controlFieldsActions';
import { Card } from '@mui/material';
import { CancelButton, CtrlFldsSubmitButton } from '../../../utility/button/button';

const CtrlFldsBidSector = () => {
    const store = useSelector(state => state.ControlFields);
    const [BidSectorList, setBidSectorList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
      dispatch(GetBidSectorList(Axios))
    }, [])

    useEffect(
        ()=>{
        setBidSectorList(store?.BidSectorList)
    },[store.BidSectorList])

    let toggleEdit = () => {
        setShow(!show)
    }
    const handleList = (bs, value) => {
        setBidSectorList(BidSectorList.map(item => item.bs_id === bs.bs_id ? {
            ...bs,
            bs_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setBidSectorList(store?.BidSectorList)
    }

    const IsValid = () => {
        let success = true;
        const data = BidSectorList.map(item => ((item?.bs_name === '') || item?.bs_name?.length === 0 || item?.bs_name === undefined || item?.bs_name === null) ? true : false);

        if (data.includes(true)) {
            success = false;
            toast.error("Bid Sector Fields Cannot Be Empty!!")
        } else {
            success = true
        }
        return success
    }

    const handleSubmit = async () => {
        if (IsValid()) {
            setLoading(true)
        let param = {
            "list": BidSectorList
        }

        const data = await dispatch(UpdateBidSectorApi(Axios, param));

        if (data?.code === 200) {
            setLoading(false)
            setShow(false)
            toast.success('Updated Bid Sector SuccessFully')
        }
        else {
            setLoading(false)
            toast.error(`${data?.message}`)
        }
    }
    }
    return (
        <>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.CtrlFields}>
            {/* <div className={styles.CtrlFldTitle}>Admin Control Fields</div> */}
            <div className={styles.CtrlFldsBodyContainer}>
                <div className={styles.CtrlFldsBodyContent}>
                    <div className={styles.CtrlFldsBodyTitle}>
                        <h2>Bid Sector</h2>
                    </div>
                    <AddBidSector/>
                    <div className={styles.Seperator} >
                        <div className={styles.SeperatorLine}></div>
                        <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                    </div>
                    <div className={styles.CtrlFldsBody}>
                        <div className={styles.CtrlFldsBodyItems}>
                            {
                                BidSectorList?.map(
                                    (item, key) =>
                                    (
                                        show ?
                                            <UpdateBidSector item={item} handleList={handleList}/> :
                                            <Card className={styles.listCard}>{item.bs_name}</Card>
                                    )
                                )
                            }

                        </div>
                        {
                            show === true ?
                                <div className={styles.CtrlFldsSubmitEdit}>
                                    <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                </div> : null
                        }

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}


export default CtrlFldsBidSector