export const columnData = {
    project_title:true,
    project_manager_name:false,
    internal_team_member:true,
    contract_start_date:true,
    contract_closure_date:true,
    contract_value:false,
    performance_security_date:false,
    performance_security_amount:false,
    pip_approved:false,
    budgeted_cost:true,
    target_net_revenue:false,
    project_sheet_submitted:false,
    deliverables_no:true,
    project_certificate:false,
    performance_security_refund:false,
    comments:false,
    created_at:false,
    updated_at:false,
    updated_by_name: false,
    added_by: false 
}
export const SelectALLData = {
    project_title:true,
    project_manager_name:true,
    internal_team_member:true,
    contract_start_date:true,
    contract_closure_date:true,
    contract_value:true,
    performance_security_date:true,
    performance_security_amount:true,
    pip_approved:true,
    budgeted_cost:true,
    target_net_revenue:true,
    project_sheet_submitted:true,
    deliverables_no:true,
    project_certificate:true,
    performance_security_refund:true,
    comments:true,
    created_at:true,
    updated_at:true,
    updated_by_name: true,
    added_by: true 
}