import * as React from 'react';
import { Routes, Route, useNavigate,Navigate, useLocation } from "react-router-dom"
import UserMgt from './components/userMgt/UserMgt';
import SignIn from './components/SignIn/SignIn';
import AddTender from './components/OppBidAddTender/AddTender';
import Catalougue from './components/OppBidAddTender/Catalougue';
import ProjectManagementDetails from './components/ProjectManagement/ProjectManagementDetails';
import AddProject from './components/ProjectManagement/AddProject';
import ContractManagementDetails from './components/ContractsManagement/ContractManagementDetails';
import AddContractForm from './components/ContractsManagement/ContractManagementForm';
import ConsultantsIndex from './components/ConsultantsIndex/ConsultantsIndex';
import AddConsultant from './components/ConsultantsIndex/AddConsultant';
import ControlFieldsMgt from './components/CtrlFields';
import Home from './components/HomePage/Home';
import { useSelector } from 'react-redux';
import PersistLogin from './hooks/PersistLogin';
import RequireAuth from './hooks/RequiredAuth';
import UnAuthorized from './components/UnAuthorized/UnAuthorized';
import Documents from './components/Documents/Documents'
import { ADDPROJECTALLOWED, ADMIN, OPPORTUNITY } from './hooks/AllowedRoles';
import {MissingRoute} from './hooks/MissingRoute';
import ComingSoon from './components/ComingSoon/ComingSoon';
import UpdateTender from './components/OppBidAddTender/UpdateTender';
import UpdateProject from './components/ProjectManagement/UpdateProject';
import AddProjectNew from './components/ProjectManagement/AddProjectNew';
import DocumentMgt from './components/Documents/DocumentMgt';
import UpdateContractManagement from './components/ContractsManagement/UpdateContractManagement';
import UpdateConsultant from './components/ConsultantsIndex/UpdateConsultant';
import DownloadIndex from './components/Downloads/DownloadIndex';






function ResponsiveDrawer(props) {
  const store = useSelector(state=>state.Login)
  const location = useLocation();
  // const store = useSelector(state => state.Login)
  // const navigate = useNavigate()

  // // React.useEffect(() => {
  // //   if (store?.access_token && store?.user_id !== 0) {
  // //     navigate("/")
  // //   }
  // //   else {
  // //     navigate("/Login")
  // //   }
  // // }, [store])

  return (
    <>
      <Routes> 
      <Route element={<PersistLogin />}>
        <Route path="/Login" element={<SignIn />} />
       
             <Route path="*" element={<MissingRoute  code = {store?.role?.role_code}/>}/>
             <Route path="/" element={<MissingRoute code = {store?.role?.role_code}/>}/>
             <Route path="/" element={ store?.access_token ? <Home /> : <Navigate to="/login" state={{ from: location }} replace />}>
         
            <Route path='/unAuthorized' element={<UnAuthorized />} />
            <Route element={<RequireAuth  allowedRoles={ADMIN}/>}>
              <Route path="UserManagement" element={<UserMgt />} />
            </Route>
            <Route element={<RequireAuth  allowedRoles={ADMIN}/>}>
              <Route path="ControlFields" element={<ControlFieldsMgt />} />
            </Route>
            <Route element={<RequireAuth  allowedRoles={ADMIN}/>}>
              <Route path="Downloads" element={<DownloadIndex />} />
            </Route>

            
            <Route element={<RequireAuth  allowedRoles={OPPORTUNITY}/>}>
              <Route path="/Catalogue/Page:id/*" element={<Catalougue />} >
                  {/* <Route  path="updateTender" element={<UnAuthorized />} /> */}  
                 
              </Route>

             <Route exact path="/Catalogue/Page:id/updateTender/:id" element={<UpdateTender />} />

            </Route>  
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              <Route path="AddTender" element={<AddTender />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              <Route path="/ProjectsIndex/Page:id/*" element={<ProjectManagementDetails />} >
                  {/* <Route  path="updateProject" element={<UnAuthorized />} /> */}
              </Route>
              <Route exact path="/ProjectsIndex/Page:id/updateProject/:id" element={<UpdateProject />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={ADDPROJECTALLOWED}/>}>
              <Route path="AddProject" element={<AddProject/>} />
            </Route>
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              <Route path="/ContractorDetail/Page:id/*" element={<ContractManagementDetails />}>
              </Route>
              <Route exact path="/ContractorDetail/Page:id/updateContractor/:id" element={<UpdateContractManagement />} />
            </Route>
            
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              
              <Route path="AddContractor" element={<AddContractForm />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              <Route path="/ConsultantsDetail/*" element={<ConsultantsIndex />}>
              </Route>
              <Route exact path="/ConsultantsDetail/updateConsultant/:id" element={<UpdateConsultant />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={OPPORTUNITY}/>}>
              <Route path="AddConsultant" element={<AddConsultant />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={OPPORTUNITY} />}>
              <Route path="/documents/*" element={<DocumentMgt />} />
              {/* <Route path="/Documents/path" element={<DocumentMgt />} /> */}
            </Route>
            <Route path='comingsoon1'element={<ComingSoon/>} />
                <Route path='comingsoon2'element={<ComingSoon/>} />
                <Route path='comingsoon3'element={<ComingSoon/>} />
                <Route path='comingsoon4'element={<ComingSoon/>} />
                <Route path='comingsoon5'element={<ComingSoon/>} />
                <Route path='comingsoon6'element={<ComingSoon/>} />
                <Route path='comingsoon7'element={<ComingSoon/>} />

          </Route>

        </Route>
      </Routes>
    </>

  );
}


export default ResponsiveDrawer;
