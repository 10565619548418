import axios from 'axios'
import { GetRefreshTokenLocalStorage } from '../hooks/LoginLocalStorage';
import { BASE_URL,BASE_FILE_URL, BASE_LOGIN_URL } from '../utility/baseUrl';

const token = GetRefreshTokenLocalStorage('jwt')

export const Axios = axios.create({
    baseURL:BASE_LOGIN_URL,
    headers:{
        "Content-Type": "application/json",
        'Authorization':`Bearer ${token}`
    },
    withCredentials:true
})

export const Authaxios = axios.create({
    baseURL:BASE_URL,
    headers:{
        "Content-Type": "application/json",
    }
    ,
    withCredentials:true
})

export const AuthFileaxios = axios.create({
    baseURL:BASE_FILE_URL,
    headers:{
        "Content-Type": "multipart/form-data",
    }
    ,
    withCredentials:true
})