import { GETBIDCATEGORYLISTTENDER, GETBIDFUNCTIONLISTTENDER,GETTENDERDETAIL, GETBIDSECTORLISTTENDER, GETDONORLISTTENDER, GETFINANCIALLISTTENDER, GETMODELISTTENDER, GETNOTICELISTTENDER, GETROLELISTTENDER, GETSTATELISTTENDER, GETTENDERLIST, GETUSERLISTTENDER } from "../reduxConstants/tenderReducerConstants";

const initialState = {
    tenderList: [],
    tenderStateList: [],
    tenderDonorList: [],
    tenderNoticeList: [],
    tenderModeList: [],
    tenderFinancialList: [],
    tenderBidCategoryList: [],
    tenderBidFunctionList: [],
    tenderBidSectorList: [],

    tenderDetail:{},
    tenderUserList: [],
    tenderRoleList: []
}


export default (state = initialState, actions) => {
    switch (actions.type) {
        case GETTENDERLIST:
            return {
                ...state,
                tenderList: actions.payload
            }
        case GETSTATELISTTENDER:
            return {
                ...state,
                tenderStateList: actions.payload
            }
        case GETDONORLISTTENDER:
            return {
                ...state,
                tenderDonorList: actions.payload
            }
        case GETNOTICELISTTENDER:
            return {
                ...state,
                tenderNoticeList: actions.payload
            }
        case GETMODELISTTENDER:
            return {
                ...state,
                tenderModeList: actions.payload
            }
        case GETFINANCIALLISTTENDER:
            return {
                ...state,
                tenderFinancialList: actions.payload
            }
        case GETBIDCATEGORYLISTTENDER:
            return {
                ...state,
                tenderBidCategoryList: actions.payload
            }
        case GETBIDFUNCTIONLISTTENDER:
            return {
                ...state,
                tenderBidFunctionList: actions.payload
            }
        case GETBIDSECTORLISTTENDER:
            return {
                ...state,
                tenderBidSectorList: actions.payload
            }

        case GETUSERLISTTENDER:
            return{
                ...state,
                tenderUserList: actions.payload
            }
        case GETROLELISTTENDER:
                return{
                    ...state,
                    tenderRoleList: actions.payload
                }
        case GETTENDERDETAIL:
            return{
                ...state,
                tenderDetail:actions.payload
            }
        default:
            return state;
    }
}