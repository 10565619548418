import { GETCONTRACTORDETAIL, GETCONTRACTORTABLE, GETINVOICELIST, GETPROJECTLISTCONTRACTOR } from "../../reduxConstants/ContractorReducerConstants";
import { AuthFileaxios } from "../../api/axios";
import axios from "axios";

export const GetContractorList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.get("/get_all_contractor_list");
        if(data.code===200){
            // console.log(data?.list)
            dispatch({type:GETCONTRACTORTABLE,payload:data?.list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const DeleteContractor = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/delete_contract",param);
         if(data.code===200){
           dispatch(GetContractorList(Axios)) 
           return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetProjectListContractor = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/project_list");
        if(data.code===200){
            dispatch({type:GETPROJECTLISTCONTRACTOR,payload:data?.project_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const FileUploadContractor = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/contract_document_upload`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }
 export const FileDeleteAddContractor = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/contract_file_delete_ADD`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

 
export const AddContractList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_contract",param);
        //console.log(data)
        if(data.code===201){
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetContractorDetailList = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/get_contract_details",param);
        if(data.code===200){
            dispatch({type:GETCONTRACTORDETAIL,payload:data?.details})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetContractorDetailListExport = async(Axios,param) => {
    try{
        const {data} = await Axios.post("/get_contract_details",param);
        if(data.code===200){
            return data?.details
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateContractorDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_contract",param);
        if(data.code===200){
            dispatch(GetContractorList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const FileUploadContractorUpdate = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/contract_document_upload_update`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }
 export const FileUploadContractorInvoice = (formData,id,Axios)=>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/contract_invoice_upload`,formData)
         if(data.code===200){
            let paramDelete = {
                "ci_id":id
            }
            const LatestList = dispatch(GetInvoiceFileListContractor(Axios, paramDelete))
            return LatestList
         }
    } catch (error) {
        console.log(error?.response?.data)
     return error?.response?.data
    }
 }
 
export const GetDocumentsForContractorById = (Axios,param) =>async(dispatch) => {
    
    try{
        const {data} = await Axios.post("/contractor_documents",param);
        console.log(GetDocumentsForContractorById,"GetDocumentsForContractorById")
        if(data.code===200){
            return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

 export const FileDeleteUpdateContractor = (formData,Axios,id)=>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/contract_file_delete`,formData);

         if(data.code===200){
            let param = {
                contract_id : id
            }
            
            const Data = await dispatch(GetDocumentsForContractorById(Axios,param))
            return Data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

 export const GetInvoiceList = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/get_invoice_contract",param);
        if(data.code===200){
            dispatch({type:GETINVOICELIST,payload:data?.invoice_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const AddInvoiceList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_invoice",param);
        let paramTwo = {
            "contract_id" : param.contract_id
        }
         if(data.code===201){
           dispatch(GetInvoiceList(Axios,paramTwo)) 
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const DeleteInvoiceList = (Axios,param) =>async(dispatch)=> {
    try{
        let paramOne = {
            "ci_id" : param.ci_id
        }
        let paramTwo = {
            "contract_id" : param.contract_id
        }
        const {data} = await Axios.post("/delete_invoice",paramOne);
        
         if(data.code===201){
           dispatch(GetInvoiceList(Axios,paramTwo)) 
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const AddInvoicePaymentList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_invoice_payment",param);
        let paramTwo = {
            "contract_id" : param.contract_id
        }
         if(data.code===201){
           dispatch(GetInvoiceList(Axios,paramTwo)) 
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const DeleteInvoicePaymentList = (Axios,param) =>async(dispatch)=> {
    try{
        let paramOne = {
            "cip_id" : param.cip_id
        }
        let paramTwo = {
            "contract_id" : param.contract_id
        }
        const {data} = await Axios.post("/delete_invoice_payment",paramOne);
        
         if(data.code===201){
           dispatch(GetInvoiceList(Axios,paramTwo)) 
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetInvoiceFileListContractor = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/contractor_invoice_document_list", param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const InvoiceDocDeleteContractor = (formData,Axios,id)=>async(dispatch)=> {
    try {
         const {data} =await AuthFileaxios.post(`/contract_file_delete_invoice`,formData);
         console.log(data,formData,Axios,id)
         if(data.code===200){
             let paramDelete = {
                "ci_id":id
            }
            const LatestList = dispatch(GetInvoiceFileListContractor(Axios, paramDelete))
            return LatestList
         }
    } catch (error) {
        console.log(error)
     return error?.response?.data
    }
 }
