import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/catalougue.module.css";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckboxInput({ label, checked, name, handleChange}) {
    return (
        <>

            <FormControlLabel
                control={
                    <Checkbox checked={checked} name={name} onChange={handleChange} />
                }
                label={label}
            /></>
    )
}

function MyVerticallyCenteredModal(props) {
    const { columns, setColumns, toggleSet, state, setState,selectAll,handleSelectAll } = props;

    const MapColumn = Object.keys(state);


    const handleChange = (event) => {
        let arr = columns;  


        if (arr.includes(event.target.name)) {
            let Arrindex = arr.indexOf(event.target.name);
            setColumns(prev => {
                return prev.filter((item, i) => i !== Arrindex)
            })
        }
        else {
            arr.push(event.target.name);
            setColumns(arr)
        }
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit=()=>{
        toggleSet(false)
    }
    
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.PopUpBox}
        >
            <Modal.Header closeButton className={styles.PopUpHeader}>
                <Modal.Title className={styles.PopUpTitle}>
                    Select Columns
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >

            
            <CheckboxInput label="SELECT ALL" checked={selectAll} name="select_all" handleChange={handleSelectAll} />
       

                <div className={styles.ColumnSelect}>
                     {
                        MapColumn?.map((item,index)=>(
                            <div key={index}>
                                <CheckboxInput label={
                                          item === "nominated_user_name"? "NOMINATED USER" :
                                          item === "submission_date_time"? "SUBMISSION DATE" :
                                          item === "updated_by_name"? "UPDATED BY" :
                                          item === "tender_fee"? "BID/TENDER FEE" :
                                          item === "security_fee"? "BID SECURITY" :
                                    item.replace(/_/g,' ').toUpperCase()
                                    
                                    } checked={state[item]} name={item}  handleChange={handleChange} columns={columns} setColumns={setColumns} />
                                </div>
                        ))
                     }
                </div>
            </Modal.Body>
            <div className={styles.PopUpSubmit}>
                <button className={styles.PopUpSubmitButton} onClick={handleSubmit} >Submit</button>
            </div>

        </Modal>
    );
}
function CatalougueColumnModal(props) {
    let { toggle, toggleSet, columns, setColumns, state, setState,selectAll,handleSelectAll } = props



    return (
        <>
            <MyVerticallyCenteredModal
                show={toggle}
                onHide={() => toggleSet(!toggle)}
                columns={columns}
                setColumns={setColumns}
                toggleSet={toggleSet}
                state={state}
                setState={setState}
                selectAll = {selectAll}
                handleSelectAll={handleSelectAll}

            />
        </>
    );
}

export default CatalougueColumnModal