import React, { useState,useEffect } from 'react'
import styles from "./styles/ctrlFldsBidCat.module.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddBidCategory from './AddBidCategory';
import { useDispatch, useSelector } from 'react-redux'
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateBidCategory from './UpdateBidCategory';
import { GetBidCategoryList, UpdateBidCategoryApi } from '../../../actions/controlFields/controlFieldsActions';
import { Card } from '@mui/material';
import { CancelButton, CtrlFldsSubmitButton } from '../../../utility/button/button';

const CtrlFldsBidCat = () => {
    const store = useSelector(state => state.ControlFields);
    const [BidCategoryList, setBidCategoryList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
      dispatch(GetBidCategoryList(Axios))
    }, [])

    useEffect(
        ()=>{
        setBidCategoryList(store?.BidCategoryList)
    },[store.BidCategoryList])

    let toggleEdit = () => {
        setShow(!show)
    }
    const handleList = (bc, value) => {
        setBidCategoryList(BidCategoryList.map(item => item.bc_id === bc.bc_id ? {
            ...bc,
            bc_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setBidCategoryList(store?.BidCategoryList)
    }

    const IsValid = () => {
        let success = true;
        const data = BidCategoryList.map(item => ((item?.bc_name === '') || item?.bc_name?.length === 0 || item?.bc_name === undefined || item?.bc_name === null) ? true : false);

        if (data.includes(true)) {
            success = false;
            toast.error("Bid Category Fields Cannot Be Empty!!")
        } else {
            success = true
        }
        return success
    }

    const handleSubmit = async () => {
        if (IsValid()) {
            setLoading(true)
        let param = {
            "list": BidCategoryList
        }

        const data = await dispatch(UpdateBidCategoryApi(Axios, param));

        if (data?.code === 200) {
            setLoading(false)
            setShow(false)
            toast.success('Updated Bid Category SuccessFully')
        }
        else {
            setLoading(false)
            toast.error(`${data?.message}`)
        }
    }
    }
    return (
        <>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.CtrlFields}>
            {/* <div className={styles.CtrlFldTitle}>Admin Control Fields</div> */}
            <div className={styles.CtrlFldsBodyContainer}>
                <div className={styles.CtrlFldsBodyContent}>
                    <div className={styles.CtrlFldsBodyTitle}>
                        <h2>Bid Category</h2>
                    </div>
                    <AddBidCategory/>
                    <div className={styles.Seperator} >
                        <div className={styles.SeperatorLine}></div>
                        <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                    </div>
                    <div className={styles.CtrlFldsBody}>
                        <div className={styles.CtrlFldsBodyItems}>
                            {
                                BidCategoryList?.map(
                                    (item, key) =>
                                    (
                                        show ?
                                            <UpdateBidCategory item={item} handleList={handleList}/> :
                                            <Card className={styles.listCard}>{item.bc_name}</Card>
                                         
                                    )
                                )
                            }

                        </div>
                        {
                            show === true ?
                                <div className={styles.CtrlFldsSubmitEdit}>
                                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                </div> : null
                        }

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}


export default CtrlFldsBidCat