import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import styles from './styles/checkBox.module.css'

const CheckboxComponent = ({label,checked,handleCheckBoxChange,setChecked}) => {
  return (
//     <>
//   <FormControlLabel sx={{size:'small'}}
//       control={
//         <Checkbox checked={checked} name={name} onChange={handleCheckBoxChange}/>
//       }
//       label={label}  
//     /></>
<div className={styles.CheckboxWrapper}>
 <input type="checkbox" checked={checked} name={label} onChange={handleCheckBoxChange} />
<label className={styles.checklabel}>
    {label.toUpperCase()}
</label>
</div>

  )
}

export default CheckboxComponent