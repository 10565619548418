import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles/consultantsIndex.module.css'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, Link } from 'react-router-dom';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { BsPencil } from "react-icons/bs";
import DownloadIcon from '@mui/icons-material/Download';
import exportFromJSON from "export-from-json"
import { DeleteConsultant, GetBidSectorListConsultant, GetConsultantDetail, GetConsultantDocumentList2, GetConsultantList, GetExpertiseListConsultant, GetStateListConsultant } from '../../actions/consultant/ConsultantAction';
import { NormalDateFormat } from '../../utility/DateFunction';
import { CONSULTANTEXPORTALLOWED, OPPORTUNITYEXPORTALLOWED } from '../../hooks/AllowedRoles';
import { Autocomplete, Button, TextField } from '@mui/material'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'save-as';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SearchFilter } from '../../utility/searchFunction';
import DeleteIcon from '@mui/icons-material/Delete';
import DeletePromptConsultant from './DeletePromptConsultant';

const ConsultantsIndex = () => {
  const [remove,setRemove]=useState(false);
  const [user,setUser]=useState({})
  // const Axios = useAxiosPrivate()
  const theader = [
    "Name",
    'Email',
    'Cell no.',
    'Type',
    'Experience',
    'State',
    'Added On',
    'Sector',
    // 'Expertise',
    'comments',
    'Download',
    'Edit'
  ]
  const [consultants, setConsultants] = useState([])
  const navigate = useNavigate()
  const store = useSelector(state => state.Consultant)
  const LoginStore = useSelector(state => state.Login)
  const Axios = useAxiosPrivate()
  const dispatch = useDispatch()
  const [filterList, setFilterList] = useState({
    sectorList: [],
    expertiseList: [],
    stateList: [],
    stateName: "",
    sectorName: "",
    expertiseName: "",
    stateId: "",
    sectorId: "",
    expertiseId: ""

  });
  const [filteredList, setFilteredList] = useState([])
  useEffect(() => {
    dispatch(GetConsultantList(Axios))
    dispatch(GetStateListConsultant(Axios))
    dispatch(GetBidSectorListConsultant(Axios))
    dispatch(GetExpertiseListConsultant(Axios))
  }, [])

  useEffect(() => {
    let key_name = "state_id";
    const sorted = store?.consultantList?.slice().sort((a, b) => key_name.endsWith("date") || key_name.endsWith("time") ? new Date(a[key_name]) - new Date(b[key_name]) : a[key_name] - b[key_name])
    setConsultants(sorted);

    let stateArr = ['All'];
    let sectorArr = ['All'];
    let expertiseArr = ['All'];

    store.stateListConsultant?.map(item => stateArr.push(item.state_name));
    store.sectorListConsultant?.map(item => sectorArr.push(item.bs_name));
    store.expertiseListConsultant?.map(item => expertiseArr.push(item.expertise_name))

    setFilterList({
      stateList: stateArr,
      sectorList: sectorArr,
      expertiseList: expertiseArr,
      stateName: "All",
      sectorName: "All",
      expertiseName: "All",
      stateId: 0,
      sectorId: 0,
      expertiseId: 0
    })

  }, [store])

  const handleNavigate = () => {
    navigate('/AddConsultant')
  }

  const handleConsultantDetail = async (item) => {
    navigate(`/ConsultantsDetail/updateConsultant/${item?.cr_id}`)
    let param = {
      "cr_id": item?.cr_id
    }
    dispatch(GetConsultantDetail(Axios, param))
  }
  const ExportToExcel = () => {
    if (CONSULTANTEXPORTALLOWED.includes(LoginStore?.role?.role_code)) {
        let ExpertiseString = ""
        let data = store?.consultantList?.map((elem) => {
             let stateName = store?.stateListConsultant?.filter((state) => state?.state_id === elem.state_id ? state?.state_name : null)
        let sectorName = store?.sectorListConsultant?.filter((sector) => sector?.bs_id === elem.sector_id ? sector?.bs_name : null)
        elem.expertise_list?.map((expertise,i)=> expertise.expertise_name ?( ((elem.expertise_list.length)-1 !== i) ? ExpertiseString= ExpertiseString  + expertise.expertise_name + ", " : ExpertiseString= ExpertiseString  + expertise.expertise_name) : "")
        return {
          'Cell No': elem.cell_no,
          'Comments': elem.comments,
          'Created At': NormalDateFormat(elem.created_at),
          'Expertise': ExpertiseString,
          'Email': elem.email,
          'Experience': elem.experience,
          'Name': elem.name,
          'Sector': sectorName[0]?.bs_name,
          'State': stateName[0]?.state_name,
          'Type': elem.type,
          'Updated At': NormalDateFormat(elem.updated_at),
          // 'Updated By': elem.updated_by
        }
        })
     
        const fileName = `Consultant Data`
        const exportType = exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
    }
    else{
      toast.error("No Access to Download")
    }
}
// console.log(store)

  const handleBidDocDownload = async (elem) => {
    let param = {
      "cr_id":elem?.cr_id
    }
    const data = await dispatch(GetConsultantDocumentList2(Axios, param))
    console.log(elem)
    console.log(data)
    if(data?.code === 200){
          var zip = new JSZip();
    var count = 0;
    var zipFilename = `${elem?.name} Consultant.zip`;
    var urls = [];
    
    if(data?.document_list?.length !== 0){
        try {
               data?.document_list?.map((elem)=>{
             urls = [...urls , elem?.file_path]
        })  
        urls.forEach(function(url){
          var filename = url?.substring(url?.lastIndexOf("/")+1);
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, function (err, data) {
             if(err) {
                toast.error("No Documents uploaded/Files Missing")
                throw err;
             }
             zip.file(filename, data, {binary:true});
             count++;
             if (count == urls.length) {
               zip.generateAsync({type:'blob'}).then(function(content) {
                  saveAs(content, zipFilename);
               });
            }
          });
        });
        } catch (error) {
            toast.error("No Documents uploaded/Files Missing")
        }
     }
     else{
        toast.error("No Documents uploaded/Files Missing")
    }
    }
}

  const handleSort = (e) => {
    let key_name = e
    const sorted = consultants?.slice().sort((a, b) => a[key_name] - b[key_name])
    setConsultants(sorted)
  }


  let List = consultants;


  const FilterFunction = () => {
    const List = consultants;

    if (filterList.stateName == undefined || filterList.stateName == null || filterList.stateName == "All") {
      if (filterList.sectorName == undefined || filterList.sectorName == null || filterList.sectorName == "All") {
        if (filterList.expertiseName == undefined || filterList.expertiseName == null || filterList.expertiseName == "All") {
          setFilteredList(consultants)
        }
        else {
          console.log("hereNotAllexp")
            const filte = List.filter((item) => {

              if (item?.expertise_list?.length !== 0) {
                for (let i = 0; i < item?.expertise_list?.length; i++) {
                  const expert_list = item?.expertise_list
                  if (expert_list[i]?.expertise_name === filterList.expertiseName) {
                    return item
                  }
                }
              }
            })

            setFilteredList(filte)
          
        }
      }
      else{
        const Filter = List.filter(item=>{
          if(item.sector_name===filterList.sectorName){
               return item
          }
        })

        if (filterList.expertiseName == undefined || filterList.expertiseName == null || filterList.expertiseName == "All") {
          console.log("hereAllexp")
          setFilteredList(Filter)
        }
        else {
          console.log("hereNotAllexp")
            const filte = Filter.filter((item) => {

              if (item?.expertise_list?.length !== 0) {
                for (let i = 0; i < item?.expertise_list?.length; i++) {
                  const expert_list = item?.expertise_list
                  if (expert_list[i]?.expertise_name === filterList.expertiseName) {
                    return item
                  }
                }
              }
            })

            setFilteredList(filte)
          
        }
      }
    }
    else{
      const StateFilter = List.filter(item=>{
        if(item.state_name===filterList.stateName){
             return item
        }
      })
      if (filterList.sectorName == undefined || filterList.sectorName == null || filterList.sectorName == "All") {
        if (filterList.expertiseName == undefined || filterList.expertiseName == null || filterList.expertiseName == "All") {
          console.log("hereAllexp")
          setFilteredList(StateFilter)
        }
        else {
          console.log("hereNotAllexp")
            const filte = StateFilter.filter((item) => {

              if (item?.expertise_list?.length !== 0) {
                for (let i = 0; i < item?.expertise_list?.length; i++) {
                  const expert_list = item?.expertise_list
                  if (expert_list[i]?.expertise_name === filterList.expertiseName) {
                    return item
                  }
                }
              }
            })

            setFilteredList(filte)
          
        }
      }
      else{
        const Filter = StateFilter.filter(item=>{
          if(item.sector_name===filterList.sectorName){
               return item
          }
        })

        if (filterList.expertiseName == undefined || filterList.expertiseName == null || filterList.expertiseName == "All") {
          console.log("hereAllexp")
          setFilteredList(Filter)
        }
        else {
          console.log("hereNotAllexp")
            const filte = Filter.filter((item) => {

              if (item?.expertise_list?.length !== 0) {
                for (let i = 0; i < item?.expertise_list?.length; i++) {
                  const expert_list = item?.expertise_list
                  if (expert_list[i]?.expertise_name === filterList.expertiseName) {
                    return item
                  }
                }
              }
            })

            setFilteredList(filte)
          
        }
      }
    }
  }

  const handleAutocomplete = (value, filter) => {

    let Data = List;
    const columns = ["state_name", "sector_name"];
    // setConsultants(SearchFilter(Data,columns,value))

    if (filter === "state") {
      if (value === "All") {
        setFilterList({
          ...filterList,
          "stateName": 'All',
          "stateId": 0
        })
      }
      else {
        const state = store.stateListConsultant?.filter(item => item?.state_name === value ? item : null)
        setFilterList({
          ...filterList,
          "stateName": value,
          "stateId": state?.length !== 0 ? state[0]?.state_id : 0
        })
      }

    }
    else if (filter === "sector") {
      if (value === "All") {
        setFilterList({
          ...filterList,
          "sectorName": "All",
          "sectorId": 0
        })
      }
      else {
        const sector = store.sectorListConsultant?.filter(item => item.bs_name === value ? item : null);

        setFilterList({
          ...filterList,
          "sectorName": value,
          "sectorId": sector?.length !== 0 ? sector[0]?.bs_id : 0
        })
      }
    }
    else if (filter === "expertise") {
      if (value === "All") {
        setFilterList({
          ...filterList,
          "expertiseName": 'All',
          "expertiseId": 0
        })
      }
      else {
        const expertise = store.expertiseListConsultant?.filter(item => item.expertise_name === value ? item : null);
        console.log(expertise, "expertise")

        setFilterList({
          ...filterList,
          "expertiseName": value,
          "expertiseId": expertise?.length !== 0 ? expertise[0]?.expertise_id : 0
        })
      }
    }


  }


  useEffect(() => {
    FilterFunction()
  }, [filterList])

  const handleConsultantDelete=async(elem)=>{
    setUser(elem)
    setRemove(true)
    //   let param = {

    //   }
    
    // const data = await DeleteConsultant(Axios,param)
  }

  const ToastShow=(data)=>{
        
    if (data?.code === 200) {
        setRemove(false)
        toast.success('Deleted Consultant SuccessFully');
        dispatch(GetConsultantList(Axios))
    }
    else {
        toast.error(`${data?.message}`)
    }
}

  return (<>
   <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> <div className={styles.rootContainer}>
     
      <div>
        <h2 className={styles.ConsultantsIndexTitle}>Consultants Index</h2>
      </div>
      <div className={styles.FilterConsultantsWrapper}>
        <div className={styles.ConsultantsFilterBody}>
          <div className={styles.ConsultantsFilterBodyItems}>
            <Autocomplete
              sx={{ minWidth: "270px" }}
              value={filterList.stateName}
              options={filterList.stateList}
              getOptionLabel={(option) => option}
              size='small'
              onChange={(e, value) => handleAutocomplete(value, "state")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='state'
                  variant="outlined"
                  label="State"
                  placeholder="state.."
                />
              )}
            />
            <Autocomplete
              sx={{ minWidth: "270px" }}
              value={filterList.sectorName}
              options={filterList.sectorList}
              getOptionLabel={(option) => option}
              size='small'
              onChange={(e, value) => handleAutocomplete(value, "sector")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='sector'
                  variant="outlined"
                  label="Sector"
                  placeholder="sector.."
                />
              )}
            />
            <Autocomplete
              sx={{ minWidth: "270px" }}
              value={filterList.expertiseName}
              options={filterList.expertiseList}
              getOptionLabel={(option) => option}
              size='small'
              onChange={(e, value) => handleAutocomplete(value, "expertise")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='expertise'
                  variant="outlined"
                  label="Expertise"
                  placeholder="expertise.."
                />
              )}
            />
          </div>
          <div className={styles.FilterButton}>
            <FilterAltIcon className={styles.FilterButton} />
          </div>

        </div>
        <div className={styles.AddExportConsultantWrapper}>

          {
            CONSULTANTEXPORTALLOWED.includes(LoginStore?.role?.role_code) ?  <div className={styles.fltrOpnsColumn} onClick={ExportToExcel} >
                        <IosShareOutlinedIcon />
                        <p>Export</p>
                    </div> : null
          }
       
                    <div>
                        <button type="submit" className={styles.AddConsultantButton} onClick={handleNavigate}>Add Consultant</button>
                    </div>
        
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.ConsultantsTable} bordered>
          <thead className={styles.tableHead}>
            <tr>
              {
                theader.map(elem => (
                  <th className={styles.ConsultantTableHead}>{elem.toUpperCase()}</th>
                ))}
                <th className={styles.ConsultantTableHead}>DELETE</th>
            </tr>
          </thead>
          <tbody>
            {
              filteredList?.map((elem, index) =>
                <tr key={index} className={index % 2 === 1 ? styles.tableRow : null}>
                  <td className={styles.ConsultantTableTD} title={elem?.name}>{elem.name}</td>
                  <td className={styles.ConsultantTableTD} title={elem?.email}>{elem.email}</td>
                  <td className={styles.ConsultantTableTD}>{elem.cell_no}</td>
                  <td className={styles.ConsultantTableTD}>{elem.type}</td>
                  <td className={styles.ConsultantTableTD}><button type="button"
                    className={
                      parseInt(elem.experience) <= 5 ? styles.levelButton1 : parseInt(elem.experience) <= 10 ? styles.levelButton2 : parseInt(elem.experience) > 10 || elem.experience ==="20+" ? styles.levelButton3 :
                      null}
                  >{elem.experience} Yrs</button></td>

                  <td className={styles.ConsultantTableTD}>{
                    store?.stateListConsultant?.map((state) => state?.state_id === elem.state_id ? state?.state_name : null)
                  }</td>
                  <td className={styles.ConsultantTableTD}>{NormalDateFormat(elem.created_at)}</td>
                  <td className={styles.ConsultantTableTD}>{
                    store?.sectorListConsultant?.map((sector) => sector?.bs_id === elem.sector_id ? sector?.bs_name : null)
                  }</td>
                  {/* <td className={styles.ConsultantTableTD}>{
                    store?.expertiseListConsultant?.map((expertise) => expertise?.expertise_id === elem.expertise_id ? expertise?.expertise_name : null)
                  }</td> */}
                  <td className={styles.ConsultantTableTD} title={elem?.comments}>{elem.comments}</td>

                  <td className={styles.ConsultantTableTD}>
                    <div className={styles.StatusContainer}>
                      <DownloadIcon style={{ color: "#ff9800", cursor: "pointer" }} onClick={() => handleBidDocDownload(elem)} />
                    </div>

                  </td>
                  <td className={styles.ConsultantTableTD}>
                    <div className={styles.StatusContainer}>
                      <div className={styles.editIcon} onClick={() => handleConsultantDetail(elem)}><BsPencil /></div>
                    </div>

                  </td>
                  <td className={styles.ConsultantTableTD}
                                onClick={()=>handleConsultantDelete(elem)}><DeleteIcon style={{cursor: "pointer"}}/></td>
                </tr>)
            }
          </tbody>
        </table>
      </div>
    </div>

    {remove && <DeletePromptConsultant remove={remove} setRemove={setRemove} file={user} ToastShow={ToastShow}/>}
  </>
   
  )
}

export default ConsultantsIndex