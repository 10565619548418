import { GETCONTRACTORDETAIL, GETCONTRACTORTABLE, GETINVOICELIST, GETINVOICEPAYMENTLIST, GETPROJECTLISTCONTRACTOR } from "../reduxConstants/ContractorReducerConstants"

const initialState = {
    contractorList : [],
    projectListContractor:[],
    contractorDetailList: {},
    invoiceList: [],
}

export default  (state=initialState, action)=>{
    switch(action.type){
        case GETCONTRACTORTABLE : 
        return {
              ...state,
              contractorList : action.payload
        }
        case GETPROJECTLISTCONTRACTOR : 
        return {
              ...state,
             projectListContractor : action.payload
        }
        case GETCONTRACTORDETAIL:
        return {
            ...state,
            contractorDetailList : action.payload
        }
        case GETINVOICELIST:
        return{
            ...state,
            invoiceList: action.payload
        }
       
   default: return state
   }
}

