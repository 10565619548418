import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/userMgt.module.css";
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { UpdateAdminUser } from '../../actions/admin/adminAction';
import {SubmitButton} from '../../utility/button/button'



function MyVerticallyCenteredModal(props) {
    const dispatch = useDispatch()
    const Axios = useAxiosPrivate()
    const store = useSelector(state => state.Admin)
    const [roleList, setRoleList] = useState([]);
    const [role, setRole] = useState('')
    let [updatedUser, setUpdatedUser] = useState({});


    useEffect(() => {
        const role = store?.roleList?.filter(item => item.role_id === store?.userData?.role_id);
        setRole(role[0]?.role_name)
        setUpdatedUser(store?.userData);
        setRoleList(store?.roleList);
        return () => {
            // console.log('unmounting edit')
        }
    }, [store?.userData])

    const handleChange = (e) => {
        setUpdatedUser({
            ...updatedUser,
            [e.target.name]: e.target.value
        })
    }

    const handleLevelChange = (e) => {
        setRole(e.target.value);
        const role = roleList?.filter(item => item.role_name === e.target.value)
        setUpdatedUser({
            ...updatedUser,
            role_id: role[0]?.role_id
        })
    }

    const handleSubmit = async (e) => {

        let param = {
            "user_id": updatedUser?.user_id,
            "full_name": updatedUser?.full_name?.replace(/\s+/g, ' ').trim(),
            "email": updatedUser?.email?.replace(/\s+/g, ' ').trim(),
            "role_id": updatedUser?.role_id
        }

        const data = await dispatch(UpdateAdminUser(Axios, param));
        if (data?.code === 200) {
            setUpdatedUser({})
            setRole('')
            toast.success('Updated User SuccessFully');

            setTimeout(()=>{
                props.handleToggle()
            },[500])
        }
        else {
            toast.error(`${data.message}`)
        }

    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className={styles.PopUpHeader}>
                <Modal.Title className={styles.PopUpTitle}>
                    Edit User ID : {updatedUser.userId}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <form className={styles.PopUpForm}>
                    <TextField
                        name="full_name"
                        value={updatedUser.full_name}
                        size='small'
                        label="Name"
                        type="text"
                         autoComplete="off"
                        onChange={handleChange}
                        className={styles.PopUpInput}
                    />
                    <TextField
                        name="email"
                        onChange={handleChange}
                        size='small'
                        id="level-input"
                        value={updatedUser.email}
                        label="Email"
                        type="text"
                         autoComplete="off"
                        className={styles.PopUpInput}
                    />
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel >Level</InputLabel>
                        <Select
                            label="Level"
                            onChange={handleLevelChange}
                            name="pmsnLevel"
                            className={styles.PopUpInput}
                            value={role}
                        >

                            {
                                roleList?.map((item, index) => (
                                    <MenuItem value={item.role_name}>{item.role_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>

                </form>
            </Modal.Body>
            <div className={styles.PopUpSubmit}>
                <SubmitButton onClick={handleSubmit} className={styles.PopUpSubmitButton}>Submit</SubmitButton>
            </div>

        </Modal>
    );
}




function UserMgtEdit(props) {
    let { toggle, toggleSet } = props

    const handleToggle = ()=>{
       toggleSet(!toggle)
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <MyVerticallyCenteredModal
                show={toggle}
                onHide={handleToggle}
                className={styles.PopUpBox}
                handleToggle={handleToggle}

            />
        </>
    );
}

export default UserMgtEdit

