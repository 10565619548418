import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SideDrawer from '../../sideDrawer/drawer';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./styles/home.module.css";


const Home = (props) => {
    const drawerWidth = 220;
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
     <div onClick={handleDrawerToggle} className={styles.sideBarMenu}><MenuIcon className={styles.OpenCloseIcon} fontSize='medium'/></div>
    <Box sx={{ display: 'flex' }}>
      
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth, display:'flex', flexDirection:'column' }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders" 
    >
   
      
 
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        
    <div onClick={handleDrawerToggle} className={styles.sideBarClose}><CloseIcon className={styles.OpenCloseIcon} variant="contained" fontSize='medium'/></div> 
     
        <SideDrawer setMobileOpen={setMobileOpen}/>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <SideDrawer />
      </Drawer>
    </Box>
    <Box
      component="main"
      sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } , minHeight:"100vh" }}
    >
      
      <Outlet/>
    </Box>
  </Box>
    </>

  )
}


Home.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Home