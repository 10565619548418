import React, { useState, useEffect } from 'react'
import styles from "./styles/contractManagementForm.module.css"
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CntrtMgtAddInvoiceModal from './CntrtMgtAddInvoiceModal';
import DeleteIcon from '@mui/icons-material/Delete';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { SubmitButton } from "../../utility/button/button"
import ClearIcon from '@mui/icons-material/Clear';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddContractList, FileDeleteAddContractor, FileUploadContractor, GetProjectListContractor } from '../../actions/contractor/ContrractorActions';
import { useNavigate } from 'react-router-dom';

const AddContractForm = () => {
    let [show, setShow] = useState(false)
    const store = useSelector(state => state.Contractor);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let Axios = useAxiosPrivate()
    const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']
    const [error, setError] = useState({});
    // const [invoiceData, setInvoiceData] = useState({

    //     "invoice_amount": 0,
    //     "invoice_date": ''
    // })
    let [formInput, setFormInput] = useState({
        "project_code": "",
        "project_id": 0,
        "email": "",
        "contractor_name": "",
        "pan_no": "",
        "gst_no": "",
        "cell_no": '',
        "contract_start_date": '',
        "contract_end_date": '',
        "contract_value": 0,
        "pan": {
            'name': '',
            "type": "pan",
            "path": ""
        },
        "gst": {
            'name': '',
            "type": "gst",
            "path": ""
        },
        "contract": [],
    })

    useEffect(() => {
        dispatch(GetProjectListContractor(Axios))
    }, [])
    let toggleInvoice = () => {
        setShow(!show)
    }

    let handleChange = (e) => {
        setFormInput(
            {
                ...formInput,
                [e.target.name]: e.target.value
            })
    }
    let handlePANChange = (e) => {
        setFormInput(
            {
                ...formInput,
                "pan_no": e.target.value.toUpperCase()
            })
    }
    let handleGSTChange = (e) => {
        setFormInput(
            {
                ...formInput,
                "gst_no": e.target.value.toUpperCase()
            })
    }
    let handleContractStartDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setFormInput({
                ...formInput,
                contract_start_date: ''
            })
        }
        else {
            setFormInput({
                ...formInput,
                contract_start_date: e
            })
        }
    }
    let handleContractEndDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setFormInput({
                ...formInput,
                contract_end_date: ''
            })
        }
        else {
            setFormInput({
                ...formInput,
                contract_end_date: e
            })
        }
    }


    let handleSelectChange = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }
    const handleFileSelect = async (event) => {
        // setFile(event.target.files[0]);
        // console.log(event.target.files[0],event.target.name)
        const Type = event.target.name;
        const KEY = Type === "pan" ? "pan" : Type === "gst" ? "gst" : "contract";


        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('type', event.target.name)
        const Result = await FileUploadContractor(formData);
        
        if (Result?.code === 200) {
            let OBJ = {
                name: Result?.name,
                type: KEY,
                path: Result?.path
            }
            if (KEY === 'contract') {
                setFormInput({
                    ...formInput,
                    [KEY]: [...formInput[KEY], OBJ]
                })
            }
            else {
                setFormInput({
                    ...formInput,
                    [KEY]: OBJ
                })
            }

            toast.success('File Uploaded SuccessFully');
        }
        else {
            toast.error('File Upload Failed')
        }
    }

    const handleClearDocument = async (type, fileName, index) => {
        const KEY = type === "pan" ? "pan" : type === "gst" ? "gst" : "contract";
        const formData = new FormData();
        formData.append('filename', fileName);
        formData.append('type', KEY)
        const Result = await FileDeleteAddContractor(formData);
        if (Result?.code === 200) {
            toast.success('Deleted Tender SuccessFully');
            if (KEY === 'contract') {

                let Arr = formInput[type];

                const filteredList = Arr.filter((data, i) => i !== index)

                setFormInput({
                    ...formInput,
                    [type]: filteredList
                })
            }
            else {
                setFormInput({
                    ...formInput,
                    [KEY]: {
                        "type": "",
                        "path": ""
                    }
                })
            }
        }
        else {
            toast.error(`${Result?.message}`)
        }
    }



    const isValid = () => {
        let success = true;
        let error = {};
        if (!formInput.contractor_name.trim()) {
            success = false
            error.contractor_name = "Please type Name of Consultant!!";
        } else if (formInput?.contractor_name?.toString()?.length > 30) {
            success = false
            error.contractor_name = "*Only 30 characters Allowed!";
        }
        if (formInput.project_id === 0) {
            success = false
            error.project_id = "Please select Project Manager!!";
        }

        if (!formInput?.pan_no.trim()) {
            success = false
            error.pan_no = "Please Enter a valid Pan number!";
        }
        else if (formInput?.pan_no?.toString()?.length !== 10) {
            success = false
            error.pan_no = "*Only 10 characters Allowed!";
        }
        else if (/^[a-z0-9]+$/i.test(formInput?.pan_no) === false) {
            success = false
            error.pan_no = "*Only alpha-numeric Allowed!";
        }
        else if (!(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(formInput?.pan_no))) {
            success = false
            error.pan_no = "Please Enter valid Pan number! ex:AOTHY6378U";
        }

        // if (!formInput?.gst_no.trim()) {
        //     success = false
        //     error.gst_no = "Please Enter a valid GST number!"
        // }
        // else if (formInput?.gst_no?.toString()?.length !== 15) {
        //     success = false
        //     error.gst_no = "*Only 15 characters Allowed!";
        // }
        if (!formInput?.gst_no.trim()) {
            error.gst_no = null
        }
        else if (!(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}?$/.test(formInput?.gst_no))) {
            success = false
            error.gst_no = "*Please Enter a valid GST number ex:23QWERT5678A1ZA !";
        }
        // else if (!(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){2}?$/.test(formInput?.gst_no))) {
        //     success = false
        //     error.gst_no = "*Please Enter a valid GST number ex:23QWERT5678A1ZA!";
        // }



        if (formInput?.cell_no <= 0) {
            success = false
            error.cell_no = "Please Enter a valid Cell number!";
        }
        else if (formInput?.cell_no?.toString()?.length !== 10) {
            success = false
            error.cell_no = "*Only 10 characters Allowed!";
        }
        else if (!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g.test(formInput?.cell_no)) {
            success = false
            error.cell_no = "Please Enter a valid Cell number starting with 6/7/8/9!";
        }
        else if (Number.isInteger(parseFloat(formInput.cell_no)) === false) {
            success = false
            error.cell_no = "Decimal not allowed!!";
        }
        if (!formInput.email.trim()) {
            error.email = '*Email cannot be empty!';
            success = false
        }
        else if (formInput.email?.toString().length > 50) {
            error.email = '*Only 50 characters Allowed!';
            success = false
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formInput.email)) {
            error.email = "Enter a Valid Email ex:vijayravi45@gmail.com!!";
            success = false
        }
        if (formInput.contract_end_date?.toString()?.length !== 0) {
            if (formInput.contract_start_date?.toString()?.length !== 0) {
             
                    if (!(new Date(formInput.contract_start_date) < new Date(formInput.contract_end_date))) {
                    success = false
                    error.contract_start_date = "Cannot be After End date!!";
                    error.contract_end_date = "Cannot be Before Start date!!";
                }
                }
                else{
                    success = false
                    error.contract_start_date = "Please Enter Contract Start date!!";
                }
               
            }
          
        if (formInput?.contract_value < 0) {
            success = false
            error.contract_value = "Please Enter a valid Contract Value!";
        }
        else if (formInput?.contract_value?.toString()?.length > 30) {
            success = false
            error.contract_value = "*Only 30 characters Allowed!";
        }
        else if (!/^[1-9]\d*$/.test(formInput.contract_value)) {
            success = false
            error.contract_value = "Please Enter a valid Contract Value!!";
        }
        else if (Number.isInteger(parseFloat(formInput.contract_value)) === false) {
            success = false
            error.contract_value = "Decimal not allowed!!";
        }


        if (formInput?.pan?.path?.length === 0) {
            success = false;
            toast.error("Please upload a PAN document!!!")
        }
        // if (formInput?.gst?.path?.length === 0) {
        //     success = false;
        //     toast.error("Please upload a GST document!!!")
        // }
        if (formInput?.contract?.length === 0) {
            success = false;
            toast.error("Please upload a Contract document!!!")
        }


        setError(error)
        return success
    }


    const handleContractFormSubmit = async (e) => {
        e.preventDefault()

        if (isValid()) {

            const projectCode = store?.projectListContractor?.filter(item => parseInt(item.project_id) === parseInt(formInput.project_id));
            let param = {
                "project_code": projectCode?.length !== 0 ? projectCode[0]?.project_code : null,
                "project_id": formInput.project_id,
                "email": formInput.email,
                "contractor_name": formInput.contractor_name,
                "pan_no": formInput.pan_no,
                "gst_no": formInput.gst_no,
                "cell_no": formInput.cell_no,
                "contract_start_date": formInput.contract_start_date !== '' && formInput.contract_start_date?.toString().length !== 0 ? formInput.contract_start_date : null,
                "contract_end_date": formInput.contract_end_date !== '' && formInput.contract_end_date?.toString().length !== 0 ? formInput.contract_end_date : null,

                "contract_value": formInput.contract_value,
            }
            if(formInput?.pan?.path?.length !== 0 && formInput?.pan?.path !== null && formInput?.pan?.path !== undefined){
                param['pan'] = {
                    "type": formInput?.pan?.type,
                    "path": formInput?.pan?.path
                }
            }
            if(formInput?.gst?.path?.length !== 0 && formInput?.gst?.path !== null && formInput?.gst?.path !== undefined){
                param['gst'] = {
                    "type": formInput?.gst?.type,
                    "path": formInput?.gst?.path
                }
            }
            if(formInput?.contract?.length !== 0 && formInput?.contract !== null && formInput?.contract !== undefined){
                param['contract'] = formInput?.contract
            }
            const data = await dispatch(AddContractList(Axios, param))
            if (data?.code === 201) {
                toast.success('Added Contract SuccessFully');
                setFormInput({
                    "project_code": "0",
                    "project_id": 0,
                    "email": "",
                    "contractor_name": "",
                    "pan_no": "",
                    "gst_no": "",
                    "cell_no": '',
                    "contract_start_date": '',
                    "contract_end_date": '',
                    "contract_value": 0,
                    "pan": {
                        'name': '',
                        "type": "pan",
                        "path": ""
                    },
                    "gst": {
                        'name': '',
                        "type": "gst",
                        "path": ""
                    },
                    "contract": [],
                })
                setTimeout(() => {
                    navigate('/ContractorDetail/Page:1')
                }, [2000])
            }
            else {
                toast.error(`${data?.message}`)
            }
        }

    }
    //   console.log(store)
    // console.log(formInput)
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.rootContainer}>

                <div>
                    <h2 className={styles.AddContractTitle}>Contract Management Form</h2>
                </div>

                <form autoComplete='off'>
                    <div className={styles.AddContractBody}>

                        <div className={styles.AddContractItem}>
                            <label>Project Title*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <FormControl sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                                    <Select
                                        error={error.project_id ? true : false} value={formInput.project_id}
                                        className={styles.AddProjectItemSelect}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleSelectChange}
                                        name="project_id"
                                    >
                                        {
                                            store?.projectListContractor?.map((item, index) => (
                                                <MenuItem value={item.project_id}>{item.project_title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText sx={{ color: "#d32f2f" }} >{error.project_id ? error.project_id : null}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>Name of Contractor / Consultant*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="contractor_name" value={formInput.contractor_name} onChange={handleChange} error={error.contractor_name ? true : false} helperText={error.contractor_name ? error.contractor_name : null} />
                            </div>
                        </div>

                        <div className={styles.AddContractItem}>
                            <label>PAN No*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="pan_no" value={formInput.pan_no} inputProps={{ maxLength: 10 }} onChange={handlePANChange} error={error.pan_no ? true : false} helperText={error.pan_no ? error.pan_no : null} />
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>GST No</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="gst_no" value={formInput.gst_no} inputProps={{ maxLength: 15 }} onChange={handleGSTChange} error={error.gst_no ? true : false} helperText={error.gst_no ? error.gst_no : null} />
                            </div>
                        </div>

                        <div className={styles.AddContractItem}>
                            <label>Cell No*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="cell_no" value={formInput.cell_no} onChange={handleChange} inputProps={{ maxLength: 10 }} error={error.cell_no ? true : false} helperText={error.cell_no ? error.cell_no : null} />
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>
                            <label>Email*</label>

                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, '& .MuiFormHelperText-root': { fontSize: "10px" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="email" value={formInput.email} onChange={handleChange} error={error.email ? true : false} helperText={error.email ? error.email : null} />
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>Contract Start Date</label>
                            <div className={styles.ContractorInputWrapper}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="contract_start_date" value={formInput.contract_start_date}
                                        onChange={handleContractStartDate}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <TextField helperText={error.contract_start_date ? error.contract_start_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>

                            <label>Contract End Date</label>
                            <div className={styles.ContractorInputWrapper}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="contract_end_date" value={formInput.contract_end_date}
                                        onChange={handleContractEndDate}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <TextField helperText={error.contract_end_date ? error.contract_end_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>
                            <label>Contract value excluding GST*</label>

                            <div className={styles.ContractorInputWrapper}>
                                <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="contract_value" value={formInput.contract_value} onChange={handleChange} error={error.contract_value ? true : false} helperText={error.contract_value ? error.contract_value : null} />
                            </div>
                        </div>


                    </div>
                </form>


                <div className={styles.AddDocsPANGSTWrapper}>
                    <div className={styles.AddPANGSTDocs}  >
                        <div className={styles.AddDocsHeader}>
                            <label>PAN Number</label>
                        </div>
                        <div className={styles.DocUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst1"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst1" name='pan' onChange={handleFileSelect} />
                            </div>
                            {
                                (formInput?.pan?.path?.length === 0 || formInput?.pan === undefined || formInput?.pan === null) ? null :
                                    <div className={styles.DocUploaded}>
                                        <a href={formInput?.pan?.path} download="pan" target={allowedExtensions.includes(formInput?.pan?.path?.substring(formInput?.pan?.path?.lastIndexOf('.') + 1).toLowerCase()) ?
                                            "_blank" : null
                                        }>{formInput?.pan?.name}</a>
                                        <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("pan", formInput?.pan?.name, 1)} />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={styles.AddPANGSTDocs}  >
                        <div className={styles.AddDocsHeader}>
                            <label>GST Number</label>
                        </div>
                        <div className={styles.DocUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst2"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst2" name='gst' onChange={handleFileSelect} />
                            </div>
                            {
                                (formInput?.gst?.path?.length === 0 || formInput?.gst === undefined || formInput?.gst === null) ? null :
                                    <div className={styles.DocUploaded}>
                                        <a href={formInput?.gst?.path} download="gst" target={allowedExtensions.includes(formInput?.gst?.path?.substring(formInput?.gst?.path?.lastIndexOf('.') + 1).toLowerCase()) ?
                                            "_blank" : null
                                        }>{formInput?.gst?.name}</a>
                                        <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("gst", formInput?.gst?.name, 1)} />
                                    </div>
                            }

                        </div>
                    </div>
                </div>
                <div className={styles.AddContractDocsWrapper}>
                    <div className={styles.AddContractDocs}  >
                        <div className={styles.AddDocsContractTitle}>
                            <label>Contract</label>
                        </div>
                        <div className={styles.DocContractUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst3"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst3" name='contract' onChange={handleFileSelect} />
                            </div>
                            <div className={styles.DocUploadedContractWrapper}>
                                {
                                    (formInput?.contract?.length === 0 || formInput?.contract === undefined || formInput?.contract === null) ? null :
                                        (
                                            formInput?.contract?.map((elem, i) => {
                                                return <div className={styles.DocUploaded}>
                                                    <a href={elem?.path} download="contract" target={allowedExtensions.includes(elem?.path?.substring(elem?.path?.lastIndexOf('.') + 1).toLowerCase()) ?
                                                        "_blank" : null}
                                                    >{elem?.name}</a>

                                                    <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("contract", elem?.name, i)} />
                                                </div>
                                            })
                                        )

                                }
                            </div>

                        </div>
                    </div>
                </div>


                {/* <div className={styles.AddInvoiceButtonWrapper}>
                    <button className={styles.AddInvoiceButton} onClick={toggleInvoice}>Add Invoice</button>
                </div>


                <div className={styles.InvoiceBoxWrapper}>
                    <div className={styles.InvoiceBox}>
                        <div className={styles.InvoiceBoxHeader}>
                            <h3 className={styles.InvoiceBoxHeaderTitle}>Invoice-PrId-001</h3>
                            <div className={styles.InvoiceBoxHeaderContent}>
                                <label>Invoice Amount</label>
                                <h5>5,30,000</h5>
                            </div>
                            <div className={styles.InvoiceBoxHeaderContent}>
                                <label>Invoice Date</label>
                                <h5>14-03-2022</h5>
                            </div>
                            <DeleteIcon fontSize="large" />
                        </div>

                        <div className={styles.InvoiceBoxAddPayment}>

                            <TextField
                                name="invoice_amount"
                                value="62352353"
                                size='small'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CurrencyRupeeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                label="Invoice Amount"
                                type="text"
                                autoComplete='off'
                                className={styles.PopUpInput}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    name="invoiceDate"
                                    inputFormat="dd/MM/yyyy"
                                    label="Invoice Date"
                                    renderInput={(params) => <TextField className={styles.PopUpInput} size="small" {...params} error={false} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                />
                            </LocalizationProvider>


                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <InputLabel >GST Included</InputLabel>
                                <Select
                                    label="GST Included"
                                    defaultValue="Yes"
                                    autoComplete='off'
                                    className={styles.PopUpInput}>

                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                            <button className={styles.AddPaymentBttn}>Add Payment</button>
                        </div>

                        <div className={styles.InvoiceBoxTableWrapper} >
                            <table className={styles.InvoiceBoxTable}>
                                <thead className={styles.InvoiceAddTableHead}>
                                    <tr className={styles.InvoiceAddTableHeadItems}>
                                        <th className={styles.InvoiceAddTableTH}>Invoice Amount</th>
                                        <th className={styles.InvoiceAddTableTH}>Invoice Date</th>
                                        <th className={styles.InvoiceAddTableTH}>GST Included</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={styles.InvoiceAddTableTR}>
                                        <td className={styles.InvoiceAddTableTD}>1,20,000</td>
                                        <td>22-04-2022</td>
                                        <td>yes</td>
                                        <td> <DeleteIcon /></td>
                                    </tr>
                                    <tr >
                                        <td className={styles.InvoiceAddTableTD}>1,20,000</td>
                                        <td>22-04-2022</td>
                                        <td>yes</td>
                                        <td> <DeleteIcon /></td>
                                    </tr>
                                    <tr className={styles.InvoiceAddTableTR}>
                                        <td className={styles.InvoiceAddTableTD}>1,20,000</td>
                                        <td>22-04-2022</td>
                                        <td>yes</td>
                                        <td> <DeleteIcon /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}

            </div>
            <div className={styles.SubmitButtonWrapper}><SubmitButton onClick={handleContractFormSubmit}>Submit</SubmitButton>
            </div>



            {/* <div>
                {show === true ? <CntrtMgtAddInvoiceModal toggle={show} toggleSet={setShow} invoiceData={invoiceData} setInvoiceData={setInvoiceData} /> : null}

            </div> */}
        </>

    )
}

export default AddContractForm