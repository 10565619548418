import React, { useEffect, useState } from 'react'
import styles from "./styles/addConsultant.module.css"
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from '@mui/material/Checkbox';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ClearIcon from '@mui/icons-material/Clear';
import { unstable_HistoryRouter, useLocation, useNavigate } from 'react-router-dom'
import { FormControl, FormControlLabel, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { AddConsultantList, DocumentDeleteConsultantAdd, DocumentDeleteConsultantUpdate, FileUploadConsultant, FileUploadUpdateConsultant, GetBidSectorListConsultant, GetConsultantDetail, GetConsultantDocumentList, GetExpertiseListConsultant, GetStateListConsultant, UpdateConsultantExpertiseList, UpdateConsultantList } from '../../actions/consultant/ConsultantAction';
import { SubmitButton } from '../../utility/button/button';
import CheckboxComponent from './Checkbox';
import DeletePromptConsultantFile from './DeletePromptConsultantFile';


const UpdateConsultant = () => {
 const [remove,setRemove] = useState(false);
 const [deleteData,setDeleteData]=useState({});
   const navigate = useNavigate()
  let [error, setError] = useState({})
  const location = useLocation();
  let experienceMenu = []
  let List = []
  for (let i = 1; i <= 20; i++) {
    experienceMenu.push(i)
  }
  experienceMenu.push("20+")
  let [formInput, setFormInput] = useState({
    "name": "",
    "type": "",
    "cell_no": '',
    "email": "",
    "state_id": 0,
    "sector_id": 0,
    "experience": "0",
    "comments": "",
    "expertise_list": [],
    "consultant_document_list": []
  })
  const store = useSelector(state => state.Consultant)
  const Axios = useAxiosPrivate()
  const dispatch = useDispatch()
  const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']


  const [expertiseBool, setExpertiseBool] = useState({})
  useEffect(() => {
    let paramDetail = {
      "cr_id": parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1))
    }

    dispatch(GetStateListConsultant(Axios))
    dispatch(GetBidSectorListConsultant(Axios))
    dispatch(GetExpertiseListConsultant(Axios))
    dispatch(GetConsultantDetail(Axios, paramDetail))
    dispatch(GetConsultantDocumentList(Axios, paramDetail))

  }, [])



  useEffect(() => {
    setFormInput(store?.consultantDetail ?
      {
        "name": store?.consultantDetail?.name,
        "type": store?.consultantDetail?.type,
        "cell_no": store?.consultantDetail?.cell_no,
        "email": store?.consultantDetail?.email,
        "state_id": store?.consultantDetail?.state_id,
        "sector_id": store?.consultantDetail?.sector_id,
        "experience": store?.consultantDetail?.experience,
        "comments": store?.consultantDetail?.comments,
        "expertise_list": store?.consultantDetail?.expertise_field,
        "consultant_document_list": store?.documentList

      }
      :
      {
        "name": "",
        "type": "",
        "cell_no": '',
        "email": "",
        "state_id": 0,
        "sector_id": 0,
        "experience": "0",
        "comments": "",
        "expertise_list": [],
        "consultant_document_list": []
      }
    )

    let Obj = {};
    store?.consultantDetail?.expertise_field?.map((elem) => {
      let KEY = elem.expertise_name
      Obj[KEY] = true
      setExpertiseBool(
        Obj
      )
    })
    // let Arr = store?.consultantDetail?.expertise_field
    // for (let i = 0; i < Arr?.length; i++) {
    //   setExpertiseBool({
    //     ...expertiseBool,
    //     [Arr[i]?.expertise_name]: true
    //   })
    // }

  }, [store?.consultantDetail])





  let handleChange = (e) => {
    setFormInput(
      {
        ...formInput,
        [e.target.name]: e.target.value
      })
  }
  let handleSelectChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    })
  }

  const handleFileSelect = async (event) => {
    const Type = event.target.name;
    const KEY = Type === "consultant" ? "consultant_document_list" : null;
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('type', event.target.name)
    formData.append('cr_id', store?.consultantDetail?.cr_id)
    const Result = await FileUploadUpdateConsultant(formData);

    if (Result?.code === 200) {
      let paramDetail = {
        "cr_id": parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1))
      }
      await dispatch(GetConsultantDocumentList(Axios, paramDetail))
      let OBJ = {
        type: event.target.name,
        file_path: Result?.path,
        name: Result?.name,
        crd_id: Result?.document_data[0]?.crd_id
      }
      setFormInput({
        ...formInput,
        [KEY]: [...formInput[KEY], OBJ]
      })

      toast.success('File Uploaded SuccessFully');
    }
    else {
      toast.error('File Upload Failed')
    }
  }

  useEffect(()=>{
       setFormInput({
        ...formInput,
        consultant_document_list : store.documentList
       })
  },[store.documentList])

  const handleClearDocument = async (filename, crd_id, index) => {
    setDeleteData({
      filename,
      crd_id,
      cr_id:store?.consultantDetail?.cr_id
    });
    setRemove(true);

   
  }



  const handleDelete = async()=>{
     let param = {
      "cr_id":deleteData?.cr_id,
      "filename": deleteData?.filename,
      "crd_id": deleteData?.crd_id
    }
    const data = await dispatch(DocumentDeleteConsultantUpdate(param,Axios));

    if (data?.code === 200) {
      setRemove(false)
      toast.success("Deleted Document SuccessFully");
    }
    else {
      toast.error(data?.message)
    }
  }


  const IsValid = () => {
    let success = true;
    let error = {};
    if (!formInput.name.trim()) {
      success = false
      error.name = "Please enter valid Consultant Name!!";
    }
    else if (formInput.name?.toString().length > 50) {
      error.name = '*Only 50 characters Allowed!';
      success = false
    }
    if (formInput.type.length===0) {
      success = false
      error.type = "Please select a valid Type!!";
    }
    else if(!(formInput.type === 'Individual' || formInput.type === 'Firm')) {
      success = false
      error.type = "Please select a valid Type!!";
    }

    if (formInput?.cell_no <= 0) {
      success = false
      error.cell_no = "Please Enter a valid Cell number!";
    }
    else if (formInput?.cell_no?.toString()?.length !== 10) {
      success = false
      error.cell_no = "*Only 10 characters Allowed!";
    }
    else if (!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g.test(formInput?.cell_no)) {
      success = false
      error.cell_no = "Please Enter a valid Cell number starting with 6/7/8/9!";
    }
    else if (Number.isInteger(parseFloat(formInput.cell_no)) === false) {
      success = false
      error.cell_no = "Decimal not allowed!!";
    }
    if (!formInput.email.trim()) {
      error.email = '*Email cannot be empty!';
      success = false
    }
    else if (formInput.email?.toString().length > 50) {
      error.email = '*Only 50 characters Allowed!';
      success = false
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formInput.email)) {
      error.email = "Enter a Valid Email ex:vijayravi45@gmail.com!!";
      success = false
    }


    if (formInput?.state_id === 0) {
      error.state_id = "Please select State!!";
      success = false;
    }

    if (formInput?.sector_id === 0) {
      error.sector_id = "Please select Sector!!";
      success = false;
    }
    if (!/^[0-9]\d*$/.test(formInput?.experience)) {
      success = false
      error.experience = "Please Enter a valid Number!!";
    }
    if (formInput?.consultant_document_list?.length === 0) {
      toast.error("Please upload a Consultant document!!!")
      success = false;
    }
    if (List?.length === 0) {
      toast.error("Please select at least one Expertise!!!")
      success = false;
    }
    setError(error)
    return success
  }

  let handleCheckBoxChange = (e) => {
    setExpertiseBool({
      ...expertiseBool,
      [e.target.name]: e.target.checked
    })
  }

  console.log(formInput)
  const handleUpdateConsultant = async (e) => {
    store?.expertiseListConsultant?.map((item) => {
      if (expertiseBool[item.expertise_name] && expertiseBool[item.expertise_name] !== undefined && expertiseBool[item.expertise_name] !== null) {
        List.push(item)
      }
    })
    let paramExpertise = {
      "cr_id": store?.consultantDetail?.cr_id,
      "expertise_list": List
    }
    if (IsValid()) {
      let param = {
        "cr_id": store?.consultantDetail?.cr_id,
        "name": formInput.name,
        "type": formInput.type,
        "cell_no": formInput.cell_no,
        "email": formInput.email,
        "state_id": formInput.state_id,
        "sector_id": formInput.sector_id,
        "experience": formInput.experience,
        "comments": formInput.comments,
        "expertise_list": List,
        "consultant_document_list": formInput.consultant_document_list
      }
      const data = await dispatch(UpdateConsultantList(Axios, param))
      const Edata = await dispatch(UpdateConsultantExpertiseList(Axios, paramExpertise))
      if (data?.code === 200 && Edata?.code === 200) {
        toast.success('Updated Consultant SuccessFully');
        setTimeout(() => {
          navigate(-1)
        }, [2000])

      }
      else {
        toast.error(`${data?.message}`)
      }
    }
  }
  // console.log(formInput)
  // console.log(store)
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={styles.rootContainer}>

        <div>
          <h2 className={styles.AddConsultantTitle}>Update Consultant</h2>
        </div>

        <form autoComplete='off'>
<div className={styles.AddConsultantBody}>
          <div className={styles.AddConsultantItem}>

            <label>Name of Consultant*</label>
            <div className={styles.ConsultantInputWrapper}>
              <TextField autoComplete="off" variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="name" value={formInput.name} onChange={handleChange} error={error.name ? true : false} helperText={error.name ? error.name : null} />
            </div>
          </div>
          <div className={styles.AddConsultantItem}>
            <label>Type*</label>
            <div className={styles.ConsultantInputWrapper}>
            <FormControl sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                <Select
                  error={error.type? true : false} value={formInput.type}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="type"
                >
                 <MenuItem value="Individual">Individual</MenuItem>
                 <MenuItem value="Firm">Firm</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.type ? error.type : null}</FormHelperText>
              </FormControl>
              </div>
          </div>
          <div className={styles.AddConsultantItem}>
            <label>Cell No.*</label>
            <div className={styles.ConsultantInputWrapper}>
              <TextField autoComplete="off" inputProps={{ maxLength: 10 }} variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="cell_no" value={formInput.cell_no} onChange={handleChange} error={error.cell_no ? true : false} helperText={error.cell_no ? error.cell_no : null} />
            </div>
          </div>
          <div className={styles.AddConsultantItem}>

            <label>Email*</label>
            <div className={styles.ConsultantInputWrapper}>
              <TextField autoComplete="off" variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" type='email' name="email" value={formInput.email} onChange={handleChange} error={error.email ? true : false} helperText={error.email ? error.email : null} />
            </div>
          </div>
          <div className={styles.AddConsultantItem}>
            <label>State</label>
            <div className={styles.ConsultantInputWrapper}>
              <FormControl sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                <Select
                  error={error.state_id ? true : false} value={formInput.state_id}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="state_id"
                >
                  {
                    store?.stateListConsultant?.map((item, index) => (
                      <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.state_id ? error.state_id : null}</FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className={styles.AddConsultantItem}>
            <label >Years of Experience</label>
            <div className={styles.ConsultantInputWrapper}>
            <TextField variant="outlined" className={styles.InputField} autoComplete="off" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="experience" value={formInput.experience} onChange={handleChange} error={error.experience ? true : false} helperText={error.experience ? error.experience : null} />
            </div>
          </div>


          <div className={styles.AddConsultantItem}>
            <label>Sector</label>
            <div className={styles.ConsultantInputWrapper}>
              <FormControl sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                <Select
                  error={error.sector_id ? true : false} value={formInput.sector_id}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="sector_id"
                >
                  {
                    store?.sectorListConsultant?.map((item, index) => (
                      <MenuItem value={item.bs_id}>{item.bs_name}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.sector_id ? error.sector_id : null}</FormHelperText>
              </FormControl>
            </div>
          </div>


        </div>
        </form>

        <div className={styles.ExpertiseListWrapper}>
 <div className={styles.ExpertiseSection}>
          <h4 className={styles.ExpertiseSectionTitle}>Expertise</h4>
          <div className={styles.ExpertiseSectionList}>
            {
              store?.expertiseListConsultant?.map((elem, index) => (
                <div key={index} className={styles.ExpertiseSectionListItem}>
                  <CheckboxComponent label={elem?.expertise_name} checked={expertiseBool[elem?.expertise_name]} handleCheckBoxChange={handleCheckBoxChange} />
                </div>
              ))
            }



          </div>

        </div>
        </div>

        <div className={styles.AddConsultantCheckListWrapper}>
          <div className={styles.AddConsultantCheckList}>
            <div className={styles.AddConsultantCheckListTitleWrapper}>
              <h2 className={styles.AddConsultantCheckListTitle}>Upload Document</h2>
            </div>
            <div className={styles.AddConsultantCheckListUploaded}>
              <div className={styles.AddConsultantButtonRowWrapper}>
                <div className={styles.AddConsultantCkLstUpldBtn}>
                  <label htmlFor="uploadChckLst"><UploadOutlinedIcon />Upload</label>
                  <input type="file" id="uploadChckLst" name="consultant" onChange={handleFileSelect} />
                </div>
              </div>
              <div className={styles.AddConsultantCkLstUpldDocsWrapper}>
                {
                  formInput?.consultant_document_list?.length !== 0 ?
                    formInput?.consultant_document_list?.map((elem, index) => (
                      <div className={styles.AddConsultantCkLstUpldDocs} key={index}>
                        <a href={elem?.file_path} target={allowedExtensions.includes((elem?.file_path?.substring(elem?.file_path?.lastIndexOf('.') + 1)).toLowerCase())  ?
                          "_blank" : null
                        }>{elem?.file_path?.substring(elem?.file_path?.lastIndexOf('/') + 1)}</a>
                        <ClearIcon fontSize='small' color='warning' style={{ cursor: "pointer" }} onClick={() => handleClearDocument(elem?.file_path, elem?.crd_id, index)} />
                      </div>)) : null
                }
              </div>
            </div>

          </div>
        </div>

        <div className={styles.ConsultantCommentsWrapper}>
          <div className={styles.CommentsLabel}><label>Notes</label></div>
          <input type="textarea" name='comments' autoComplete='off' className={styles.ConsultantNotes} placeholder={formInput.comments} onChange={handleChange} value={formInput.comments} />
        </div>
        
        <div className={styles.SubmitButtonWrapper}><SubmitButton onClick={handleUpdateConsultant}>Submit</SubmitButton>
            </div>
    
      </div>

      { remove && <DeletePromptConsultantFile remove={remove}  setRemove={setRemove} file={deleteData} ToastShow = {handleDelete}/>}
    </>

  )
}


export default UpdateConsultant