import React, { useEffect, useState } from 'react'
import styles from './styles/contractManagementDetails.module.css';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import ContractManagementModal from './ContractManagementModal';
import editIcon from "./images/editIconNew.png";
import { columnData, SelectALLData} from "./ContractManagementData";
import ContractorTable from './ContractorTable';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate,Link, useLocation } from 'react-router-dom';
import exportFromJSON from "export-from-json"
import { OPPORTUNITYEXPORTALLOWED } from '../../hooks/AllowedRoles';
import { GetContractorDetailListExport, GetContractorList } from '../../actions/contractor/ContrractorActions';
import { NormalDateFormat } from '../../utility/DateFunction';
import { TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Export } from '../../utility/export';


const SearchFilter = (data, columns, q) => {
    if (data !== undefined && data !== null && data.length !== 0) {
        return data.filter((row) =>
            columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
    }
}

const ContractManagementDetails = () => {
    const navigate = useNavigate()
    const store = useSelector(state => state.Contractor)
    const Axios = useAxiosPrivate()
    const dispatch = useDispatch()
    const [selectAll, setSelectAll] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [state, setState] = useState(columnData)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(10);
    let [show, setShow] = useState(false)
    const [contractorList, setContractorList] = useState([])
    const IndexOfLastPost = currentPage * postsPerPage
    const IndexOfFirstPost = IndexOfLastPost - postsPerPage
    const pageNumbers = Math.ceil(contractorList?.length / postsPerPage)
    const currentPosts = SearchFilter(contractorList, ['project_title', 'contractor_name'], searchText)?.slice(IndexOfFirstPost, IndexOfLastPost);
    const LoginStore = useSelector(state => state.Login)
    let [exportContractorList,setExportContractorList] = useState([])
    const [sort, setSort] = useState('project_title')
    const location = useLocation();


    let [columns, setColumns] = useState(["project_title", "contractor_name" ,"pan_no", "gst_no", "cell_no","email","contract_start_date","contract_end_date"
    ]);

    let arr = columns;

    useEffect(() => {
        dispatch(GetContractorList(Axios))
        let ObjectKeys = Object.keys(columnData);
        let CheckArr = []
        ObjectKeys.map((item, index) => {
            if (columnData[item]) {
                if (arr.includes(item)) {
                    CheckArr = [...CheckArr,item]
                    setColumns(
                        CheckArr
                    )
                }
                else {
                    arr.push(item);
                    setColumns(arr)
                }
            }
        }
        )
    }, [])
    useEffect(() => {
        let key_name = "project_title";
        const sorted = store?.contractorList?.slice().sort((a, b) =>  a[key_name]?.localeCompare(b[key_name]))
        setContractorList(sorted)
    }, [store])

    useEffect(()=>{
        let Page = location?.pathname?.substring(location?.pathname?.lastIndexOf('Page:'));
        let PageNumber = Page?.substring(Page?.lastIndexOf(':')+1);
        console.log(PageNumber)
        setCurrentPage(parseInt(PageNumber))
    },[location])
    const handleNavigate = () => {
        navigate('/AddContractor')
    }

    let toggleColumn = () => {
        setShow(!show)
    }
    const ExportToExcel = async() => {
        if (OPPORTUNITYEXPORTALLOWED.includes(LoginStore?.role?.role_code)) {
          
           for(let k=0; k<store?.contractorList?.length; k++){
            let param = {
                "contract_id":store?.contractorList[k]?.contract_id
            }
            let OBJ = {}
            let ContractorData = await GetContractorDetailListExport(Axios,param)
            OBJ['Cell No'] = ContractorData?.cell_no
            OBJ['Contract Code'] = ContractorData?.contract_code
            OBJ['Contract End Date'] = NormalDateFormat(ContractorData?.contract_end_date)
            OBJ['Contract Start Date'] = NormalDateFormat(ContractorData?.contract_start_date)
            OBJ['Contract Value'] = ContractorData?.contract_value
            OBJ['Contractor Name'] = ContractorData?.contractor_name
            OBJ['Created At'] = NormalDateFormat(ContractorData?.created_at)
            OBJ['Email'] = ContractorData?.email
            OBJ['GST No'] = ContractorData?.gst_no
            OBJ['No of Invoices'] = ContractorData?.invoices_list?.length
            OBJ['PAN No'] = ContractorData?.pan_no
            OBJ['Project Code'] = ContractorData?.project_code
            OBJ['Project Title'] = ContractorData?.project_title
            OBJ['Updated At'] =NormalDateFormat(ContractorData?.updated_at)
            ContractorData?.invoices_list?.map((invoiceData,i)=>{
                OBJ[`Invoice ${i+1} Invoice Code`] = invoiceData.ci_code
                OBJ[`Invoice ${i+1} Invoice Amount`] = invoiceData.invoice_amount
                OBJ[`Invoice ${i+1} Invoice Date`] = NormalDateFormat(invoiceData.invoice_date)
                invoiceData?.payment_list?.map((invoicePaymentData,j)=>{
                    OBJ[`Invoice ${i+1} Payment ${j+1} Invoice Amount`] = invoicePaymentData?.invoice_amount
                    OBJ[`Invoice ${i+1} Payment ${j+1} Invoice Date`] = NormalDateFormat(invoicePaymentData?.invoice_date)
                    OBJ[`Invoice ${i+1} Payment ${j+1} GST Included`] = invoicePaymentData?.gst_included
                })
            })
           exportContractorList?.push(OBJ)
           }
    Export(exportContractorList,"Contractor List")
        }
        else{
            toast.error("No Access to Download")
          }
    }

  
    const handleSearch = (e) => {
        setSearchText(e.target.value)
        navigate('/ContractorDetail/Page:1')
    }
    const handleSelectAll = (e) => {
        setSelectAll(!selectAll);

        if (e.target.checked) {
            setColumns(Object.keys(state));
            setState(SelectALLData)
        }
        else{
            setColumns(["project_title", "contractor_name","pan_no", "gst_no" , "cell_no","email","contract_start_date","contract_end_date"])
            setState(columnData)
        }

    }
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        let path = location?.pathname.substring(0, location?.pathname.indexOf(':'));
        navigate(path + `:${value}`)
    };

    const handleSort = (e) => {
        let key_name = e.target.value;
        console.log(key_name)
        const sorted = contractorList.slice().sort((a, b) =>  a[key_name]?.localeCompare(b[key_name]))
        setContractorList(sorted)
        console.log(sorted)
    }


    console.log(store)
    //.log(columns, "colombus")
    return (
      <>
         <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
       <div className={styles.rootContainer}>
              
            <div>
                <h2 className={styles.CntctMngDetailsTitle}>Contractor Management Details</h2>
            </div>

            <div className={styles.filterWrapper}>
            <div className={styles.filterOptionsLeftWrapper}>
                <div className={styles.filterOptionsWrapper}>
                    <div className={styles.fltrOpnsColumn} onClick={toggleColumn}>
                        <TableChartOutlinedIcon color='success' />
                        <label>Column</label>
                    </div>
                    {
                         OPPORTUNITYEXPORTALLOWED.includes(LoginStore?.role?.role_code) ? <div className={styles.fltrOpnsColumn} onClick={ExportToExcel}>
                        <IosShareOutlinedIcon />
                        <p>Export</p>
                    </div> : null

                    }
                   
                </div>
                <div className={styles.ButtonWrapper}>
                    <button className={styles.AddContractButton} onClick={handleNavigate}>Add Contract</button>
                    </div>
                    <div className={styles.searchWrapper}>
                        <TextField variant="outlined" label="Project Title/Contractor" className={styles.searchInputField} sx={{ minWidth: "150px", maxWidth: '170px' }} size="small" name="searchList" autoComplete='off' onChange={handleSearch} value={searchText} />
                    </div>
                </div>
                <div className={styles.fltrOpnsColumnRightWrapper}>
                    <Pagination shape="rounded" count={pageNumbers} page={currentPage} className={styles.tablePagination} onChange={handlePageChange} />
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={styles.sortTableButtonWrapper}>
                        <InputLabel >Sort By</InputLabel>
                        <Select className={styles.sortTableButton}
                            label="Sort By"
                            defaultValue={sort}
                            onChange={handleSort}
                        >
                            {/* <MenuItem className={styles.sortTableButtonList} value="contract_start_date">Contract Start Date</MenuItem>
                            <MenuItem className={styles.sortTableButtonList} value="contract_value">Contract Value </MenuItem> */}
                            <MenuItem className={styles.sortTableButtonList} value="project_title">Project Title</MenuItem>
                            <MenuItem className={styles.sortTableButtonList} value="contractor_name">Contractor Name </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

          <div className={styles.ContractsDetailTableWrapper}>
                <ContractorTable  columns={columns} currentPosts={currentPosts} currentPage={currentPage} postsPerPage={postsPerPage}/>
          </div>
            {show === true ? <ContractManagementModal toggle={show} toggleSet={setShow} columns={columns} setColumns={setColumns} state={state} setState={setState} selectAll={selectAll} handleSelectAll={handleSelectAll} /> : null}
        
            </div>
      </>
       
       
    )
}

export default ContractManagementDetails