import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './styles/DeletePromptTender.module.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton, SubmitButton } from '../../utility/button/button';
import { DeleteTender } from '../../actions/Tender/tenderAction';


function MyVerticallyCenteredModal(props) {
    const [folderText, setFolderText] = useState('')
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    const { setRemove,file,ToastShow } = props

    const [error, setError] = useState({})

    let handleChange = (e) => {
        setFolderText(e.target.value)

    }


    let handleDelete = async () => {
         let param = {
            "tender_id": file?.tender_id
        }
        const data = await dispatch(DeleteTender(Axios, param))
        ToastShow(data);
    }
    return (
        <>
           
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.PopUpBox}
            >
                <Modal.Header closeButton className={styles.PopUpHeader}>
                    <Modal.Title className={styles.PopUpTitle}>
                       {`Are You Sure to delete ?`} 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={styles.addFolderInputWrapper}>
                         
                                <div style={{display:"flex",flexDirection:"column"}}>
                                <label>{file?.bid_title}- ({file?.tender_code})</label>
                                <label>{`tender Contains files`}</label>
                                </div>
                         
                    </div>
                </Modal.Body>
                <div className={styles.PopUpSubmit}>
                    <SubmitButton onClick={handleDelete}>Delete</SubmitButton>
                    <CancelButton onClick={()=>setRemove(false)}>Cancel</CancelButton>
                </div>
            </Modal></>
    );
}
function DeleteFilePromptTable(props) {
    let { remove, setRemove,file,ToastShow} = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={remove}
                onHide={() => setRemove(!remove)}
                setRemove={setRemove}
                file={file}
                ToastShow={ToastShow}
            />
        </>
    );
}

export default DeleteFilePromptTable