import { GETALLDOCUMENTS } from "../../reduxConstants/documentReducerConstant";
import axios from 'axios'
import { BASE_URL } from "../../utility/baseUrl";


export const GetAllDocuments = (Axios, param) => async (dispatch) => {
    try {
        const { data } = await Axios.post("/get_all_documents", param);
     
        if (data?.code === 200) {
            dispatch({ type: GETALLDOCUMENTS, payload: data?.file_list })
        }
    }
    catch (error) {

        return error?.response?.data
    }
}

export const AddFolderDocument = (Axios, param) => async (dispatch) => {
    try {
        const { data } = await Axios.post("/create_folder", param);
        if (data.code === 201) {
            let paramDoc = { "path": param?.path }
            dispatch(GetAllDocuments(Axios, paramDoc))
            return data
        }

    }
    catch (error) {
        return error?.response?.data
    }
}


export const DeleteDocument = (Axios, param, path) => async (dispatch) => {
    try {

        const { data } = await Axios.post("/delete_file", param);
      
        if (data.code === 200) {
            let paramDoc = { "path": path };
           
            dispatch(GetAllDocuments(Axios, paramDoc))
            return data
        }

    }
    catch (error) {
        return error?.response?.data
    }
}

export const RenameDocument = (Axios, param, path) => async (dispatch) => {
    try {

        const { data } = await Axios.post("/rename_file", param);
      
        if (data.code === 200) {
            let paramDoc = { "path": path };
           
            dispatch(GetAllDocuments(Axios, paramDoc))
            return data
        }

    }
    catch (error) {
        return error?.response?.data
    }
}


export const DocumentFileUpload = async (formData,setProgress) => {
    try {

        const { data } = await axios.post(`${BASE_URL}/document_file_upload`, formData,{
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              setProgress(prev=>{
                  return [...prev,percentage]
              })
            }
          });
        return data

    }
    catch (error) {
        return error?.response?.data
    }
}