import React, { useState,useEffect } from 'react'
import styles from "./styles/ctrlFldsNotice.module.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddNotice from './AddNotice';
import { useDispatch, useSelector } from 'react-redux'
import { NoticeList,UpdateNoticeApi} from '../../../actions/controlFields/controlFieldsActions';
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateNotice from './UpdateNotice';
import { Card } from '@mui/material';
import { CancelButton, CtrlFldsSubmitButton } from '../../../utility/button/button';


const CtrlFldsNotice = () =>  {
    const store = useSelector(state => state.ControlFields);
    const [noticeList, setNoticeList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)


    useEffect(() => {
      dispatch(NoticeList(Axios))
    }, [])

    useEffect(
        ()=>{
        setNoticeList(store?.noticeList)
    },[store.noticeList])

    let toggleEdit = () => {
        setShow(!show)
    }
    const handleList = (notice, value) => {
        setNoticeList(noticeList.map(item => item.notice_id === notice.notice_id ? {
            ...notice,
            notice_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setNoticeList(store?.noticeList)
    }
    const IsValid=()=>{
        let success = true;
        const data = noticeList.map(item=> ((item?.notice_name ==='') ||  item?.notice_name?.length===0 || item?.notice_name===undefined || item?.notice_name===null) ? true : false);
         
        if(data.includes(true)){
          success = false;
          toast.error("Notice Fields Cannot Be Empty!!")
        }else{
          success = true
        } 
        return success
   }

  const handleSubmit = async () => {
      if(IsValid()){
        setLoading(true)
        let param = {
            "list": noticeList
        }

        const data = await dispatch(UpdateNoticeApi(Axios, param));
           //console.log(data)
        if (data?.code === 200) {
            setLoading(false)
            setShow(false)
            toast.success('Updated Notices SuccessFully')
        }
        else {
            setLoading(false)
            toast.error(`${data?.message}`)
        }
    }
}
    return (
        <>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.CtrlFields}>
            {/* <div className={styles.CtrlFldTitle}>Admin Control Fields</div> */}
            <div className={styles.CtrlFldsBodyContainer}>
                <div className={styles.CtrlFldsBodyContent}>
                    <div className={styles.CtrlFldsBodyTitle}>
                        <h2>Notice</h2>
                    </div>
                    <AddNotice/>
                    <div className={styles.Seperator} >
                        <div className={styles.SeperatorLine}></div>
                        <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                    </div>
                    <div className={styles.CtrlFldsBody}>
                        <div className={styles.CtrlFldsBodyItems}>
                            {
                                noticeList.map(
                                    (item, key) =>
                                    (
                                        show ?
                                            <UpdateNotice item={item} handleList={handleList}/> :
                                            <Card className={styles.listCard}>{item.notice_name}</Card>
                                    )
                                )
                            }

                        </div>
                        {
                            show === true ?
                                <div className={styles.CtrlFldsSubmitEdit}>
                                     <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                </div> : null
                        }

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default CtrlFldsNotice