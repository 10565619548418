export const convertDate = (date) => {
    let day=0;
    let year=0;
    let month=0;
     const dealsDate = new Date(date)
     day = dealsDate.getDate();
     year = dealsDate.getFullYear();
     month = `0${dealsDate.getMonth() + 1}`.slice(-2);

     if(day<10){
        day= `0${day}`
    }

    const fdate = `${year}-${month}-${day}`
    return fdate
};

export const TableconvertDate = (date) => {
    let day=0;
    let year=0;
    let month=0;
    let Hour=0;
    let Minutes=0;
    let seconds=0;
    const TodaysDate = new Date();
    // seconds=TodaysDate.getSeconds();
     const dealsDate = new Date(date)
     day = dealsDate.getDate();
     year = dealsDate.getFullYear();
     month = `0${dealsDate.getMonth() + 1}`.slice(-2);
     Hour = dealsDate.getHours();
     Minutes=dealsDate.getMinutes();
     let  ampm = Hour >= 12 ? 'pm' : 'am';
     Hour = Hour % 12;
     Hour = Hour ? Hour : 12;
     if(day<10){
        day= `0${day}`
    }
    if(Hour<10){
        Hour = `0${Hour}`
    }
    if(Minutes<10){
        Minutes=`0${Minutes}`
    }
    if(seconds<10){
        seconds=`0${seconds}`
    }
    const fdate = `${day}-${month}-${year} ${Hour}:${Minutes} ${ampm}`
    return fdate
};

export const ConvertTime = (date) => {
    let day=0;
    let year=0;
    let month=0;
    let Hour=0;
    let Minutes=0;
    let seconds=0;
    const TodaysDate = new Date();
    // seconds=TodaysDate.getSeconds();
     const dealsDate = new Date(date)
     day = dealsDate.getDate();
     year = dealsDate.getFullYear();
     month = `0${dealsDate.getMonth() + 1}`.slice(-2);
     Hour = dealsDate.getHours();
     Minutes=dealsDate.getMinutes();
     let  ampm = Hour >= 12 ? 'pm' : 'am';
     Hour = Hour % 12;
     Hour = Hour ? Hour : 12;
     if(day<10){
        day= `0${day}`
    }
    if(Hour<10){
        Hour = `0${Hour}`
    }
    if(Minutes<10){
        Minutes=`0${Minutes}`
    }
    if(seconds<10){
        seconds=`0${seconds}`
    }
    const fdate = `${Hour}:${Minutes} ${ampm}`
    return fdate
};
export const NormalDateFormat = (date) => {
    let day=0;
    let year=0;
    let month=0;
     const dealsDate = new Date(date)
     day = dealsDate.getDate();
     year = dealsDate.getFullYear();
     month = `0${dealsDate.getMonth() + 1}`.slice(-2);

     if(day<10){
        day= `0${day}`
    }
    const fdate = `${day}-${month}-${year}`
    return fdate
};




export const UserMgtTableDate = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];
    let day=0;
    let year=0;
    let month=0;
    let Hour=0;
    let Minutes=0;
    let seconds=0;
    const TodaysDate = new Date();
    // seconds=TodaysDate.getSeconds();
     const dealsDate = new Date(date)
     day = dealsDate.getDate();
     year = dealsDate.getFullYear();
     month = `0${dealsDate.getMonth() + 1}`.slice(-2);
     Hour = dealsDate.getHours();
     Minutes=dealsDate.getMinutes();
     let  ampm = Hour >= 12 ? 'pm' : 'am';
     Hour = Hour % 12;
     Hour = Hour ? Hour : 12;
     if(day<10){
        day= `0${day}`
    }
    if(Hour<10){
        Hour = `0${Hour}`
    }
    if(Minutes<10){
        Minutes=`0${Minutes}`
    }
    if(seconds<10){
        seconds=`0${seconds}`
    }

    const TextMonth = monthNames[dealsDate.getMonth()];
    const last2Again = dealsDate.getFullYear().toString().slice(-2);

    const fdate = `${day}-${TextMonth}-${last2Again} ${Hour}:${Minutes} ${ampm}`
    return fdate
};