import React, { useEffect,useState } from 'react';
import styles from './styles/catalougue.module.css';
import { useDispatch, useSelector } from 'react-redux';
import editIcon from "./images/editIcon.png";
import { NormalDateFormat, UserMgtTableDate } from '../../utility/DateFunction';
import { Avatar } from '@mui/material';
import { DeleteTender, GetDocumentsForTenderById, GetTenderDetail, GetUserListTender } from '../../actions/Tender/tenderAction';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import {AiFillStar} from 'react-icons/ai'
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'save-as';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteFilePromptTable from './DeletePromptTender';

const TenderTable = ({ columns, currentPosts, currentPage, postsPerPage }) => {
    const navigate = useNavigate()
    const store = useSelector(state => state.Login);
    const location = useLocation();
    const [remove,setRemove]=useState(false)
    const dispatch = useDispatch();
    const Axios = useAxiosPrivate();
    const [deleteData,setDeleteData] = useState({})

    useEffect(()=>{
        dispatch(GetUserListTender(Axios))
    },[])
    const countt = currentPage * postsPerPage - postsPerPage;


    const handleTenderDetail = async (item) => {


        navigate(`${location?.pathname}/updateTender/${item?.tender_id}`)
        let param = {

            "tender_id": item?.tender_id

        }
        dispatch(GetTenderDetail(Axios, param))
    }

    
    const handleDeleteTender = async(item) => {
        setRemove(true)
        setDeleteData(item)
    
        // let param = {
        //     "tender_id": item?.tender_id
        // }
        // const data = await dispatch(DeleteTender(Axios, param))
        // if (data?.code === 200) {
        //     toast.success('Deleted Tender SuccessFully');
        // }
        // else {
        //     toast.error(`${data?.message}`)
        // }
        
    }

    const ToastShow =(data)=>{
        if (data?.code === 200) {
            setRemove(false)
            toast.success('Deleted Tender SuccessFully');
        }
        else {
            toast.error(`${data?.message}`)
        }
    }

    
    const handleBidDocDownload = async (tender_id) => {
        let param = {
            "tender_id": tender_id
        }
        const data = await dispatch(GetDocumentsForTenderById(Axios, param))

        if(data?.code === 200){
              var zip = new JSZip();
        var count = 0;
        var zipFilename = "Bid Document.zip";
        var urls = [];
        
        if(data?.documentList?.length !== 0){
            try {
                   data?.documentList?.map((elem)=>{
                 urls = [...urls , elem?.file_path]
            })  
            urls.forEach(function(url){
              var filename = url?.substring(url?.lastIndexOf("/")+1);
              // loading a file and add it in a zip file
              JSZipUtils.getBinaryContent(url, function (err, data) {
                 if(err) {
                    toast.error("No Documents uploaded/Files Missing")
                    throw err;
                 }
                 zip.file(filename, data, {binary:true});
                 count++;
                 if (count == urls.length) {
                   zip.generateAsync({type:'blob'}).then(function(content) {
                      saveAs(content, zipFilename);
                   });
                }
              });
            });
            } catch (error) {
                toast.error("No Documents uploaded/Files Missing")
            }
         }
         else{
            toast.error("No Documents uploaded/Files Missing")
        }
        }
    }



    return (
        <> <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <div>
            <table bordered striped hover className={styles.CatalougueTable}>
                <thead className={styles.CatalougueTableHead}>
                    <tr className={styles.TableFixedRow}>
                        {/* <th className={styles.CatalougueTableTH}>Sl No.</th> */}
                        {
                            columns?.map((list) =>
                                <th className={styles.CatalougueTableTH}>
                                    {
                                    list === "nominated_user_name"? "NOMINATED USER" :
                                    list === "submission_date_time"? "SUBMISSION DATE" :
                                    list === "updated_by_name"? "UPDATED BY" :
                                    list === "tender_fee"? "BID/TENDER FEE" :
                                    list === "security_fee"? "BID SECURITY" :
                                    list === "security_refund_date_time"? "EMD Refund Date" :
                                    list.replace(/_/g, ' ').toUpperCase()
                                    }
                                    </th>
                            )

                        }
                        <th className={styles.CatalougueTableTH}>DOWNLOAD</th>
                        <th className={styles.CatalougueTableTH}>EDIT</th>
                        <th className={styles.CatalougueTableTH}>DELETE</th>
                    </tr>

                </thead>
                <tbody className={styles.TableBody}>
                    {
                        currentPosts?.map((item, index) => (
                            <tr className={index % 2 === 1 ? styles.TableBodyAltBg :  null} key={index} >
                                {/* <td className={styles.CatalougueTableTD}>{countt + index + 1}</td> */}
                                {
                                    columns?.map((column) =>
                                        <td className={ styles.CatalougueTableTD}>
                                         {
                                                 column === 'tender_code' ? 
                                                (item?.selected_for_bidding === "Yes" ? 
                                            <> 
                                            <button className={styles.selectedForBidding}>{item?.tender_code}</button>
                                            {store?.user_id===item?.nominated_user_id ? <AiFillStar style={{color:"gold"}}/>:null} </>
                                               :
                                               <> 
                                               {item?.tender_code}{store?.user_id===item?.nominated_user_id ? 
                                               <AiFillStar style={{color:"gold"}}/>:null} 
                                               </>
                                                ) :
                                                      column?.endsWith('date') || (column?.endsWith('time')) || (column?.endsWith('at')) ? (item[column] === null || item[column] === undefined || NormalDateFormat(item[column])==="01-01-1970" ? "--/--/----" : NormalDateFormat(item[column])) 
                                                : (column === 'tender_fee' || column === 'security_fee')? parseInt(item[column])?.toLocaleString('en-IN'):  <label className={styles.CatalougueTableTD} title={item[column]}>{item[column]}</label>
                                         }
                                        </td>
                                    )
                                }
                                <td className={styles.CatalougueTableTD} onClick={()=> handleBidDocDownload(item?.tender_id)}  >  <DownloadIcon style={{ color: "#ff9800", cursor: "pointer" }}/></td>
                                <td className={styles.CatalougueTableTD} onClick={() => handleTenderDetail(item)}><img src={editIcon} style={{cursor:'pointer', width: 24, height: 24 }} /></td>

                                <td className={styles.CatalougueTableTD}
                                onClick={()=>handleDeleteTender(item)}><DeleteIcon style={{cursor: "pointer"}}/></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>

        {remove &&  <DeleteFilePromptTable  remove={remove} setRemove={setRemove} file={deleteData} ToastShow={ToastShow}/>}
        </>
       
    )
}

export default TenderTable