import { AuthFileaxios } from "../../api/axios";
import { GETBIDCATEGORYLISTTENDER, GETBIDFUNCTIONLISTTENDER, GETBIDSECTORLISTTENDER, GETDONORLISTTENDER, GETFINANCIALLISTTENDER, GETMODELISTTENDER, GETNOTICELISTTENDER, GETROLELISTTENDER, GETSTATELISTTENDER, GETTENDERDETAIL, GETTENDERLIST, GETUSERLISTTENDER }  from "../../reduxConstants/tenderReducerConstants";



export const GetTenderList = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/tender_list");
        
        if(data.code===200){
            dispatch({type:GETTENDERLIST,payload:data?.tender_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const DeleteTender = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/tender_delete",param);
         if(data.code===200){
           dispatch(GetTenderList(Axios)) 
           return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const AddTenderList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_tender",param);
        //console.log(data)
        if(data.code===201){
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}
export const DocumentDeleteTenderAdd =(Axios,param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/tender_file_delete_Add`,param)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

export const GetTenderDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/tender_detail",param);
        //console.log(data)
        if(data.code===200){
            dispatch({type:GETTENDERDETAIL,payload:data?.tender_detail[0]})
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}



export const GetStateListTender = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/control_field_state_list");
        
        if(data.code===200){
            dispatch({type:GETSTATELISTTENDER,payload:data?.state_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}
export const GetDonorListTender = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/control_field_donor_list");
        
        if(data.code===200){
            dispatch({type:GETDONORLISTTENDER,payload:data?.donor_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetNoticeListTender = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/control_field_notice_list");
        
        if(data.code===200){
            dispatch({type:GETNOTICELISTTENDER,payload:data?.notice_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}
export const GetModeListTender = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_mode_list");
        
        if(data.code===200){
            dispatch({type:GETMODELISTTENDER,payload:data?.mode_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}
export const GetFinancialListTender = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_financial_list");
        
        if(data.code===200){
            dispatch({type:GETFINANCIALLISTTENDER,payload:data?.financial_year_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetBidCategoryListTender = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_bid_category_list");
        
        if(data.code===200){
            dispatch({type:GETBIDCATEGORYLISTTENDER,payload:data?.Bid_Category_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetBidFunctionListTender = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_bid_function_list");
        
        if(data.code===200){
            dispatch({type:GETBIDFUNCTIONLISTTENDER,payload:data?.Bid_Function_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}
export const GetBidSectorListTender = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_bid_sector_list");
        
        if(data.code===200){
            dispatch({type:GETBIDSECTORLISTTENDER,payload:data?.Bid_Sector_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}


export const FileUploadTender = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/tender_documents`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }

 export const GetUserListTender = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/tender_user_list");
         console.log(data)
        if(data.code===200){
            dispatch({type:GETUSERLISTTENDER,payload:data.userList})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetRoleListTender = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/role_list");
         //console.log(data)
        if(data.code===200){
            dispatch({type:GETROLELISTTENDER,payload:data.roleList})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const DocumentDeleteTender =(Axios,param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/tender_file_delete`,param)
         if(data.code===200){
             let ParamTwo = {
                "tender_id": param?.tender_id
            }
            return dispatch(GetDocumentsForTenderById(Axios,ParamTwo))
         }
    } catch (error) {
     return error?.response?.data
    }
 }


 export const DocumentDeleteTenderEmpty =(Axios,param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/tender_file_delete_empty`,param) ;     console.log(data)
         if(data.code===200){
      
         }
    } catch (error) {
     return error?.response?.data
    }
 }




export const GetDocumentsForTenderById = (Axios,param) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/tender_document_list",param);
         //console.log(data)
        if(data.code===200){
             return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}


export const UpdateTenderDetail = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_tender",param);
        //console.log(data)
        if(data.code===200){
            dispatch(GetTenderList(Axios))
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}


export const FileUploadTenderUpdate = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/update_tender_documents`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }