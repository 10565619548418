import React, { useRef, useState } from 'react'
import styles from "./styles/ForgotPassword.module.css"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ResetPassword, SendEmail, ValidateOtp } from '../../actions/login/login';
import { useDispatch } from 'react-redux';
import { LOGINUSERDATA } from '../../reduxConstants/LoginReducerConstant';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ErrorButton, LoginButton, SuccessButton } from '../../utility/button/button';
import './styles/forgotEmail.scss'


const ForgotPassword = ({ setCredentials, setId, SetChangePass, setForgotPass }) => {
    const emailRef = useRef();
    const OtpRef = useRef();
    const [emailLoader, setEmailLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false)
    const [OtpLoader, setOtpLoader] = useState(false);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [error, setNewError] = useState({});
    const [otp, setOtp] = useState("");
    const [mailsuccess, setMailsuccess] = useState(false);
    const [userId, setUserId] = useState(0);


    const handleFocus = () => {
        emailRef.current.focus();
        setSuccess(false)
        setFail(false)
        setNewError({})
        setEmailLoader(false)
    }

    const handleFocusOtp = () => {
        OtpRef.current.focus();
        setSuccess(false)
        setFail(false)
        setNewError({})
        setOtpLoader(false)
    }


    const handleOtpChange = (e) => {
        setOtp(e.target.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const IsValid = () => {
        let success = true;
        let error = {};

        if (!email.trim()) {
            error.email = "**Please Type An Email!!**";
            success = false;
        }

        setNewError(error)
        return success
    }

    const handleForgotEmail = async (e) => {

        
        e.preventDefault()

        if (IsValid()) {
            setEmailLoader(true)
            let param = {
                "email": `${email}`,
            }

            const data = await dispatch(SendEmail(param));
            if (data?.code === 200) {
                setSuccess(true)
                setTimeout(() => {
                    setMailsuccess(true)
                    setSuccess(prev => {
                        return !prev
                    })
                }, [1000])

                setUserId(data?.user_id);
                setEmailLoader(false)
            }
            else {
                setFail(true)
                setNewError({
                    emailSend: data?.message
                })
                setEmailLoader(false)
            }



        }


    }


    const IsValidOtp = () => {
        let success = true;
        let error = {};

        if (!otp.trim()) {
            error.otp = "**Please Enter Otp!!**";
            success = false;
        }

        setNewError(error)
        return success
    }

    const handleSubmitOtp = async (e) => {

       
        e.preventDefault()
        if (IsValidOtp()) { 
            setOtpLoader(true)
            let param = {
                "otp": otp,
                "user_id": userId,
                "email": email
            }

            const data = await dispatch(ValidateOtp(param));

            if (data?.code === 200) {
                setSuccess(true)
                setId(userId);
                setCredentials(prev => {
                    return {
                        ...prev,
                        uname: email
                    }

                })
            
                setTimeout(() => {
                    setOtpLoader(false);
                    SetChangePass(true);
                    setForgotPass(false);
                    setSuccess(prev => {
                        return !prev
                    })
                }, [1000])

            }
            else{
                setFail(true)
                setNewError({
                    OtpError: data?.message
                })
                setOtpLoader(false)
            }
        }


    }


    return (
        <div className="ForGotEmailRootContainer">

            <div className={styles.ChangePassWrapper}>
                {
                    mailsuccess ? (
                        <form onSubmit={handleSubmitOtp} >
                            <div className={styles.Container}>
                                {error.OtpError && <h2 className={styles.ErrorHeading}>{error.OtpError}</h2>}
                                <div className={styles.ChangePassForm}>

                                    <h2 className={styles.ChangePassFormTitle}>Forgot Password</h2>
                                    <div>
                                        <div className={styles.ChangePassPassword}>
                                            <MailOutlineIcon />
                                            <input className={styles.ChangePassFormInput} ref={OtpRef} onFocus={handleFocusOtp} onChange={handleOtpChange} value={otp} type="text" placeholder='Otp' autoComplete='none' />
                                        </div>
                                        {error?.otp && <span className={styles.Error}>{error?.otp}</span>}
                                    </div>

                                    <div className={styles.ChangePassSignInButtonWrapper}>
                                        {
                                            success ? (<SuccessButton>Success</SuccessButton>) : (fail ? (<ErrorButton>UnAuthorized</ErrorButton>) : (<LoginButton type='submit' loading={OtpLoader} >Send Otp</LoginButton>))
                                        }

                                    </div>
                                </div>
                            </div>

                        </form>
                    ) :
                        (
                            <form onSubmit={handleForgotEmail} >
                                <div className={styles.Container}>
                                    {error.emailSend && <h2 className={styles.ErrorHeading}>{error.emailSend}</h2>}
                                    <div className={styles.ChangePassForm}>
                                        <h2 className={styles.ChangePassFormTitle}>Forgot Password</h2>
                                        <div>
                                            <div className={styles.ChangePassPassword}>
                                                <MailOutlineIcon />
                                                <input className={styles.ChangePassFormInput} onChange={handleEmailChange} value={email} type="email" ref={emailRef} placeholder='Email' autoComplete='none' onFocus={handleFocus} />
                                            </div>
                                            {error?.email && <span className={styles.Error}>{error?.email}</span>}
                                        </div>

                                        <div className={styles.ChangePassSignInButtonWrapper}>
                                            {
                                                success ? (<SuccessButton>Success</SuccessButton>) : (fail ? (<ErrorButton>UnAuthorized</ErrorButton>) : (<LoginButton type='submit' loading={emailLoader} >Send Email</LoginButton>))
                                            }


                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                }

            </div>
        </div>
    )
}

export default ForgotPassword