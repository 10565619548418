import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import styles from "./styles/addTender.module.css"
import { useDispatch, useSelector } from 'react-redux'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AddTenderList, DocumentDeleteTender, FileUploadTender, FileUploadTenderUpdate, GetBidCategoryListTender, GetBidFunctionListTender, GetBidSectorListTender, GetDonorListTender, GetFinancialListTender, GetModeListTender, GetNoticeListTender, GetRoleListTender, GetStateListTender, GetTenderDetail, GetUserListTender, UpdateTenderDetail, UpdateTenderList } from '../../actions/Tender/tenderAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ADDALLOWEDROLESOPPORTUNITY, LEVEL3 } from '../../hooks/AllowedRoles';
import { SubmitButton } from '../../utility/button/button';
import DeleteTenderPromptDocument from './DeleteTenderPromptDocument';


const UpdateTender = () => {
  const navigate = useNavigate()
  const location = useLocation();
  let [formInput, setFormInput] = useState({
    "bid_tracking_date": new Date(),
    "bid_published_date": '',
    "bid_title": "",
    "state_id": 0,
    "bid_source": "",
    "donor_id": 0,
    "notice_id": 0,
    "pre_bid_meeting_date": '',
    "submission_date_time": '',
    "submission_mode": 0,
    "hand_submission": "No",
    "tender_fee": 0,
    "security_fee": 0,
    "bid_category": 0,
    "bid_sector": 0,
    "bid_function": 0,
    "selected_for_bidding": "No",
    "bid_advice": "",
    "bid_task_deadline": "",
    "bid_review_deadline": "",
    "nominated_user_id": 0,
    "consortium_partners": "",
    "bid_submitted": "No",
    "actual_submission_date": '',
    "result_responsibility": "",
    "eoi_shortlisted": "No",
    "rfp_consultant": "",
    "rfp_shortlisted": "No",
    "rfp_quote": "",
    "rfp_won": "No",
    "security_refund": "No",
    "security_refund_date_time": '',
    "comments": "",
    "bid_checklist_url": [],
    "bid_document_url": [],
    "bid_proposal_url": []
  })
  const ROLE_LIST = ADDALLOWEDROLESOPPORTUNITY;
  const LoginStore = useSelector(state => state.Login);
  const MY_ROLE = parseInt(LoginStore?.role?.role_code);
  const MY_USERID = parseInt(LoginStore?.user_id);
  let NORMALFIELDS = false;
  const NOMINATED_BOOLEAN = formInput?.nominated_user_id && parseInt(formInput?.nominated_user_id) !== 0 && parseInt(MY_USERID) === parseInt(formInput?.nominated_user_id) ? false : true;
  const [remove, setRemove] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  if (ROLE_LIST.includes(MY_ROLE)) {
    NORMALFIELDS = true
  }
  else {
    NORMALFIELDS = false
  }

  const store = useSelector(state => state.Tender);
  const dispatch = useDispatch()
  let Axios = useAxiosPrivate()
  const [error, setError] = useState({});
  const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']


  useEffect(() => {
    let param = {
      "tender_id": parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1))
    }
    dispatch(GetTenderDetail(Axios, param))
    dispatch(GetStateListTender(Axios))
    dispatch(GetDonorListTender(Axios))
    dispatch(GetNoticeListTender(Axios))
    dispatch(GetModeListTender(Axios))
    dispatch(GetFinancialListTender(Axios))
    dispatch(GetBidCategoryListTender(Axios))
    dispatch(GetBidFunctionListTender(Axios))
    dispatch(GetBidSectorListTender(Axios))
    dispatch(GetUserListTender(Axios))
    dispatch(GetRoleListTender(Axios))

  }, [])

  // var yesterday = new Date(Date.now() - 864e5);
  let TodaysDate = new Date(Date.now());
  console.log(formInput)
  const IsValid = () => {
    let success = true;
    let error = {};
    // if (new Date(formInput.bid_tracking_date)!== new Date()) {
    //   success = false
    //   error.bid_tracking_date = "Please Select Todays Date!!";
    // }

    if (!formInput.bid_title.trim()) {
      success = false
      error.bid_title = "Please enter the Title!!";
    }
    else if (formInput.bid_title?.toString().length > 1000) {
      error.bid_title = '*Only 1000 characters Allowed!';
      success = false
    }

    if (formInput.state_id === 0) {
      success = false
      error.state_id = "Please select a state!!";
    }

    if (!formInput.bid_source.trim()) {
      success = false
      error.bid_source = "Please enter the Bid Source!!";
    }
    else if (formInput.bid_source?.toString().length > 100) {
      error.bid_source = '*Only 100 characters Allowed!';
      success = false
    }

    if (formInput.donor_id === 0) {
      success = false
      error.donor_id = "Please select a Donor!";
    }

    if (formInput.notice_id === 0) {
      success = false
      error.notice_id = "Please select a Notice!!!";
    }


    if (formInput.bid_published_date !== null && formInput.bid_published_date !== undefined && formInput.bid_published_date?.toString()?.length !== 0) {
      if ((new Date(formInput.bid_published_date) > new Date(formInput.bid_tracking_date))) {
        success = false
        error.bid_published_date = "Pre Meeting Date should be before Bid Tracking Date!!";
      }
    }

    if (formInput.pre_bid_meeting_date !== null && formInput.pre_bid_meeting_date !== undefined && formInput.pre_bid_meeting_date?.toString()?.length !== 0) {
      if (formInput.bid_published_date !== null && formInput.bid_published_date !== undefined && formInput.bid_published_date?.toString()?.length !== 0) {
        if ((new Date(formInput.pre_bid_meeting_date) < new Date(formInput.bid_published_date))) {
          success = false
          error.pre_bid_meeting_date = "Pre Meeting Date should be after Bid Published Date!!";
          error.bid_published_date = "Bid Published Date should be before Pre Meeting Date!!";
        }
      }
      else {
        success = false
        error.bid_published_date = "Please Enter Bid Published Date!!";
      }
    }


    if (formInput.pre_bid_meeting_date !== null && formInput.pre_bid_meeting_date !== undefined && formInput.pre_bid_meeting_date?.toString()?.length !== 0) {
      if (formInput.submission_date_time !== null && formInput.submission_date_time !== undefined && formInput.submission_date_time?.toString()?.length !== 0) {

        if ((new Date(formInput.submission_date_time) < new Date(formInput.pre_bid_meeting_date))) {
          success = false
          error.pre_bid_meeting_date = "Meeting Date should be before Submission Date!!";
          error.submission_date_time = "Submission Date should be after Meeting Date!!";
        }
      }
      else {
        success = false
        error.submission_date_time = "Please Enter Submission Date!!";
      }
    }
    if (formInput.submission_date_time !== null && formInput.submission_date_time !== undefined && formInput.submission_date_time?.toString()?.length !== 0) {
      if (formInput.bid_published_date !== null && formInput.bid_published_date !== undefined && formInput.bid_published_date?.toString()?.length !== 0) {
        if ((new Date(formInput.submission_date_time) < new Date(formInput.bid_published_date))) {
          success = false
          error.bid_published_date = "Bid Published Date should be before Submission Date!!";
          error.submission_date_time = "Submission Date should be after Bid Published Date!!";
        }
      }
      else {
        success = false
        error.bid_published_date = "Please Enter Bid Published Date!!";
      }
    }
    if ((formInput.submission_date_time !== null && formInput.submission_date_time !== undefined && formInput.submission_date_time?.length !== 0) ? (new Date(formInput.submission_date_time) < new Date(formInput.bid_tracking_date)) : false) {
      success = false
      error.submission_date_time = "Submission Date Cannot Be before Tracking Date!!";
    }
    if (formInput.submission_mode === 0) {
      success = false
      error.submission_mode_id = "Please select a Submission Mode!!";
    }
    if (formInput.hand_submission.length === 0) {
      success = false
      error.hand_submission = "Please Choose a Hand Submission!!";
    }



    if (formInput.tender_fee < 0) {
      success = false
      error.tender_fee = "Please Enter a valid Tender Fee!!";
    }
    else if (!/^(0|[1-9]\d*)$/.test(formInput.tender_fee)) {
      success = false
      error.tender_fee = "Enter a valid Number!!";
    }
    else if (formInput.tender_fee?.toString().length > 30) {
      error.tender_fee = '*Only 30 characters Allowed!';
      success = false
    }
    else if (Number.isInteger(parseInt(formInput.tender_fee)) === false) {
      success = false
      error.tender_fee = "Decimal not allowed!!";
    }

    if (formInput.security_fee < 0) {
      success = false
      error.security_fee = "Please Enter a valid Security Fee!!";
    }
    else if (!/^(0|[1-9]\d*)$/.test(formInput.security_fee)) {
      success = false
      error.security_fee = "Enter a valid Number!!";
    }
    else if (formInput.security_fee?.toString().length > 30) {
      error.security_fee = '*Only 30 characters Allowed!';
      success = false
    }
    else if (Number.isInteger(parseInt(formInput.security_fee)) === false) {
      success = false
      error.security_fee = "Decimal not allowed!!";
    }

    if (formInput.bc_id === 0) {
      success = false
      error.bc_id = "Please Choose a Bid Category!!";
    }
    if (formInput.bs_id === 0) {
      success = false
      error.bs_id = "Please Choose a Bid Sector!!";
    }
    if (formInput.bf_id === 0) {
      success = false
      error.bf_id = "Please Choose a Bid Function!!";
    }

    if (NORMALFIELDS) {
      if (formInput.selected_for_bidding?.length === 0) {
        success = false
        error.selected_for_bidding = "Please Choose a Bidding!!";
      }
    }

    if (formInput.bid_submitted?.length === 0) {
      success = false
      error.bid_submitted = "Please select a Bid Submitted Date!!";
    }

    if (formInput.bid_document_url?.length === 0) {
      success = false;
      toast.error("Please upload a document!!!")
    }

    if (!NOMINATED_BOOLEAN) {
      // if (!((formInput.submission_date_time !== null || formInput.submission_date_time !== undefined) && (formInput.actual_submission_date !== null || formInput.actual_submission_date !== undefined) ? (new Date(formInput.actual_submission_date) <= new Date(formInput.submission_date_time)) : false)) {
      //   success = false
      //   error.actual_submission_date = "Actual Submission Date cannot exceed Submission Date !!";
      // }

      // if (!formInput.result_responsibility.trim()) {
      //   success = false
      //   error.result_responsibility = "Please Enter a Result Responsibility!!";
      // }
      if (formInput.eoi_shortlisted?.length === 0) {
        success = false
        error.eoi_shortlisted = "Please Choose EOI ShortListed!!";
      }

      if (formInput.rfp_shortlisted?.length === 0) {
        success = false
        error.rfp_shortlisted = "Please Choose RFP ShortListed!!";
      }
      if (formInput.rfp_won?.length === 0) {
        success = false
        error.rfp_won = "Please Choose RFP ShortListed!!";
      }
      if (formInput.security_refund?.length === 0) {
        success = false
        error.security_refund = "Please Enter a Security Refund!!";
      }

      // if (((formInput.security_refund_date_time === null || formInput.security_refund_date_time === undefined) && (formInput.submission_date_time === null || formInput.submission_date_time === undefined)) ? false : (new Date(formInput.security_refund_date_time) < new Date(formInput.submission_date_time))) {
      //   success = false
      //   error.actual_submission_date = "Actual Submission Date cannot exceed Submission Date !!";
      // }

      if (formInput.submission_date_time?.toString()?.length !== 0 && formInput.submission_date_time !== null && formInput.submission_date_time !== undefined) {
        if (formInput.actual_submission_date?.toString()?.length !== 0 && formInput.actual_submission_date !== null && formInput.actual_submission_date !== undefined) {
          if (new Date(formInput.actual_submission_date) > new Date(formInput.submission_date_time)) {
            success = false
            error.actual_submission_date = "Actual Submission Date cannot exceed Submission Date !!";
          }
        }
      }
      if (formInput.security_refund_date_time?.toString()?.length !== 0 && formInput.security_refund_date_time !== null && formInput.security_refund_date_time !== undefined) {
        if (formInput.submission_date_time?.toString()?.length !== 0 && formInput.submission_date_time !== null && formInput.submission_date_time !== undefined) {
          if (new Date(formInput.security_refund_date_time) <= new Date(formInput.submission_date_time)) {
            success = false
            error.security_refund_date_time = "Select date after Submission date!!";
          }
        }
        else {
          success = false
          error.submission_date_time = "Please select a Submission Date!!";
        }

      }

      // if (!formInput.comments?.trim()) {
      //   success = false
      //   error.comments = "Please Enter Comments!!";
      // }
      // if (formInput.nominated_user_id === 0) {
      //   success = false
      //   error.nominated_user_id = "Please Choose a Final Submission Responsibility!!";
      // }


    }

    setError(error)
    return success
  }

  let handleSubmitForm = async (e) => {
    e.preventDefault()
    // console.log(IsValid())
    if (IsValid()) {
      //console.log("entered")
      let param = {
        "nominated_user_id": formInput.nominated_user_id,
        "tender_id": formInput?.tender_id,
        "bid_tracking_date": formInput?.bid_tracking_date,

        "bid_published_date": formInput.bid_published_date !== '' && formInput.bid_published_date?.toString().length !== 0 ? formInput.bid_published_date : null,
        "bid_title": formInput?.bid_title?.replace(/\s+/g, ' ').trim(),
        "state_id": formInput?.state_id,
        "bid_source": formInput?.bid_source?.replace(/\s+/g, ' ').trim(),
        "donor_id": formInput?.donor_id,
        "notice_id": formInput?.notice_id,

        "pre_bid_meeting_date": formInput.pre_bid_meeting_date !== '' && formInput.pre_bid_meeting_date?.toString().length !== 0 ? formInput.pre_bid_meeting_date : null,
        "submission_date_time": formInput.submission_date_time !== '' && formInput.submission_date_time?.toString().length !== 0 ? formInput.submission_date_time : null,
        "submission_mode": formInput?.submission_mode,
        "hand_submission": formInput?.hand_submission,
        "tender_fee": formInput?.tender_fee,
        "security_fee": formInput?.security_fee,
        "bid_category": formInput?.bid_category,
        "bid_sector": formInput?.bid_sector,
        "bid_function": formInput?.bid_function,
        "selected_for_bidding": formInput?.selected_for_bidding,
        "bid_advice": formInput?.bid_advice?.replace(/\s+/g, ' ').trim(),
        "bid_task_deadline": formInput?.bid_task_deadline?.replace(/\s+/g, ' ').trim(),
        "bid_review_deadline": formInput?.bid_review_deadline?.replace(/\s+/g, ' ').trim(),
        "consortium_partners": formInput?.consortium_partners,
        "bid_submitted": formInput?.bid_submitted,
        "actual_submission_date": formInput.actual_submission_date !== '' && formInput.actual_submission_date?.toString().length !== 0 ? formInput.actual_submission_date : null,
        "result_responsibility": formInput?.result_responsibility,
        "eoi_shortlisted": formInput?.eoi_shortlisted,
        "rfp_consultant": formInput?.rfp_consultant?.replace(/\s+/g, ' ').trim(),
        "rfp_shortlisted": formInput?.rfp_shortlisted,
        "rfp_quote": formInput?.rfp_quote?.replace(/\s+/g, ' ').trim(),
        "rfp_won": formInput?.rfp_won,
        "security_refund": formInput?.security_refund,
        "security_refund_date_time": formInput.security_refund_date_time !== '' && formInput.security_refund_date_time?.toString().length !== 0 ? formInput.security_refund_date_time : null,
        "comments": formInput?.comments?.replace(/\s+/g, ' ').trim(),
        "checklistDocs": formInput?.bid_checklist_url,
        "documentDocs": formInput?.bid_document_url,
        "proposalDocs": formInput?.bid_proposal_url
      }


      const data = await dispatch(UpdateTenderDetail(Axios, param))

      if (data?.code === 200) {
        setTimeout(() => {
          navigate(-1)
        }, [2000])

        toast.success('Updated Tender SuccessFully');
      }
      else {
        toast.error(`${data?.message}`)
      }
    }
  }

  let handleChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    })
  }
  let handleSelectChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    })
  }

  let handleBidTrackingDate = (e) => {
    setFormInput({
      ...formInput,
      bid_tracking_date: e
    })
  }

  let handleBidPublishedDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        bid_published_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        bid_published_date: e
      })
    }
  }
  let handlePreBidMeetingDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        pre_bid_meeting_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        pre_bid_meeting_date: e
      })
    }
  }
  let handleSubmissionDateTimeDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        submission_date_time: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        submission_date_time: e
      })
    }
  }
  let handleBidSubmittedDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        bid_submitted: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        bid_submitted: e
      })
    }
  }

  let handleActualSubmissionDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        actual_submission_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        actual_submission_date: e
      })
    }
  }
  let handleSecurityRefundDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        security_refund_date_time: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        security_refund_date_time: e
      })
    }
  }



  const handleFileSelect = async (event) => {
    // setFile(event.target.files[0]);
    // console.log(event.target.files[0],event.target.name)
    const Type = event.target.name;
    console.log(Type)
    const KEY = Type === "checklist" ? "bid_checklist_url" : Type === "documents" ? "bid_document_url" : "bid_proposal_url";

    //console.log(Type, KEY)
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('type', event.target.name);
    formData.append('tender_id', formInput?.tender_id);
    const Result = await FileUploadTenderUpdate(formData);

    console.log(Result, "eew")

    if (Result?.code === 200) {
      let Obj = Result?.document_data ? Result?.document_data[0] : {}
      setFormInput({
        ...formInput,
        [KEY]: [...formInput[KEY], Obj]
      })
      toast.success('File Uploaded SuccessFully');
    }
    else {
      toast.error('File Upload Failed')
    }
  }

  const handleClearDocument = async (type, item, index) => {
    setRemove(true);
    setDeleteData({
      "tender_id": item?.tender_id,
      "td_id": item?.td_id,
      "type": type,
      "filename": item?.file_path,
      item: item
    })

  }

  const handleConfirmDeleteDocument = async () => {
    let param = {
      "tender_id": deleteData?.tender_id,
      "td_id": deleteData?.td_id,
      "type": deleteData?.type,
      "filename": deleteData?.filename
    }

    const data = await dispatch(DocumentDeleteTender(Axios, param));
    if (deleteData?.type === "documents") {
      if (NORMALFIELDS) {
        if (data?.code === 200) {
          setRemove(false)
          setFormInput({
            ...formInput,
            bid_checklist_url: data?.checklist,
            bid_document_url: data?.documentList,
            bid_proposal_url: data?.proposaList
          })

          toast.success("Deleted Document SuccessFully");
        }
        else {
          toast.error(data?.message)
        }
      }
      else {
        toast.error("Access not allowed")
      }

    }
    else {
      if (!NOMINATED_BOOLEAN) {
        if (data?.code === 200) {
          setRemove(false)
          setFormInput({
            ...formInput,
            bid_checklist_url: data?.checklist,
            bid_document_url: data?.documentList,
            bid_proposal_url: data?.proposaList
          })

          toast.success("Deleted Document SuccessFully");
        }
        else {
          toast.error(data?.message)
        }
      }
      else {
        toast.error("Access not allowed")
      }
    }



  }

  useEffect(() => {
    setFormInput(store?.tenderDetail ? store.tenderDetail :
      {
        "added_by": "",
        "bid_tracking_date": new Date(),
        "bid_published_date": '',
        "bid_title": "",
        "state_id": 0,
        "bid_source": "",
        "donor_id": 0,
        "notice_id": 0,
        "pre_bid_meeting_date": '',
        "submission_date_time": '',
        "submission_mode": 0,
        "hand_submission": "No",
        "tender_fee": 0,
        "security_fee": 0,
        "bid_category": 0,
        "bid_sector": 0,
        "bid_function": 0,
        "selected_for_bidding": "No",
        "bid_advice": "",
        "bid_task_deadline": "",
        "bid_review_deadline": "",
        "nominated_user_id": 0,
        "consortium_partners": "",
        "bid_submitted": "No",
        "actual_submission_date": '',
        "result_responsibility": "",
        "eoi_shortlisted": "No",
        "rfp_consultant": "",
        "rfp_shortlisted": "No",
        "rfp_quote": "",
        "rfp_won": "No",
        "security_refund": "No",
        "security_refund_date_time": '',
        "comments": "",
        "bid_checklist_url": [],
        "bid_document_url": [],
        "bid_proposal_url": []
      })

    // let param = {
    //   "tender_id": store?.tenderDetail?.tender_id && parseInt(store?.tenderDetail?.tender_id) > 0 ? (parseInt(store?.tenderDetail?.tender_id)) : (0)
    // }
    // dispatch(GetDocumentsForTenderById(Axios, param))

  }, [store.tenderDetail])


  return (
    <>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={styles.rootContainer}>

        <div>
          <h2 className={styles.OppBidTitle}>Opportunity & Bidding Form</h2>
        </div>


        <form autoComplete='off'>
          <div className={styles.OppBidBody}>
            <div className={styles.OppBidItem}>
              <label>Bid Tracking Date</label>
              <div className={styles.TenderInputWrapper}>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker

                    inputFormat="dd/MM/yyyy"
                    name="bid_tracking_date" value={formInput.bid_tracking_date}
                    disabled={!NORMALFIELDS}
                    renderInput={(params) => <TextField size="small"  {...params} error={false} sx={{ minWidth: '150px', width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className={styles.OppBidItem}>
              <label>Bid Published Date</label>
              <div className={styles.TenderInputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    disabled={!NORMALFIELDS}
                    name="bid_published_date" value={formInput.bid_published_date}
                    onChange={handleBidPublishedDate}

                    renderInput={(params) => <TextField size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />}
                  />
                </LocalizationProvider>
                {error.bid_published_date && <label className={styles.emptyMessage}>{error.bid_published_date}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid Title*</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small'
                  disabled={!NORMALFIELDS}
                  className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} type='text' onChange={handleChange} name="bid_title" value={formInput.bid_title} />
                {error.bid_title && <label className={styles.emptyMessage}>{error.bid_title}</label>}
              </div>
            </div>



            <div className={styles.OppBidItem}>
              <label>State / UT*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.state_id}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="state_id"
                  >
                    {
                      store?.tenderStateList?.map((item, index) => (
                        <MenuItem value={item.state_id}>{item.state_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.state_id && <label className={styles.emptyMessage}>{error.state_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid Source*</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small'
                  disabled={!NORMALFIELDS}
                  className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} type='text' onChange={handleChange} name="bid_source" value={formInput.bid_source} />
                {error.bid_source && <label className={styles.emptyMessage}>{error.bid_source}</label>}
              </div>
            </div>


            <div className={styles.OppBidItem}>
              <label>Donor*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    value={formInput.donor_id}
                    disabled={!NORMALFIELDS}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="donor_id"
                  >
                    {
                      store?.tenderDonorList?.map((item, index) => (
                        <MenuItem value={item.donor_id}>{item.donor_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.donor_id && <label className={styles.emptyMessage}>{error.donor_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>EOI/RFP*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.notice_id}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="notice_id"
                  >
                    {
                      store?.tenderNoticeList?.map((item, index) => (
                        <MenuItem value={item.notice_id}>{item.notice_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.notice_id && <label className={styles.emptyMessage}>{error.notice_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Pre BID Meeting Date</label>
              <div className={styles.TenderInputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                  <DatePicker
                    disabled={!NORMALFIELDS}
                    inputFormat="dd/MM/yyyy"
                    name="pre_bid_meeting_date" value={formInput.pre_bid_meeting_date}
                    onChange={handlePreBidMeetingDate}
                    renderInput={(params) => <TextField size="small" {...params} error={false} sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                  />
                </LocalizationProvider>
                {error.pre_bid_meeting_date && <label className={styles.emptyMessage}>{error.pre_bid_meeting_date}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Submission Date</label>
              {/* <input type='date' onChange={handleDateChange} name="pre_bid_meeting_date" value={formInput.pre_bid_meeting_date} /> */}
              <div className={styles.TenderInputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                  <DatePicker
                    disabled={!NORMALFIELDS}
                    inputFormat="dd/MM/yyyy"
                    name="submission_date_time" value={formInput.submission_date_time}
                    onChange={handleSubmissionDateTimeDate}
                    renderInput={(params) => <TextField size="small" {...params} error={false} sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                  />
                </LocalizationProvider>
                {error.submission_date_time && <label className={styles.emptyMessage}>{error.submission_date_time}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Submission Mode*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    value={formInput.submission_mode}
                    className={styles.Select}
                    displayEmpty
                    disabled={!NORMALFIELDS}
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="submission_mode"
                  >
                    {
                      store?.tenderModeList?.map((item, index) => (
                        <MenuItem value={item.mode_id}>{item.mode_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.submission_mode_id && <label className={styles.emptyMessage}>{error.submission_mode_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Hand Submission</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.hand_submission}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="hand_submission"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.hand_submission && <label className={styles.emptyMessage}>{error.hand_submission}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid/Tender Fee*</label>
              <div className={styles.TenderInputWrapper}>
                <TextField disabled={!NORMALFIELDS} size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} name="tender_fee" onChange={handleChange} value={formInput.tender_fee} />
                {error.tender_fee && <label className={styles.emptyMessage}>{error.tender_fee}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid Security/EMD*</label>
              <div className={styles.TenderInputWrapper}>
                <TextField disabled={!NORMALFIELDS} size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} name="security_fee" onChange={handleChange} value={formInput.security_fee} />
                {error.security_fee && <label className={styles.emptyMessage}>{error.security_fee}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid Category*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.bid_category}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="bid_category"
                  >
                    {
                      store?.tenderBidCategoryList?.map((item, index) => (
                        <MenuItem value={item.bc_id}>{item.bc_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.bc_id && <label className={styles.emptyMessage}>{error.bc_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Bid Sector*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.bid_sector}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="bid_sector"
                  >
                    {
                      store?.tenderBidSectorList?.map((item, index) => (
                        <MenuItem value={item.bs_id}>{item.bs_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.bs_id && <label className={styles.emptyMessage}>{error.bs_id}</label>}
              </div>
            </div>


            <div className={styles.OppBidItem}>
              <label>Bid Function*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl
                  sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.bid_function}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="bid_function"
                  >
                    {
                      store?.tenderBidFunctionList?.map((item, index) => (
                        <MenuItem value={item.bf_id}>{item.bf_name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.bf_id && <label className={styles.emptyMessage}>{error.bf_id}</label>}
              </div>
            </div>

            <div className={styles.OppBidItem}>
              <label>Selected for Bidding</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select

                    value={formInput.selected_for_bidding}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="selected_for_bidding"
                    disabled={!NORMALFIELDS}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.selected_for_bidding && <label className={styles.emptyMessage}>{error.selected_for_bidding}</label>}
              </div>
            </div>


            <div className={styles.OppBidItem}>
              <label>Bid Advice</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} type='text' onChange={handleChange} name="bid_advice" value={formInput.bid_advice} disabled={!NORMALFIELDS} />
                {error.bid_advice && <label className={styles.emptyMessage}>{error.bid_advice}</label>}
              </div>
            </div>


            <div className={styles.OppBidItem}>
              <label>Bid Task Allocation with Deadline</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} type='text' disabled={!NORMALFIELDS} name="bid_task_deadline" onChange={handleChange} value={formInput.bid_task_deadline} />
                {error.bid_task_deadline && <label className={styles.emptyMessage}>{error.bid_task_deadline}</label>}
              </div></div>

            <div className={styles.OppBidItem}>
              <label>Bid Review Allocation with Deadline</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} type='text' disabled={!NORMALFIELDS} name="bid_review_deadline" onChange={handleChange} value={formInput.bid_review_deadline} />
                {error.bid_review_deadline && <label className={styles.emptyMessage}>{error.bid_review_deadline}</label>}
              </div></div>


            <div className={styles.OppBidItem}>
              <label>Final Submission Responsibility</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={!NORMALFIELDS}
                    value={formInput.nominated_user_id}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="nominated_user_id"
                  >
                    {
                      store?.tenderUserList?.map((item, index) => (
                        <MenuItem value={item.user_id}>{item.full_name + ` (${item.user_code})`}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {error.nominated_user_id && <label className={styles.emptyMessage}>{error.nominated_user_id}</label>}
              </div>
            </div>


            <div className={styles.OppBidItem}>
              <label>Consortium Partners</label>
              <div className={styles.TenderInputWrapper}>
                <TextField size='small' className={styles.InputField} disabled={NOMINATED_BOOLEAN} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} name="consortium_partners" onChange={handleChange} value={formInput.consortium_partners} />
                {error.consortium_partners && <label className={styles.emptyMessage}>{error.consortium_partners}</label>}
              </div></div>
            <div className={styles.OppBidItem}>
              <label>Bid Submitted</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    value={formInput.bid_submitted}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="bid_submitted"
                    disabled={NOMINATED_BOOLEAN}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.bid_submitted && <label className={styles.emptyMessage}>{error.bid_submitted}</label>}
              </div></div>
            <div className={styles.OppBidItem}>
              <label>Actual Submission Date</label>
              <div className={styles.TenderInputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={NOMINATED_BOOLEAN}
                    name="actual_submission_date" value={formInput.actual_submission_date}
                    onChange={handleActualSubmissionDate}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField size="small" {...params} error={false} sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />}
                  />
                </LocalizationProvider>
                {error.actual_submission_date && <label className={styles.emptyMessage}>{error.actual_submission_date}</label>}
              </div></div>
            {/* <div className={styles.OppBidItem}>
            <label>Results Responsibility</label>
            <div className={styles.TenderInputWrapper}>
            <TextField size='small' className={styles.InputField} sx={{'& legend': { display: 'none' }, '& fieldset': { top: 0 }}} name="result_responsibility" onChange={handleChange} value={formInput.result_responsibility} />
            {error.result_responsibility && <label className={styles.emptyMessage}>{error.result_responsibility}</label>}
          </div></div> */}

            <div className={styles.OppBidItem}>
              <label>EOI Shortlisted*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    value={formInput.eoi_shortlisted}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="eoi_shortlisted"
                    disabled={NOMINATED_BOOLEAN}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.eoi_shortlisted && <label className={styles.emptyMessage}>{error.eoi_shortlisted}</label>}
              </div></div>


            <div className={styles.OppBidItem}>
              <label>Consultants shortlisted for RFP</label>
              <div className={styles.TenderInputWrapper}>
                <TextField disabled={NOMINATED_BOOLEAN} size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} name="rfp_consultant" onChange={handleChange} value={formInput.rfp_consultant} />
                {error.rfp_consultant && <label className={styles.emptyMessage}>{error.rfp_consultant}</label>}
              </div></div>

            <div className={styles.OppBidItem}>
              <label>RFP Shortlisted*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={NOMINATED_BOOLEAN}
                    value={formInput.rfp_shortlisted}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="rfp_shortlisted"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.rfp_shortlisted && <label className={styles.emptyMessage}>{error.rfp_shortlisted}</label>}
              </div></div>
            <div className={styles.OppBidItem}>
              <label>Technical and Financial Quote of RFP Shortlisted Consultants</label>
              <div className={styles.TenderInputWrapper}>
                <TextField disabled={NOMINATED_BOOLEAN} size='small' className={styles.InputField} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} name="rfp_quote" onChange={handleChange} value={formInput.rfp_quote} />
                {error.rfp_quote && <label className={styles.emptyMessage}>{error.rfp_quote}</label>}
              </div></div>
            <div className={styles.OppBidItem}>
              <label>RFP Won*</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={NOMINATED_BOOLEAN}
                    value={formInput.rfp_won}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="rfp_won"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.rfp_won && <label className={styles.emptyMessage}>{error.rfp_won}</label>}
              </div></div>
            <div className={styles.OppBidItem}>
              <label> EMD / Bid Security Refund</label>
              <div className={styles.TenderInputWrapper}>
                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" >
                  <Select
                    disabled={NOMINATED_BOOLEAN}
                    value={formInput.security_refund}
                    className={styles.Select}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleSelectChange}
                    name="security_refund"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
                {error.security_refund && <label className={styles.emptyMessage}>{error.security_refund}</label>}
              </div></div>

            <div className={styles.OppBidItem}>
              <label>EMD Refund Date</label>
              <div className={styles.TenderInputWrapper}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={NOMINATED_BOOLEAN}
                    name="security_refund_date_time" value={formInput.security_refund_date_time}
                    onChange={handleSecurityRefundDate}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField size="small" {...params} error={false} sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />}
                  />
                </LocalizationProvider>
                {error.security_refund_date_time && <label className={styles.emptyMessage}>{error.security_refund_date_time}</label>}
              </div></div>

          </div>
        </form>




        <div className={styles.Seperator}></div>


        <div className={styles.OppBidCheckListWrapper}>
          {
            parseInt(LoginStore?.user_id) === parseInt(formInput.nominated_user_id) ?

              <div className={styles.OppBidCheckList}>
                <div className={styles.OppBidCheckListTitleWrapper}>
                  <h2 className={styles.OppBidCheckListTitle}>Bid Document*</h2>
                </div>
                <div className={styles.OppBidCheckListUploaded}>
                  <div className={styles.OppBidCkLstUpldBtn}>
                    <label><UploadOutlinedIcon />Upload</label>
                    <input type="file" id="uploadChckLst" name="documents" />
                  </div>
                  <div className={styles.OppBidCkLstUpldDocsWrapper}>
                    {
                      formInput?.bid_document_url?.map((item, index) => (
                        <div className={styles.OppBidCkLstUpldDocs} key={index}>
                          <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                            "_blank" : null
                          }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                          <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' />
                        </div>
                      ))
                    }

                  </div>
                </div>
              </div>
              :
              (NORMALFIELDS ? <div className={styles.OppBidCheckList}>
                <div className={styles.OppBidCheckListTitleWrapper}>
                  <h2 className={styles.OppBidCheckListTitle}>Bid Document*</h2>
                </div>
                <div className={styles.OppBidCheckListUploaded}>
                  <div className={styles.OppBidCkLstUpldBtn}>
                    <label htmlFor="uploadChckLst"><UploadOutlinedIcon />Upload</label>
                    <input type="file" id="uploadChckLst" name="documents" onChange={handleFileSelect} />
                  </div>
                  <div className={styles.OppBidCkLstUpldDocsWrapper}>
                    {
                      formInput?.bid_document_url?.map((item, index) => (
                        <div className={styles.OppBidCkLstUpldDocs} key={index} >
                          <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                            "_blank" : null
                          }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                          <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("documents", item, index)} />
                        </div>
                      ))
                    }

                  </div>
                </div>
              </div> :
                <div className={styles.OppBidCheckList}>
                  <div className={styles.OppBidCheckListTitleWrapper}>
                    <h2 className={styles.OppBidCheckListTitle}>Bid Document*</h2>
                  </div>
                  <div className={styles.OppBidCheckListUploaded}>
                    <div className={styles.OppBidCkLstUpldBtn}>
                      <label><UploadOutlinedIcon />Upload</label>
                      <input type="file" id="uploadChckLst" name="documents" />
                    </div>
                    <div className={styles.OppBidCkLstUpldDocsWrapper}>
                      {
                        formInput?.bid_document_url?.map((item, index) => (
                          <div className={styles.OppBidCkLstUpldDocs} key={index}>
                            <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                              "_blank" : null
                            }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                            <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' />
                          </div>
                        ))
                      }

                    </div>
                  </div>
                </div>)

          }

          {
            parseInt(LoginStore?.user_id) === parseInt(formInput.nominated_user_id) ?
              <>
                <div className={styles.OppBidCheckList}>
                  <div className={styles.OppBidCheckListTitleWrapper}>
                    <h2 className={styles.OppBidCheckListTitle}>Bid CheckList</h2>
                  </div>
                  <div className={styles.OppBidCheckListUploaded}>
                    <div className={styles.OppBidCkLstUpldBtn}>
                      <label htmlFor="uploadChckLst1"><UploadOutlinedIcon />Upload</label>
                      <input type="file" id="uploadChckLst1" name="checklist" onChange={handleFileSelect} />
                    </div>
                    <div className={styles.OppBidCkLstUpldDocsWrapper}>
                      {
                        formInput?.bid_checklist_url?.map((item, index) => (
                          <div className={styles.OppBidCkLstUpldDocs} key={index}>
                            <a href={item.file_path} target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                              "_blank" : null
                            }
                              download="bid_document">{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                            <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("checklist", item, index)} />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.OppBidCheckList}>
                  <div className={styles.OppBidCheckListTitleWrapper}>
                    <h2 className={styles.OppBidCheckListTitle}>Updated Final Eol/Proposal</h2>
                  </div>
                  <div className={styles.OppBidCheckListUploaded}>
                    <div className={styles.OppBidCkLstUpldBtn}>
                      <label htmlFor="uploadChckLst2"><UploadOutlinedIcon />Upload</label>
                      <input type="file" id="uploadChckLst2" name="proposal" onChange={handleFileSelect} />
                    </div>
                    <div className={styles.OppBidCkLstUpldDocsWrapper}>
                      {
                        formInput?.bid_proposal_url?.map((item, index) => (
                          <div className={styles.OppBidCkLstUpldDocs} key={index}>
                            <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                              "_blank" : null
                            } >{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                            <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("proposal", item, index)} />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className={styles.OppBidCheckList}>
                  <div className={styles.OppBidCheckListTitleWrapper}>
                    <h2 className={styles.OppBidCheckListTitle}>Bid CheckList</h2>
                  </div>
                  <div className={styles.OppBidCheckListUploaded}>
                    <div className={styles.OppBidCkLstUpldBtnDisabled}>
                      <label ><UploadOutlinedIcon />Upload</label>
                      {/* <input type="file" id="uploadChckLst" name="checklist" onChange={handleFileSelect}/> */}
                    </div>
                    <div className={styles.OppBidCkLstUpldDocsWrapper}>
                      {
                        formInput?.bid_checklist_url?.map((item, index) => (
                          <div className={styles.OppBidCkLstUpldDocs} key={index}>
                            <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                              "_blank" : null
                            } >{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                            <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='#8e8e8e' />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.OppBidCheckList}>
                  <div className={styles.OppBidCheckListTitleWrapper}>
                    <h2 className={styles.OppBidCheckListTitle}>Updated Final Eol/Proposal</h2>
                  </div>
                  <div className={styles.OppBidCheckListUploaded}>
                    <div className={styles.OppBidCkLstUpldBtnDisabled}>
                      <label ><UploadOutlinedIcon />Upload</label>
                      {/* <input type="file" id="uploadChckLst" name="proposal" disabled={true} /> */}
                    </div>
                    <div className={styles.OppBidCkLstUpldDocsWrapper}>
                      {
                        formInput?.bid_proposal_url?.map((item, index) => (
                          <div className={styles.OppBidCkLstUpldDocs} key={index}>
                            <a href={item.file_path} download="bid_document" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                              "_blank" : null
                            }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                            <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='#8e8e8e' />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </>
          }

        </div>
        <div className={styles.OppBidNotesWrapper}>
          <div className={styles.OppBidNotesLabel}><label>Notes</label></div>
          <input disabled={NOMINATED_BOOLEAN} type="textarea" name='comments' className={styles.OppBidNotes} placeholder={formInput.comments} onChange={handleChange} value={formInput.comments} autoComplete='off' />
        </div>

        <div className={styles.submitButtonWrapper}>
          <SubmitButton onClick={handleSubmitForm}>Submit</SubmitButton>
        </div>

      </div>




      <DeleteTenderPromptDocument remove={remove} setRemove={setRemove} file={deleteData} ToastShow={handleConfirmDeleteDocument} />
    </>

  )
}

export default UpdateTender