// export const BASE_URL = "http://15.206.185.75:2000/admin";
// // export const BASE_LOGIN_URL = "http://15.206.185.75:2000/login";
// export const BASE_LOGIN_URL = "http://15.206.185.75:2000/admin";
// export const BASE_FILE_URL = "http://15.206.185.75:2000/upload_file";
// export const BASE_PATH = '/home/ubuntu/sutra_api_dev/public/'

// export const BASE_URL = "http://localhost:2000/admin";
// // export const BASE_LOGIN_URL = "http://localhost:2000/login"; 
// export const BASE_LOGIN_URL = "http://localhost:2000/admin";
// export const BASE_FILE_URL = "http://localhost:2000/upload_file";
// export const BASE_PATH = 'D:/echno/mis server/public/'

// export const BASE_URL = "http://192.168.1.28:2000/admin";
// export const BASE_FILE_URL = "http://192.168.1.28:2000/upload_file";
// export const BASE_LOGIN_URL = "http://192.168.1.28:2000/admin";


export const BASE_URL = "https://sutramis.com:2003/admin";
// export const BASE_LOGIN_URL = "http://sutramis.com:2000/login";
export const BASE_LOGIN_URL = "https://sutramis.com:2003/admin";
export const BASE_FILE_URL = "https://sutramis.com:2003/upload_file";
export const BASE_PATH = '/home/ubuntu/sutra_api_prod/public/'
