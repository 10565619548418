import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from './styles/ctrlFldsBidCat.module.css'

const UpdateBidCategory = ({item,handleList}) => {  
    const [bidCategory,setBidCategory]=useState(item);


    const handleChange=(e)=>{
        setBidCategory({
            ...bidCategory,
            bc_name:e.target.value})
        handleList(bidCategory,e.target.value)
    }

    return (
        <div>
 
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} autoComplete="off" size="small" type="text" name="bc_name" value={bidCategory.bc_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateBidCategory