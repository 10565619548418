import React,{useState} from 'react'
import styles from "./styles/ctrlFldsMode.module.css";
import { useDispatch } from 'react-redux'
import { AddModeApi } from '../../../actions/controlFields/controlFieldsActions';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddLoadingButton } from '../../../utility/button/button';

const AddMode = () => {
    const Axios = useAxiosPrivate();
    const dispatch = useDispatch()
    const [modeName, setModeName] = useState('');
    const [error, setError] = useState({})
    const [loading,setLoading] = useState(false)


    const IsValid = ()=>{
        let success = true;
        let error = {};
        if (!modeName.trim()) {
            error.mode = "Please type a Mode!!!!";
            success = false
        }
        else if(modeName?.toString().length>50){
            error.mode = '*Only 50 characters Allowed!';
            success = false
        }
        setError(error)
        return success
    }
    const handleAddMode = async (e) => {
        e.preventDefault();

        if (IsValid()) {
            setLoading(true)
            let param = {
                "mode_name": modeName.replace(/\s+/g, ' ').trim()
            }
            const data = await dispatch(AddModeApi(Axios, param))


            if (data?.code === 201) {
            setLoading(false)
                setModeName('');
                toast.success('Added Mode SuccessFully')
            }
            else {
            setLoading(false)
                toast.error(`${data?.message}`)
            }
        }
    }

  return (
    <>
    <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
    <form onSubmit={handleAddMode}>

        <div className={styles.CtrlFldsBodyAdd}>
            <div className={styles.CtrlFldsInputWrapper}>
                <input onChange={(e) => setModeName(e.target.value)} className={styles.CtrlFldsBodyAddInput} value={modeName} autoComplete="off" type="text" placeholder='Add Item' />
                {error.mode && <label className={styles.emptyMessage}>{error.mode}</label>}
            </div>

            <div>
            <AddLoadingButton type="submit" size="small"  loading={loading} className={styles.SubmitButton}>Add</AddLoadingButton>
            </div>
        </div>
    </form>

</>
  )
}

export default AddMode