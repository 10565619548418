import { GETCONSULTANTDOWNLOADLIST, GETCONTRACTORDOWNLOADLIST, GETPROJECTDOWNLOADLIST, GETTENDERDOWNLOADLIST } from "../reduxConstants/DowloadReducerConstant"

const initialState = {
   tenderdownloadList:[],
   projectDownloadList:[],
   contractorDownloadList:[],
   consultantDownloadList:[],
}
export default (state = initialState, actions) => {
    switch (actions.type) {
        case GETTENDERDOWNLOADLIST:
            return {
                ...state,
                tenderdownloadList: actions.payload
            }
        case GETPROJECTDOWNLOADLIST:
            return {
                ...state,
                projectDownloadList: actions.payload
            }
        case GETCONTRACTORDOWNLOADLIST:
            return {
                ...state,
                contractorDownloadList: actions.payload
            }
        case GETCONSULTANTDOWNLOADLIST:
            return {
                ...state,
                consultantDownloadList: actions.payload
            }
        default: return state
    }
}