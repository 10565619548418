    import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/contractManagementForm.module.css";
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddInvoiceList } from '../../actions/contractor/ContrractorActions';


function MyVerticallyCenteredModal(props) {
   let {  contractId, projectCode ,contractStartDate} = props
    let Axios = useAxiosPrivate()
    const dispatch = useDispatch()

   

        
    const [invoiceData, setInvoiceData] = useState({
        "invoice_amount": 0,
        "invoice_date":  null
    })

    const { toggleSet } = props
    const [error, setError] = useState({})

    const handleChange = (e) => {
        setInvoiceData({
            ...invoiceData,
            [e.target.name]: e.target.value
        })
    }
    let handleInvoiceDateChange = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setInvoiceData({
                ...invoiceData,
                invoice_date: ''
            })
        }
        else {
            setInvoiceData({
                ...invoiceData,
                invoice_date: e
            })
        }
      }
   
    const isValid = () => {
        let success = true
        let error = {};
        if (invoiceData?.invoice_amount <= 0) {
            success = false
            error.invoice_amount = "Please Enter a valid Invoice Amount!";
        }
        else if (invoiceData?.invoice_amount?.toString()?.length > 30) {
            success = false
            error.invoice_amount = "*Only 30 characters Allowed!";
        }
        else if (!/^[0-9]\d*$/.test(invoiceData?.invoice_amount)) {
            success = false
            error.invoice_amount = "Please Enter a valid Number!!";
          }
        else if (Number.isInteger(parseFloat(invoiceData?.invoice_amount)) === false) {
            success = false
            error.invoice_amount = "Decimal not allowed!!";
        }
        
        if(contractStartDate !== null && contractStartDate !== undefined && contractStartDate?.length !== 0){
            if(invoiceData?.invoice_date !== null && invoiceData?.invoice_date !== undefined && invoiceData?.invoice_date?.length !== 0){
                   if (new Date(contractStartDate) > new Date(invoiceData?.invoice_date)) {
            success = false
            error.invoice_date = "Cannot be before Contract Start Date!!";
          }
            }
           
        }
        else{
            success = false
            error.invoice_date = "Please Enter Contract Start Date!!";
        }
        

        setError(error)
        return success
    }
    const handleInvoiceSubmit = async(e) => {
        
        e.preventDefault()
        if(isValid()){
             let param = {
            "contract_id":contractId,
            "project_code":projectCode,
            "invoice_amount":invoiceData?.invoice_amount,
            "invoice_date":invoiceData?.invoice_date !=='' && invoiceData?.invoice_date?.toString().length!==0? invoiceData?.invoice_date : null,
        }
        console.log(param,"param")
         const data = await dispatch(AddInvoiceList(Axios,param))
        if (data?.code === 201) {
            toast.success('Added Invoice SuccessFully');
            setInvoiceData({
                "invoice_amount": 0,
                "invoice_date": null
            })
        }
        else {
            toast.error(`${data?.message}`)
        }
        toggleSet(false)
        }  
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.PopUpBox}
        >
            <Modal.Header closeButton className={styles.PopUpHeader}>
                <Modal.Title className={styles.PopUpTitle}>
                    Add Invoice
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.PopUpBoxBody}>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
                <div className={styles.PopUpInputWrapper}>
                    {/* <TextField
                       name="invoice_id"
                        value={invoiceData.invoice_id}
                        size='small'
                        label="Invoice ID"
                        type="number"
                        autoComplete='off'
                        onChange={handleChange}
                        className={styles.PopUpInput}
                    />  */}
                    <TextField
                        name="invoice_amount"
                        value={invoiceData.invoice_amount}
                        size='small'
                        error={error.invoice_amount ? true : false}
                         helperText={error.invoice_amount ? error.invoice_amount : null}
                        label="Invoice Amount Excluding GST"
                        sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }}} 
                        autoComplete='off'
                        onChange={handleChange}
                        className={styles.PopUpInput}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            name="invoice_date"
                            inputFormat="dd/MM/yyyy"
                            value={invoiceData.invoice_date}
                            onChange={handleInvoiceDateChange}
                            label="Invoice Date"
                            renderInput={(params) => <TextField className={styles.PopUpInput}   
                            helperText={error.invoice_date ? error.invoice_date : null} size="small" {...params} error={false} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                        />
                    </LocalizationProvider>

                </div>
            </Modal.Body>
            <div className={styles.PopUpSubmit}>
                <button className={styles.PopUpSubmitButton} onClick={handleInvoiceSubmit} >Submit</button>
            </div>

        </Modal>
    );
}
function CntrtMgtAddInvoiceModal(props) {
    let { toggle, toggleSet,   contractId, projectCode , contractStartDate } = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={toggle}
                onHide={() => toggleSet(!toggle)}
                toggleSet={toggleSet}
                contractId={contractId}
                projectCode={projectCode}
                contractStartDate={contractStartDate}
            />
        </>
    );
}

export default CntrtMgtAddInvoiceModal