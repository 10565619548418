import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DocumentFileUpload, GetAllDocuments } from '../../actions/Documents/document.action';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Documents from './Documents';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_PATH } from '../../utility/baseUrl';
import styles from './styles/rootDoc.module.css';
import DuplicateFilePrompt from './DuplicateFilePrompt';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DOCUMENTUPLOADALLOWED } from '../../hooks/AllowedRoles';

const DocumentMgt = () => {
    const location = useLocation();
    const [loader, setLoader] = useState(false)
    const [duplicate, setDuplicate] = useState(false);
    const [duplicateList, setDuplicateList] = useState([])
    let Axios = useAxiosPrivate();
    const dispatch = useDispatch();
    const store = useSelector(state => state.Documents);
    const LoginStore = useSelector(state => state.Login)
    
    const [path, setPath] = useState(location?.pathname.substring(location?.pathname.indexOf('/') + 1));
    const [file, setFile] = useState([]);
    const [progress, setProgress] = useState([]);

    useEffect(() => {
        let param = {
            "path": path
        }
        dispatch(GetAllDocuments(Axios, param))
    }, [])

    const url = window.location.pathname;
    useEffect(() => {
        // setPath(location?.pathname.substring(location?.pathname.indexOf('/') + 1))
        console.log(location?.pathname.substring(location?.pathname.indexOf('/') + 1))
        console.log("trigered", url, "path", path)
        let param = {
            "path": location?.pathname.substring(location?.pathname.indexOf('/') + 1)
        }
        dispatch(GetAllDocuments(Axios, param))
    }, [url])


    const handleFileSelect = async (e) => {
        let formdata = new FormData();
        setFile(e.target.files)
        for (let i = 0; i < e.target.files?.length; i++) {
            formdata.append("file", e.target.files[i]);
        }
        formdata.append('path', `${BASE_PATH}${location?.pathname.substring(location?.pathname.indexOf('/') + 1)}/`);
        formdata.append('force', false);

        setLoader(true)

        const Data = await DocumentFileUpload(formdata, setProgress);

        if (Data?.code === 200) {
            setLoader(false)
            toast.success("File Uploaded SuccessFully")
            let param = {
                "path": location?.pathname.substring(location?.pathname.indexOf('/') + 1)
            }
            dispatch(GetAllDocuments(Axios, param))
        }
        else if (Data?.code === 409) {
            setLoader(false)
            setDuplicate(true);
            setDuplicateList(Data.Duplicate_List)
        }
        else {

            setLoader(false)
            toast.error("File Upload Failed")
        }
    }

    const handleReplace = async () => {
        let formdata = new FormData();
        for (let i = 0; i < file?.length; i++) {
            formdata.append("file", file[i]);
        }
        formdata.append('path', `${BASE_PATH}${location?.pathname.substring(location?.pathname.indexOf('/') + 1)}/`);
        formdata.append('force', true);
        setLoader(true)
        const Data = await DocumentFileUpload(formdata, setProgress);

        if (Data?.code === 200) {
            setLoader(false)
            toast.success("File Uploaded SuccessFully")
            let param = {
                "path": location?.pathname.substring(location?.pathname.indexOf('/') + 1)
            }
            dispatch(GetAllDocuments(Axios, param));
            setDuplicate(false)
        }
        else if (Data?.code === 409) {
            setLoader(false)
            setDuplicate(true);
            setDuplicateList(Data.Duplicate_List)
        }
        else {
            setLoader(false)
            toast.error("File Upload Failed")
        }
    }



    console.log(progress, "progress")

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                {
                    loader ?
                        <div className={styles.loaderContainer}>
                            <label>Uploading File...</label>
                            <LinearProgress />
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </div>
                        : <Documents path={path} />
                }

                {
                    DOCUMENTUPLOADALLOWED.includes(LoginStore?.role?.role_code) ? ( <div className={styles.FloatingButton}>
                    <Fab color="primary" aria-label="add" htmlFor="UploadFile">
                        <label htmlFor='UploadFile' style={{ cursor: "pointer" }}><AddIcon sx={{ cursor: "pointer" }} /></label>
                    </Fab>
                </div> ) : null
                }
               

                <input type="file" id="UploadFile" hidden onChange={handleFileSelect} multiple />
                {
                    duplicate && <DuplicateFilePrompt remove={duplicate} setRemove={setDuplicate} setDuplicateList={setDuplicateList} duplicateList={duplicateList} handleReplace={handleReplace} />
                }

            </div>
        </>
    )
}

export default DocumentMgt