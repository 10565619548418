import { GETCONSULTANTDETAIL, GETCONSULTANTDOCUMENTDETAIL, GETCONSULTANTTABLE , GETEXPERTISECONSULTANT, GETSECTORLISTCONSULTANT, GETSTATELISTCONSULTANT } from "../reduxConstants/ConsultantReducerConstants"

const initialState = {
    consultantList : [],
    stateListConsultant : [],
    sectorListConsultant : [],
    expertiseListConsultant : [],
    consultantDetail:{},
    documentList:[]
}

export default (state=initialState, action)=>{
    switch(action.type){
        case GETCONSULTANTTABLE : 
        return {
              ...state,
              consultantList : action.payload
        }
        case GETSTATELISTCONSULTANT : 
        return {
              ...state,
              stateListConsultant : action.payload
        }
        case GETSECTORLISTCONSULTANT : 
        return {
              ...state,
              sectorListConsultant : action.payload
        }
        case GETEXPERTISECONSULTANT : 
        return {
              ...state,
              expertiseListConsultant : action.payload
        }
        case GETCONSULTANTDETAIL : 
        return {
            ...state,
            consultantDetail : action.payload
        }
        case GETCONSULTANTDOCUMENTDETAIL : 
        return {
            ...state,
            documentList : action.payload
        } 
           
   default: return state
}
}

