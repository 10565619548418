import { AuthFileaxios } from "../../api/axios";
import { GETCONSULTANTDETAIL, GETCONSULTANTDOCUMENTDETAIL, GETCONSULTANTTABLE, GETEXPERTISECONSULTANT, GETSECTORLISTCONSULTANT, GETSTATELISTCONSULTANT } from "../../reduxConstants/ConsultantReducerConstants";

export const GetConsultantList = (Axios) =>async(dispatch)=> {
    try{
        const {data} = await Axios.get("/get_all_consultant");
        if(data.code===200){
            // console.log(data?.list)
            dispatch({type:GETCONSULTANTTABLE,payload:data?.consultant_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetStateListConsultant = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/control_field_state_list");
        if(data.code===200){
            dispatch({type:GETSTATELISTCONSULTANT,payload:data?.state_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetBidSectorListConsultant = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_bid_sector_list");
        
        if(data.code===200){
            dispatch({type:GETSECTORLISTCONSULTANT,payload:data?.Bid_Sector_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}
export const GetExpertiseListConsultant = (Axios) =>async(dispatch) => {   
    try{
        const {data} = await Axios.get("/control_field_expertise_list");
        
        if(data.code===200){
            dispatch({type:GETEXPERTISECONSULTANT,payload:data?.expertise_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const FileUploadConsultant = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/consultant_document_upload`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 }
 export const DocumentDeleteConsultantAdd =(param) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/consultant_file_delete_ADD`,param)
         if(data.code===200){
            return data
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }
 export const AddConsultantList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_consultant",param);
        //console.log(data)
        if(data.code===201){
            return data
        }
        
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetConsultantDetail = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/get_consultant_detail",param);
        if(data.code===200){
            dispatch({type:GETCONSULTANTDETAIL,payload:data?.Detail[0]})
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const FileUploadUpdateConsultant = async(formData)=>{
    try {
         const {data} =await AuthFileaxios.post(`/consultant_document_upload_update`,formData)
         if(data.code===200){
            return data
         }
    } catch (error) {
     return error?.response?.data
    }
 } 
 export const DocumentDeleteConsultantUpdate =(param,Axios) =>async(dispatch)=>{
    try {
         const {data} =await AuthFileaxios.post(`/consultant_file_delete`,param)
         if(data.code===200){
            let paramTwo={
                 "cr_id":param?.cr_id
            }
            dispatch(GetConsultantDocumentList(Axios,paramTwo))
            return data
         }
    }
     catch (error) {
     return error?.response?.data
    }
 }

 export const UpdateConsultantList = (Axios,param) =>async(dispatch) => {
    console.log("entered")
    try{
        const {data} = await Axios.post("/update_consultant",param);
        console.log(data,"data")
        if(data.code===200){
            dispatch(GetConsultantList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateConsultantExpertiseList = (Axios,param) =>async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_expertise_list",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetConsultantDocumentList = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/get_consultant_document_list",param);
        console.log(data)
        if(data.code===200){
            dispatch({type:GETCONSULTANTDOCUMENTDETAIL,payload:data?.document_list})
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const GetConsultantDocumentList2 = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/get_consultant_document_list",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const DeleteConsultant = (Axios,param) =>async(dispatch)=> {
    try{
        const {data} = await Axios.post("/delete_consultant",param);
        if(data.code===200){
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}


