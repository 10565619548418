import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from './styles/ctrlFldsMode.module.css'

const UpdateMode = ({item,handleList}) => {  
    const [modeName,setModeName]=useState(item);


    const handleChange=(e)=>{
        setModeName({
            ...modeName,
            mode_name:e.target.value})
        handleList(modeName,e.target.value)
    }

    return (
        <div>
            
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small"   autoComplete="off" type="text" name="mode_name" value={modeName.mode_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateMode