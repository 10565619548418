import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from './styles/ctrlFldsNotice.module.css'

const UpdateNotice = ({item,handleList}) => {  
    const [noticeName,setNoticeName]=useState(item);


    const handleChange=(e)=>{
        setNoticeName({
            ...noticeName,
            notice_name:e.target.value})
        handleList(noticeName,e.target.value)
    }

    return (
        <div>

            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" autoComplete="off" type="text" name="notice_name" value={noticeName.notice_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateNotice