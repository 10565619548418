import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import UnAuthorized from "../components/UnAuthorized/UnAuthorized";

const RequireAuth = ({ allowedRoles }) => {
    const store = useSelector(state => state.Login)
    const location = useLocation();

    return (
        store?.access_token //changed from user to accessToken to persist login after refresh
            ? (

                allowedRoles?.includes(store?.role?.role_code) ?
                    <Outlet /> : <UnAuthorized />)
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;