import { GETALLDOCUMENTS } from "../reduxConstants/documentReducerConstant"

const initialState = {
    FileList : []
}


export default(state=initialState,action)=>{
    switch(action.type){
        case GETALLDOCUMENTS:
            return {
                ...state,
                FileList : action.payload
            }

        default :  return state
    }
}