export const LEVEL1=1021;
export const SUPERADMIN = 9999;
export const LEVEL2 = 1985;
export const LEVEL3=2051;




export const SIGNINALLOWED = [9999,1021]
//////////////////////////////
export const ADMIN = [9999,1021];
export const ALLOWEDUSEREDIT = [1985,2051];
export const ALLOWEDUSERSTATUS = [9999,1021]

//////Tender/////////////
export const OPPORTUNITY = [9999,1021,1985,2051];
export const OPPORTUNITYEXPORTALLOWED = [9999,1021];
export const ADDALLOWEDROLESOPPORTUNITY = [9999, 1021, 1985]
/////////////////////

///////Project///////////
export const PROJECTEXPORTALLOWED = [9999,1021];
export const PROJECTUPDATEALLOWED = [9999,1021];
export const ADDPROJECTALLOWED = [9999,1021];
/////////////////

//////Consultant/////////
export const CONSULTANTEXPORTALLOWED = [9999,1021];
/////////////////////////

//////DOCUMENTS/////////
export const DOCUMENTCREATEFOLDERALLOWED = [9999,1021]
export const DOCUMENTUPLOADALLOWED = [1021,9999,1985]
///////////////////////