import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/DuplicateFilePrompt.module.css";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Card, TextField } from '@mui/material';

import { AddFolderDocument, DeleteDocument } from '../../actions/Documents/document.action';
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton, SubmitButton } from '../../utility/button/button';


function MyVerticallyCenteredModal(props) {
    const [folderText, setFolderText] = useState('')
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    const { setRemove,setDuplicateList,duplicateList,handleReplace } = props

    const [error, setError] = useState({})

    let handleChange = (e) => {
        setFolderText(e.target.value)

    }


    
    return (
        <>
           
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.PopUpBox}
            >
                <Modal.Header closeButton className={styles.PopUpHeader}>
                    <Modal.Title className={styles.PopUpTitle}>
                       File Already Exist! Do You Want to replace? 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={styles.addFolderInputWrapper}>
                         {
                            duplicateList.map(item=>(
                                <Card sx={{minWidth:"300px"}}>
                                     <label className={styles.DuplicateFileName}>{item}</label>
                                </Card>
                               
                            ))
                         }
                    </div>
                </Modal.Body>
                <div className={styles.PopUpSubmit}>
                    <SubmitButton onClick={handleReplace}>Replace</SubmitButton>
                    <CancelButton onClick={()=>setRemove(false)}>Cancel</CancelButton>
                </div>
            </Modal></>
    );
}
function DuplicateFilePrompt(props) {
    let { remove, setRemove,setDuplicateList,duplicateList,handleReplace} = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={remove}
                onHide={() => setRemove(!remove)}
                setRemove={setRemove}
                setDuplicateList={setDuplicateList}
                duplicateList={duplicateList}
                handleReplace = {handleReplace}
            />
        </>
    );
}

export default DuplicateFilePrompt