import { combineReducers } from "redux";
import Admin from './AdminReducer'
import Login from "./LoginReducer";
import ControlFields from  "./ControlFieldsReducer";
import Tender from './TenderReducer';
import Project from './ProjectsReducer'
import Contractor from "./ContractorReducer"
import Consultant from './ConsultantReducer'
import Documents from './DocumentReducer'
import Downloads from './DownloadReducer'


export const Reducer = combineReducers({
      Admin,
      Login,
      ControlFields,
      Tender,
      Project,
      Contractor,
      Consultant,
      Documents,
      Downloads
})