export const columnData = {
    tender_code: true,
    added_by: false,
    bid_tracking_date: false,
    bid_published_date: false,
    bid_title: true,
    state_name: false, 
    bid_source: false,
    donor_name: false,
    notice_name: false,
    pre_bid_meeting_date: false,
    submission_date_time: true,
    submission_mode: false,
    hand_submission: false,
    tender_fee: false,
    security_fee: false,
    bid_category: false,
    bid_sector: false,
    bid_function: false,
    selected_for_bidding: true,
    bid_advice: false,
    bid_task_deadline: true,
    bid_review_deadline: false,
    nominated_user_name: true,
    consortium_partners: false,
    bid_submitted: false,
    actual_submission_date: false,
    result_responsibility: false,
    eoi_shortlisted: false,
    rfp_consultant: false,
    rfp_shortlisted: false,
    rfp_quote: false,
    rfp_won: false,
    security_refund: false,
    security_refund_date_time: false,
    comments: false,
    created_at: false,
    updated_by_name: false,
}

export const SelectALLData = {
    tender_code: true,
    added_by: true,
    bid_tracking_date: true,
    bid_published_date: true,
    bid_title: true,
    state_name: true, 
    bid_source: true,
    donor_name: true,
    notice_name: true,
    pre_bid_meeting_date: true,
    submission_date_time: true,
    submission_mode: true,
    hand_submission: true,
    tender_fee: true,
    security_fee: true,
    bid_category: true,
    bid_sector: true,
    bid_function: true,
    selected_for_bidding: true,
    bid_advice: true,
    bid_task_deadline: true,
    bid_review_deadline: true,
    nominated_user_name: true,
    consortium_partners: true,
    bid_submitted: true,
    actual_submission_date: true,
    result_responsibility: true,
    eoi_shortlisted: true,
    rfp_consultant: true,
    rfp_shortlisted: true,
    rfp_quote: true,
    rfp_won: true,
    security_refund: true,
    security_refund_date_time: true,
    comments: true,
    created_at: true,
    updated_by_name: true,
   
   
}