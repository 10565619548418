import { LOGINUSERDATA, NEWACCESSTOKEN,LOGOUTUSER, EMPTYTOKEN } from "../reduxConstants/LoginReducerConstant"

const initialState = {
    "access_token": "",
    "user_id": 0,
    "role": {},
}

export default (state = initialState, action) => {
    switch (action.type) {

        case LOGINUSERDATA:
            return {
                ...state,
                "access_token": action.payload?.access_token,
                "user_id": action.payload?.user_id,
                "role": action.payload?.role,
            }

        case NEWACCESSTOKEN:
            return{
                ...state,
                access_token : action.payload?.access_token,
                role: action.payload?.role,
                "user_id": action.payload?.user_id,
            }
        
            case  LOGOUTUSER:
            return {
                ...state,
                "access_token": "",
                "user_id": 0,
                "role": {},
            }

            case EMPTYTOKEN:
                return{
                    ...state,
                    "access_token": "",
                "user_id": 0,
                "role": {},
                }

        default: return state
    }
}