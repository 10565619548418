import React from 'react';
import { useDispatch } from 'react-redux';
import { Axios } from '../api/axios';
import { NEWACCESSTOKEN } from '../reduxConstants/LoginReducerConstant';

const useRefreshToken = () => {
    const dispatch = useDispatch();

    const refresh =async()=>{
           const {data} =  await Axios.post('/get_access_token');
           //console.log(data,"refresh")
           dispatch({type:NEWACCESSTOKEN,payload:data})
         return data?.access_token
    }
  return refresh
}

export default useRefreshToken