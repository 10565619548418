import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAllDocuments } from '../../actions/Documents/document.action'
import AddFolderModal from './AddFolderModal'
import DocumentsFolders from './DocumentsFolders'
import styles from "./styles/documents.module.css"
import SubFolder from './SubFolder';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DeleteFilePrompt from './DeleteFilePrompt'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ADMIN, DOCUMENTCREATEFOLDERALLOWED } from '../../hooks/AllowedRoles'

const Documents = ({ path }) => {
    const location = useLocation()
    let Axios = useAxiosPrivate()
    const dispatch = useDispatch();
    const store = useSelector(state => state.Documents)
    const LoginStore = useSelector(state => state.Login)
    const [addFolderToggle, setAddFolderToggle] = useState(false)
    const [toggleSubFolder, setToggleSubFolder] = useState(false);
    const [folderList, setFolderList] = useState([]);
    const [remove,setRemove] = useState(false);
    const [file,setFile] = useState({})
    

    useEffect(() => {
        setFolderList(store.FileList)
    }, [store.FileList])

    const handleDelete=(file)=>{
        if(ADMIN.includes(LoginStore?.role?.role_code)){
          setRemove(true);
          setFile(file)  
        }
        else{
            toast.error('Only Level1 User Allowed To Delete Documents')
        }
        

    }

    const ToastShow =(file,toastBool)=>{
          if(toastBool){
            toast.success(file? `Folder Deleted SuccessFully` : `File Deleted SuccessFully`)
          }
          else{
            toast.error("Failed To Delete")
          }
    }
    

    return (
        <div style={{height:"100%"}}>
             <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
       
        <div className={styles.rootContainer}>
            <div className={styles.DocumentsHeader}>
                <div>
                    <h2 className={styles.DocumentsTitle}>Documents</h2>
                </div>
                <div className={styles.CreateFolderButtonWrapper}>
                    {
                       DOCUMENTCREATEFOLDERALLOWED.includes(LoginStore?.role?.role_code) ?
                           ( toggleSubFolder === false ? <button className={styles.CreateFolderButton} onClick={() => setAddFolderToggle(!addFolderToggle)}>Create Folder</button> : null ) : null
                    }
                </div>
            </div>
            <div className={styles.DocumentsFolders} >
                {
                    folderList?.map((item) => (
                        <div >
                            <DocumentsFolders file={item} path={path} handleDelete={handleDelete} />
                        </div>

                    ))
                }

            </div>

            {addFolderToggle === true ? <AddFolderModal toggle={addFolderToggle} toggleSet={setAddFolderToggle} /> : null}
            { remove && <DeleteFilePrompt remove={remove} setRemove={setRemove} file={file} path={location?.pathname.substring(location?.pathname.indexOf('/') + 1)} ToastShow={ToastShow}/>}
        </div>
        </div>
    )
}

export default Documents