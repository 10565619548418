import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/DeleteFilePrompt.module.css";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';

import { AddFolderDocument, DeleteDocument } from '../../actions/Documents/document.action';
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton, SubmitButton } from '../../utility/button/button';


function MyVerticallyCenteredModal(props) {
    const [folderText, setFolderText] = useState('')
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    const { setRemove,file,path,ToastShow } = props

    const [error, setError] = useState({})

    let handleChange = (e) => {
        setFolderText(e.target.value)

    }


    let handleDelete = async () => {
        
        let param = {
           "path":file?.path
        }
        let data = await dispatch(DeleteDocument(Axios,param,path));

        if(data?.code===200){
            ToastShow(file?.isFolder,true)
            setRemove(false)
        }
        else{
            ToastShow(file?.isFolder,false)
        }
    }
    return (
        <>
           
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.PopUpBox}
            >
                <Modal.Header closeButton className={styles.PopUpHeader}>
                    <Modal.Title className={styles.PopUpTitle}>
                       Are You Sure to delete ? 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={styles.addFolderInputWrapper}>
                         {
                            file?.isFolder ? (
                                <div>
                                <label>{file.filename}</label>
                                <label>{`Folder Contains ${file?.files_count} files`}</label>
                                </div>
                            ):
                            (
                                <div>
                                   <label>{file.filename}</label>
                                </div>
                            )
                         }
                    </div>
                </Modal.Body>
                <div className={styles.PopUpSubmit}>
                    <SubmitButton onClick={handleDelete}>Delete</SubmitButton>
                    <CancelButton onClick={()=>setRemove(false)}>Cancel</CancelButton>
                </div>
            </Modal></>
    );
}
function DeleteFilePrompt(props) {
    let { remove, setRemove,file,path,ToastShow} = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={remove}
                onHide={() => setRemove(!remove)}
                setRemove={setRemove}
                file={file}
                path={path}
                ToastShow={ToastShow}
            />
        </>
    );
}

export default DeleteFilePrompt