import React, { useEffect, useState } from 'react'
import styles from './styles/projectManagementDetails.module.css';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import ProjectMngtDetailsModal from './ProjectMngtDetailsModal';
import { columnData,SelectALLData } from "./ProjectMngtClmnData";
import editIcon from "./images/editIconNew.png"
import ChildTable from './ChildTable';
import ProjectManagementTable from './ProjectTable';
import { GetChildDetailProject, GetChildDetailProject2, GetProjectDetail, GetProjectList } from '../../actions/ProjectManagement/ProjectAction';
import { useLocation, useNavigate } from 'react-router-dom';
import exportFromJSON from "export-from-json"
import { PROJECTEXPORTALLOWED } from '../../hooks/AllowedRoles';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { NormalDateFormat } from '../../utility/DateFunction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@mui/material';
import { Export } from '../../utility/export';


const SearchFilter = (data, columns, q) => {
    if (data !== undefined && data !== null && data.length !== 0) {
        return data.filter((row) =>
            columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
    }
}

const ProjectManagementDetails = () => {
    const [state, setState] = useState(columnData)
    const LoginStore = useSelector(state=>state.Login)
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(10);
    let [show, setShow] = useState(false)
    const [invoiceProjectTitle,setInvoiceProjectTitle] = useState("")
    const [projectList, setProjectList] = useState([])
    let [showInvoice,setShowInvoice] = useState(false)
    let [exportInvoiceData,setExportInvoiceData] = useState([])
    let [columns, setColumns] = useState(["project_title", "internal_team_member","contract_start_date", "contract_closure_date" ,"budgeted_cost", "deliverables_no"]);
    const [selectAll, setSelectAll] = useState(false)
    const store = useSelector(state=>state.Project)
    const Axios = useAxiosPrivate()
    const location = useLocation();
    const dispatch = useDispatch()

    const IndexOfLastPost = currentPage * postsPerPage
    const IndexOfFirstPost = IndexOfLastPost - postsPerPage
    const pageNumbers = Math.ceil(projectList?.length / postsPerPage)
    const currentPosts = SearchFilter(projectList, ['project_title'], searchText)?.slice(IndexOfFirstPost, IndexOfLastPost);

    useEffect(()=>{
        dispatch(GetProjectList(Axios))
        },[])
    useEffect(()=>{
        let key_name = "contract_start_date";
           const sorted = store?.projectList?.slice().sort((a, b) => key_name.endsWith("date") || key_name.endsWith("time")? new Date(a[key_name])- new Date(b[key_name]) : a[key_name] - b[key_name]
        )
        setProjectList(sorted)
       
    },[store])

    useEffect(()=>{
        let Page = location?.pathname?.substring(location?.pathname?.lastIndexOf('Page:'));
        let PageNumber = Page?.substring(Page?.lastIndexOf(':')+1);
        console.log(PageNumber)
        setCurrentPage(parseInt(PageNumber))
    },[location])
   let arr = columns;

    useEffect(() => {
        let ObjectKeys = Object.keys(columnData);
        let CheckArr = []
        ObjectKeys.map((item, index) => {
            if (columnData[item]) {
                if (arr.includes(item)) {
                    CheckArr = [...CheckArr,item]
                    setColumns(
                        CheckArr
                    )
                }
                else {
                    arr.push(item);
                    setColumns(arr)
                }
            }
        }
        )
    }, [])

   
    const handleSearch = (e) => {
        setSearchText(e.target.value)
        console.log(navigate('/ProjectsIndex/Page:1'))
        navigate('/ProjectsIndex/Page:1')
    }
    const handleSelectAll = (e) => {
        setSelectAll(!selectAll);
        if (e.target.checked) {
            setColumns(Object.keys(state));
            setState(SelectALLData)
        }
        else{
            setColumns(["project_title", "internal_team_member","contract_start_date", "contract_closure_date" ,"budgeted_cost", "deliverables_no"])
            setState(columnData)
        }
    }
    const ExportToExcel = async() => {
            if (PROJECTEXPORTALLOWED.includes(LoginStore?.role?.role_code)) {
                for(let i = 0; i< store?.projectList?.length; i++ ){
                    let param = {
                    "project_id":store?.projectList[i]?.project_id
                    }
                let OBJ = await GetChildDetailProject2(Axios , param);

                let OBJ2 = {}
             
                OBJ2['Added By']=store?.projectList[i]?.added_by
                OBJ2['Budgeted Cost']=store?.projectList[i]?.budgeted_cost
                OBJ2['Comments']=store?.projectList[i]?.comments
                OBJ2['Contract Closure Date']=NormalDateFormat(store?.projectList[i]?.contract_closure_date)
                OBJ2['Contract Start Date']=NormalDateFormat(store?.projectList[i]?.contract_start_date)
                OBJ2['Contract Value']=store?.projectList[i]?.contract_value
                OBJ2['Created At']=NormalDateFormat(store?.projectList[i]?.created_at)
                OBJ2['Deliverables No']=store?.projectList[i]?.deliverables_no
                OBJ2['Internal Team Member']=store?.projectList[i]?.internal_team_member
                OBJ2['Performance Security Amount']=store?.projectList[i]?.performance_security_amount
                OBJ2['Performance Security Date'] = NormalDateFormat(store?.projectList[i]?.performance_security_date)
                OBJ2['Performance Security Refund']=store?.projectList[i]?.performance_security_refund
                OBJ2['PIP Approved']=store?.projectList[i]?.pip_approved
                OBJ2['Project Certificate']=store?.projectList[i]?.project_certificate
                OBJ2['Project Code']=store?.projectList[i]?.project_code
                OBJ2['Project Manager Name']=store?.projectList[i]?.project_manager_name
                OBJ2['Project Sheet Submitted']=store?.projectList[i]?.project_sheet_submitted
                OBJ2['Project Title']   =store?.projectList[i]?.project_title
                OBJ2['Target Net Revenue']=store?.projectList[i]?.target_net_revenue
                OBJ2['Updated At']=NormalDateFormat(store?.projectList[i]?.updated_at)
                OBJ2['Updated By']=store?.projectList[i]?.updated_by_name
                for(let j=0 ; j<OBJ?.length ; j++){
    
                  OBJ2[`Invoice ${j+1} Deliverable Detail`] = OBJ[j]?.deliverable_detail
                  OBJ2[`Invoice ${j+1} Deliverable Due Date`] = NormalDateFormat(OBJ[j]?.deliverable_due_date)
                  OBJ2[`Invoice ${j+1} Deliverable Payment`] = OBJ[j]?.deliverable_payment
                  OBJ2[`Invoice ${j+1} Invoice Amount`] = OBJ[j]?.invoice_amount
                  OBJ2[`Invoice ${j+1} Invoicing Date`] = NormalDateFormat(OBJ[j]?.invoicing_date)
                  OBJ2[`Invoice ${j+1} Payment Amount`] = OBJ[j]?.payment_amount
                  OBJ2[`Invoice ${j+1} Payment Receipt Date`] = NormalDateFormat(OBJ[j]?.invoicing_date)
                  OBJ2[`Invoice ${j+1} Submission Date`] = NormalDateFormat(OBJ[j]?.submission_date)
                  OBJ2[`Invoice ${j+1} Created At`] = NormalDateFormat(OBJ[j]?.created_at)
                  OBJ2[`Invoice ${j+1} Created By`] = store?.projectList[i]?.added_by
                }

              exportInvoiceData.push(OBJ2)
                }
                Export(exportInvoiceData , 'Project List')
        }
        else{
            toast.error("No Access to Download")
          }
    }

    const handleNavigate=()=>{
        navigate('/AddProject')
   }
    let toggleInvoice = ()=>{
        setShowInvoice(!showInvoice)
    }
    let toggleColumn = () => {
        setShow(!show)
    }

    const [sort, setSort] = useState('contract_start_date')

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        let path = location?.pathname.substring(0, location?.pathname.indexOf(':'));
        navigate(path + `:${value}`)
    };
    
    const handleChildProjectDetail = async(item) => {
        setInvoiceProjectTitle(item?.project_title)
        let param = {
            "project_id":item?.project_id
        }
        toggleInvoice()
        await dispatch(GetChildDetailProject(Axios , param))
    } 

    const handleSort = (e) => { 
        let key_name = e.target.value;
        const sorted = projectList.slice().sort((a, b) =>key_name.endsWith("date")? new Date(a[e.target.value])- new Date(b[e.target.value]) : a[e.target.value] - b[e.target.value]
        )
        setProjectList(sorted)
    }
    // console.log(columns)
    // ADDPROJECTALLOWED
    return (
        <>
         <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        <div className={styles.rootContainer}>
       
                <h2 className={styles.ProjectMngDetailsTitle}>Project Management Details</h2>

            <div className={styles.filterWrapper}>
                <div className={styles.filterOptionsLeftWrapper}>
                    <div className={styles.filterOptionsWrapper}>
                         <div className={styles.fltrOpnsColumn} onClick={toggleColumn}>
                        <TableChartOutlinedIcon color='success' />
                        <label>Column</label>
                    </div>
                    {
                        PROJECTEXPORTALLOWED.includes(LoginStore?.role?.role_code) ? 
                    <div className={styles.fltrOpnsColumn} onClick={ExportToExcel} >
                        <IosShareOutlinedIcon />
                        <p>Export</p>
                    </div> : null
                    }
                    </div>
                    {
                        PROJECTEXPORTALLOWED.includes(LoginStore?.role?.role_code) ? 
                        <div className={styles.ButtonWrapper}>
                        <button type="submit" className={styles.AddProjectButton} onClick={handleNavigate}>Add Project</button>
                        </div> : null
                    }
                       <div className={styles.searchWrapper}>
                        <TextField variant="outlined" label="Project Title" className={styles.searchInputField} sx={{ minWidth: "120px", maxWidth: '170px' }} size="small" name="searchList" autoComplete='off' onChange={handleSearch} value={searchText} />
                    </div>
                    
                </div>
               
                <div className={styles.fltrOpnsColumnRightWrapper}>
                    <Pagination shape="rounded" count={pageNumbers} page={currentPage} className={styles.tablePagination} onChange={handlePageChange} />
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={styles.sortTableButtonWrapper}>
                        <InputLabel >Sort By</InputLabel>
                        <Select className={styles.sortTableButton}
                            label="Sort By"
                            defaultValue={sort}
                            onChange={handleSort}
                        >
                            <MenuItem className={styles.sortTableButtonList} value="contract_start_date">Contract Start Date</MenuItem>
                            <MenuItem className={styles.sortTableButtonList} value="budgeted_cost">Budgeted Cost</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

          <div className={styles.ProjectsTableWrapper}>
                <ProjectManagementTable handleChildProjectDetail={handleChildProjectDetail} columns={columns} currentPosts={currentPosts}
                 currentPage={currentPage} postsPerPage={postsPerPage} toggleInvoice={toggleInvoice} editIcon={editIcon}  />
          </div>
            {show === true ? <ProjectMngtDetailsModal selectAll={selectAll} handleSelectAll={handleSelectAll}  toggle={show} toggleSet={setShow} columns={columns} setColumns={setColumns} state={state} setState={setState} /> : null}
            {showInvoice === true ? <ChildTable invoiceProjectTitle={invoiceProjectTitle} toggle={showInvoice} toggleSet={setShowInvoice}/> : null}
            </div>
        </>
    )
}

export default ProjectManagementDetails