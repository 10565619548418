import React, { useEffect, useState } from 'react'
import styles from './styles/userMgt.module.css'
import UserMgtEdit from './UserMgtEdit'
import AddUser from './AddUser';
import UserMgtTable from './UserMgtTable';
import { GetRoleList, GetUserList } from '../../actions/admin/adminAction';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { EDITUSERDATA } from '../../reduxConstants/adminReducerConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ALLOWEDUSEREDIT, LEVEL1, SUPERADMIN } from '../../hooks/AllowedRoles';



const UserMgt = () => {
    const store = useSelector(state => state.Login)
    const Axios = useAxiosPrivate()
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    React.useEffect(() => {
        const GetData = async () => {
            const dataOne = await dispatch(GetUserList(Axios))
            if (dataOne !== undefined && dataOne?.code !== 200) {
                toast.error(`${dataOne?.message}`)
            }
            const dataTwo = await dispatch(GetRoleList(Axios))
            if (dataTwo !== undefined && dataTwo?.code !== 200) {
                toast.error(`${dataTwo?.message}`)
            }
        }

        GetData()
    }, [])

    const handleToggle = (item) => {
    
        if (parseInt(store?.role?.role_code)===SUPERADMIN) {
            setShow(!show);
            dispatch({ type: EDITUSERDATA, payload: item })
        }
        else if(store?.role?.role_code===LEVEL1){
            if(parseInt(item?.role_code)=== (LEVEL1 || SUPERADMIN)){
             toast.error("Only Super Admin Can Change the Details")
            }
            else{
                setShow(!show);
                dispatch({ type: EDITUSERDATA, payload: item })
            }

        }
    }
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.Container}>
                <h2 className={styles.usrMngTitle}>User Management</h2>
                <div className={styles.AddContainer}>
                    <div className={styles.userAddMenuWrapper} >
                        <AddUser />
                    </div>
                </div>
                <UserMgtTable handleToggle={handleToggle} />
                {show === true ? <UserMgtEdit toggle={show} toggleSet={setShow} /> : null}

            </div>
        </>
    )
}

export default UserMgt