import { GETUSERLISTUSERMGT , GETROLELISTUSERMGT ,ADDUSERUSERMGT } from '../../reduxConstants/adminReducerConstants';




export const GetUserList = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/user_list");
        //  console.log(data)
        if(data.code===200){
            dispatch({type:GETUSERLISTUSERMGT,payload:data.userList})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetRoleList = (Axios) => async(dispatch) => {
    try{
        const {data} = await Axios.get("/role_list");
        //console.log(data)
        if(data.code===200)
        {
            dispatch({type:GETROLELISTUSERMGT,payload:data.roleList})
        }
    }
    catch(error){
        
        return error?.response?.data
    }
}

export const AddAdminUser = (Axios,param) => async(dispatch) => {
    try{
        const {data} = await Axios.post("/add_user",param);
        //console.log(data)
        if (data.code === 201) {
            dispatch(GetUserList(Axios))
            return data
        }
    }
    catch(error){
          
        return error?.response?.data
    }
} 


export const UpdateAdminUser = (Axios,param) => async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_user",param);

        if (data.code === 200) {
            dispatch(GetUserList(Axios))
            return data
        }
    }
    catch(error){
          
        return error?.response?.data
    }
} 


export const AdminUserStatus = (Axios,param) => async(dispatch) => {
    try{
        const {data} = await Axios.post("/update_status",param);

        if (data.code === 200) {
            dispatch(GetUserList(Axios))
            return data
        }
    }
    catch(error){
          
        return error?.response?.data
    }
} 