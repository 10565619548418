import React from 'react'
import CtrlFldsBidCat from './BidCategory/CtrlFldsBidCat'
import CtrlFldsBidFnc from './BidFnc/CtrlFldsBidFnc'
import CtrlFldsBidSector from './BidSector/CtrlFldsBidSector'
import CtrlFldsDonor from './Donor/CtrlFldsDonor'
import CtrlFldsFinYear from './FinancialYear/CtrlFldsFinYear'
import CtrlFldsMode from './Mode/CtrlFldsMode'
import CtrlFldsNotice from './Notice/CtrlFldsNotice'
import CtrlFldsStates from './StateorUT/CtrlFldsStates'
import CtrlFldsExpertise from './Expertise/CtrlFldsExpertise'
import styles from "./StateorUT/styles/ctrlFldsStates.module.css";

const ControlFieldsMgt = () => {
  return (
    <div>
       <div className={styles.CtrlFldTitle}>Admin Control Fields</div>
       <div  className={styles.CtrlFldIndexBody}>
   <CtrlFldsStates />
      <CtrlFldsDonor />
      <CtrlFldsNotice />
      <CtrlFldsMode />
      <CtrlFldsFinYear />
      <CtrlFldsBidCat />
      <CtrlFldsBidFnc />
      <CtrlFldsBidSector />
      <CtrlFldsExpertise />
       </div>
   
    </div>
  )
}

export default ControlFieldsMgt