import React, { useEffect, useState } from 'react'
import styles from "./styles/documentsFolders.module.css"
import folderImage from "./images/folderImage.jpg"
import { useDispatch, useSelector } from 'react-redux'
import JPEG from "./images/jpegFormatImage.png"
import PNG from "./images/pngFormatImage.png"
import PDF from "./images/pdfFormatImage.png"
import DOCX from "./images/docxFormatImage.png"
import JPG from "./images/jpgFormatImage.png"
import PPTX from "./images/pptxFormatImage.png"
import RAR from "./images/rarFormatImage.png"
import TXT from "./images/txtFormatImage.png"
import XLS from "./images/xlsFormatImage.png"
import ZIP from "./images/zipFormatImage.png"
import VIDEO from "./images/videoFormatImage.png"
import CSV from "./images/csvFormatImage.png"
import SQL from "./images/sqlFormatImage.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { GetAllDocuments, RenameDocument } from '../../actions/Documents/document.action'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import DeleteFilePrompt from './DeleteFilePrompt';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';






const DocumentsFolders = ({ file, path, handleDelete }) => {
    const Axios = useAxiosPrivate()
    const dispatch = useDispatch()
    // const store = useSelector(state=>state.Document);
    const location = useLocation();
    const navigate = useNavigate();
    const [toggleEdit, setToggleEdit] = useState(false)
    const [editFolderName, setEditFolderName] = useState([]);

    const [Obj, setObj] = useState({
        "newFilename": "",
        "filename": "",
        "isFolder": false,
        "path": "",
        "files_count": 0
    })


    useEffect(() => {
        if (!file.isFolder) {
            let filename = file?.filename?.substring(file?.filename?.lastIndexOf('/') + 1);
            let NewFilename = filename.substring(0, filename.indexOf('.'))
            setObj({
                "newFilename": NewFilename,
                "filename": file?.filename,
                "isFolder": file?.isFolder,
                "path": file?.path,
                "files_count": file?.files_count,
            })
        }
        else {
            let NewFilename = file?.filename
            setObj({
                "newFilename": NewFilename,
                "filename": file?.filename,
                "isFolder": file?.isFolder,
                "path": file?.path,
                "files_count": file?.files_count,
            })
        }

    }, [file])






    const handleClick = (e) => {
        console.log("wdwadwad")

    };

    const handleFileClick = () => {
        setToggleEdit(false)
    }

    const handleBlur = () => {
        console.log("blurr")
        setToggleEdit(false)
    }

    const handleEdit = () => {
        setToggleEdit(!toggleEdit)
    }

    const handleDoubleClick = (file) => {
        const WholePath = location?.pathname;
        console.log("WholePath", WholePath);
        console.log("double", file);
        navigate(`${WholePath}/${file?.filename}`)
        let param = {
            "path": `${WholePath}/${file?.filename}`
        }
        dispatch(GetAllDocuments(Axios, param))

    }


    const handleFileRename = async (e) => {
        e.preventDefault();
        let param = {
            "old_file_name": Obj.path,
            "new_file_name": Obj.newFilename,
            "isFolder":file?.isFolder
        }
        let pathname = location?.pathname.substring(location?.pathname.indexOf('/') + 1)
        const data = await dispatch(RenameDocument(Axios, param, pathname));

        if (data.code === 200) {
            toast.success("File Renamed SuccessFully");
            setToggleEdit(false)
        }
        else {
            toast.error(data.message)
        }
    }



    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.DocumentsFoldersWrapper} >

                <Card compoenent="paper" className={styles.Card} sx={{ cursor: file?.isFolder ? "pointer" : null }} onDoubleClick={file?.isFolder ? () => handleDoubleClick(file) : null} >
                    <div className={styles.DeleteContainer}>
                        <IconButton onClick={() => handleDelete(file)} edge='end'><DeleteIcon /></IconButton>
                        {
                            file?.isFolder ? null :  <IconButton href={file?.download_path} edge='end' target='_blank'><DownloadForOfflineIcon /></IconButton>
                        }
                    </div>
                    <div className={styles.DocumentsFolderItem} >
                        {
                            file?.isFolder ?
                                <>
                                    <img src={folderImage} alt="not Found" className={styles.folderImage} onDoubleClick={() => handleDoubleClick(file)} />

                                </>

                                :
                                <>
                                    <img
                                        src={
                                            file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "jpeg" ?
                                                JPEG : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "png" ?
                                                    PNG : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "docx" ?
                                                        DOCX : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "pdf" ?
                                                            PDF : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "jpg" ?
                                                                JPG : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "pptx" ?
                                                                    PPTX : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "rar" ?
                                                                        RAR : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "txt" ?
                                                                            TXT : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "xls" ?
                                                                                XLS : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "zip" ?
                                                                                    ZIP : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "csv" ?
                                                                                        CSV : file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "sql" ?
                                                                                            SQL : (file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "mkv" ||
                                                                                                file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "mp4" ||
                                                                                                file?.filename?.substring(file?.filename?.lastIndexOf('.') + 1) === "avi") ?
                                                                                                VIDEO : TXT
                                        }
                                        alt="not Found" className={styles.folderImage}
                                    //  onClick={handleFileClick}
                                    />

                                </>
                        }



                        <div onBlur={handleBlur} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {

                                toggleEdit ?

                                    <div className={styles.InputContainer}>
                                        <form onSubmit={handleFileRename}>
                                            <input type="text" className={styles.Input} value={Obj.newFilename}
                                                onBlur={handleBlur}
                                                onChange={(e) => setObj({ ...Obj, newFilename: e.target.value })}
                                            />
                                        </form>

                                    </div>

                                    :
                                    <div className={styles.TextContainer}>
                                        <label className={styles.DocumentsFolderTitle} onClick={handleEdit}>{file.filename}</label>
                                    </div>



                            }
                        </div>

                    </div>
                </Card>
            </div>
        </>
    )
}

export default DocumentsFolders