import { styled } from '@mui/material/styles';
import {Button} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const AddButton = styled(Button)(({ theme }) => ({
    padding:"5px 15px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#ff9800",
    border: "2px solid #ff9800",
    '&:hover': {
        color: "white",
        backgroundColor: "#ff9800",
        border: "2px solid #ff9800",
    },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
    padding:"5px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#3a5172",
    border: "2px solid #3a5172",
    '&:hover': {
        color: "white",
        backgroundColor: "#3a5172",
        border: "2px solid #3a5172",
    },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    padding:"5px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#bfbfbf",
    border: "2px solid #bfbfbf",
    '&:hover': {
        color: "white",
        backgroundColor: "#bfbfbf",
        border: "2px solid #bfbfbf",
    },
}));
export const CtrlFldsSubmitButton = styled(LoadingButton)(({ theme }) => ({
    padding:"7px 30px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#3a5172",
    border: "2px solid #bfbfbf",
    '&:hover': {
        color: "white",
        backgroundColor: "#3a5172",
        border: "2px solid #2d3c57",
    },
}));


export const LoginButton = styled(LoadingButton)(({ theme }) => ({
    padding:"10px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#3a5172",
    border: "2px solid #2d3c57",
    borderRadius:"25px",
    width: "100%",
    '&:hover': {
        color: "white",
        backgroundColor: "#3a5172",
        border: "2px solid #2d3c57",
    },
}));


export const SuccessButton = styled(LoadingButton)(({ theme }) => ({
    padding:"10px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#00b050",
    border: "2px solid #00b050",
    borderRadius:"25px",
    width: "100%",
    '&:hover': {
        color: "white",
        backgroundColor: "#00b050",
        border: "2px solid #00b050",
    },
}));

export const AddLoadingButton = styled(LoadingButton)(({ theme }) => ({
    padding:"10px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#ff9800",
    border: "2px solid #ff9800",
    // borderRadius:"25px",
    // width: "100%",
    '&:hover': {
        color: "white",
        backgroundColor: "#ff9800",
        border: "2px solid #ff9800",
    },
}));

export const ErrorButton = styled(LoadingButton)(({ theme }) => ({
    padding:"10px 25px",
    fontWeight:"bold",
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
    borderRadius:"25px",
    width: "100%",
    '&:hover': {
        color: "white",
        backgroundColor: "#DC1D13",
        border: "2px solid #DC1D13",
    },
}));