import {GETCONTROLFIELDSTATELIST,
    GETCONTROLFIELDDONORLIST,
    GETCONTROLFIELDNOTICELIST,
    GETCONTROLFIELDMODELIST,
    GETCONTROLFIELDFINANCIALLIST,
    GETCONTROLFIELDBIDCATEGORYLIST,
    GETCONTROLFIELDBIDFUNCTIONLIST,
    GETCONTROLFIELDBIDSECTORLIST,
    GETCONTROLFIELDEXPERTISELiST} from "../reduxConstants/controlFieldsConstants";

const initialState = {
     stateList:[],
     donorList:[],
     noticeList:[],
     modeList:[],
     financialList:[],
     BidCategoryList:[],
     BidFunctionList:[],
     BidSectorList:[],
     ExpertiseList:[]
}

export default (state=initialState, action)=>{
    switch(action.type){
        case GETCONTROLFIELDSTATELIST : 
        return {
              ...state,
              stateList : action.payload
        }

        case GETCONTROLFIELDDONORLIST : 
        return {
              ...state,
              donorList : action.payload
        }

        case GETCONTROLFIELDNOTICELIST : 
        return {
              ...state,
              noticeList : action.payload
        }
        case GETCONTROLFIELDMODELIST : 
        return {
              ...state,
              modeList : action.payload
        }
        case GETCONTROLFIELDFINANCIALLIST : 
        return {
              ...state,
              financialList : action.payload
        }
        case GETCONTROLFIELDBIDCATEGORYLIST : 
        return {
              ...state,
              BidCategoryList : action.payload
        }
        case GETCONTROLFIELDBIDFUNCTIONLIST : 
        return {
              ...state,
              BidFunctionList : action.payload
        }
        case GETCONTROLFIELDBIDSECTORLIST : 
        return {
              ...state,
              BidSectorList : action.payload
        }
        case GETCONTROLFIELDEXPERTISELiST : 
        return {
            ...state,
            ExpertiseList : action.payload
        }
        default: return state
    }
}