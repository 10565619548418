import { GETPROJECTLIST, GETTENDERLISTPROJECT, GETUSERLISTPROJECT, GETPROJECTDETAIL,GETPROJECTCHILDDETAIL,GETPROJECTROLELIST, GETDOCUMENTLISTPROJECT } from "../reduxConstants/ProjectReducerConstants";
const initialState = {
    projectList: [],
    tenderListProject: [],
    userListProject: [],

    projectDetail: {},
    projectRoleList: [],
    projectChildDetail:[]
}
export default (state = initialState, actions) => {
    switch (actions.type) {
        case GETPROJECTLIST:
            return {
                ...state,
                projectList: actions.payload
            }
        case GETTENDERLISTPROJECT:
            return {
                ...state,
                tenderListProject: actions.payload
            }
        case GETUSERLISTPROJECT:
            return {
                ...state,
                userListProject: actions.payload
            }
        case GETPROJECTDETAIL:
            return {
                ...state,
                projectDetail: actions.payload
            }
        case  GETPROJECTROLELIST:
            return {
                ...state,
                projectRoleList: actions.payload
            }
            case GETPROJECTCHILDDETAIL:
                return {
                    ...state,
                    projectChildDetail: actions.payload
                }

            case GETDOCUMENTLISTPROJECT:
                return{
                    ...state,
                    projectDetail : {
                        ...state.projectDetail,
                        project_pipdocument_url : actions.payload?.pipDocument,
                        project_projectsheet_url :actions.payload?.projectSheet
                    }
                  
                }
        default: return state
    }
}