export const GETTENDERLIST = "GETTENDERLIST";
export const GETSTATELISTTENDER = "GETSTATELISTTENDER";
export const GETDONORLISTTENDER = "GETDONORLISTTENDER";
export const GETNOTICELISTTENDER = "GETNOTICELISTTENDER";
export const GETMODELISTTENDER = "GETMODELISTTENDER";
export const GETFINANCIALLISTTENDER = "GETFINANCIALLISTTENDER";
export const GETBIDCATEGORYLISTTENDER = "GETBIDCATEGORYLISTTENDER";
export const GETBIDFUNCTIONLISTTENDER = "GETBIDFUNCTIONLISTTENDER";
export const GETBIDSECTORLISTTENDER = "GETBIDSECTORLISTTENDER";

export const GETUSERLISTTENDER = "GETUSERLISTTENDER"
export const GETROLELISTTENDER = "GETROLELISTTENDER"
export const GETTENDERDETAIL = "GETTENDERDETAIL"