// import {useState,useEffect} from 'react'


export const GetRefreshTokenLocalStorage=(key,initValue)=>{
    if (typeof window === 'undefined') return "";

    const localValue = JSON.parse(localStorage.getItem(key));
    if (localValue) return localValue;

    // return result of a function 
    if (initValue instanceof Function) return initValue();

    return initValue
}


export const SetRefreshTokenLocalStorage=(key,initialValue)=>{
    // const [value, setValue] = useState(() => {
    //     return GetRefreshTokenLocalStorage(key, initialValue);
    // });

    // useEffect(() => {
        localStorage.setItem(key, JSON.stringify(initialValue));
    // }, [key, value])
    // return [value, setValue];
}


