import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Authaxios } from '../api/axios';
import useRefreshToken from './useRefreshToken';


const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const store = useSelector(state=>state.Login);

      useEffect(()=>{
           const requestInterceptors = Authaxios.interceptors.request.use(config=>{
               if(!config.headers['Authorization']){
                     config.headers['Authorization'] = `Bearer ${store?.access_token}`
               }
               return config
           },(error)=>Promise.reject(error))

           const responseInterceptors = Authaxios.interceptors.response.use(response=>
            response,async(error)=>{
                const prevRequest = error?.config;
                // console.log(error?.response)
                 if(error?.response?.status===403 || !prevRequest){
                    prevRequest.sent = true
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return Authaxios(prevRequest)
                }
                return Promise.reject(error);
            })

            return ()=>{
                Authaxios.interceptors.request.eject(requestInterceptors);
                Authaxios.interceptors.response.eject(responseInterceptors);
            }
      },[refresh,store])
    

  return Authaxios
}

export default useAxiosPrivate