import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from "./styles/ctrlFldsExpertise.module.css";

const UpdateExpertise = ({item,handleList}) => {  
    const [expertiseList,setExpertiseList]=useState(item);


    const handleChange=(e)=>{
        setExpertiseList({
            ...expertiseList,
            expertise_name:e.target.value})
        handleList(expertiseList,e.target.value)
    }

    return (
        <div>
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" type="text" name="expertise_name" autoComplete="off" value={expertiseList.expertise_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateExpertise
