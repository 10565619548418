import React, { useState, useEffect } from 'react';
import { convertDate, NormalDateFormat } from '../../utility/DateFunction';
import { Button, TextField } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux'
import styles from "./styles/contractManagementForm.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ToastContainer, toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { AddContractList, AddInvoicePaymentList, DeleteInvoiceList, DeleteInvoicePaymentList, FileDeleteAddContractor, FileDeleteUpdateContractor, FileUploadContractor, FileUploadContractorInvoice, FileUploadContractorUpdate, GetContractorDetailList, GetDocumentsForContractorById, GetInvoiceFileListContractor, GetInvoiceList, GetProjectListContractor, InvoiceDocDeleteContractor, UpdateContractorDetail } from '../../actions/contractor/ContrractorActions';

import 'react-toastify/dist/ReactToastify.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DeletePromptInvoice from './DeletePromptInvoice';
import DeleteInvoiceFile from './DeleteInvoiceFile';
import DeletePromptInvoicePayment from './DeletePromptInvoicePayment';

const INVOICE = ({ item, handleDeleteInvoice, index,contractStartDate }) => {
    // console.log(item,contractStartDate)
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [invoicePaymentList, setInvoicePaymentList] = useState([])
    const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv'];
    const [error, setError] = useState({})
    let [invoicePayment, setInvoicePayment] = useState({
        'invoice_payment_amount': 0,
        'invoice_payment_date': '',
        'gst_included': '',
        'invoice_file': []
    });
    const [removeFile,setRemoveFile] = useState(false);
    const [deleteDataFile,setDeleteDataFile]=useState({});

    const [removePayment,setRemovePayment] = useState(false);
    const [deleteDataPayment,setDeleteDataPayment]=useState({});

    



    useEffect(() => {
        setInvoicePayment({
            ...invoicePayment,
            invoice_file: item?.document
        })

    }, [item])

    useEffect(() => {
        let key_name = "invoice_date";
        const sorted = item?.payment_list?.slice().sort((a, b) => new Date(a[key_name]) - new Date(b[key_name])
        )
        setInvoicePaymentList(sorted)
        console.log(sorted)
    }, [item?.payment_list])


    let handleInvoicePaymentDateChange = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setInvoicePayment({
                ...invoicePayment,
                invoice_payment_date: ''
            })
        }
        else {
            setInvoicePayment({
                ...invoicePayment,
                invoice_payment_date: e
            })
        }
    }

    const handleDeleteInvoicePayment = async (item, contract_id) => {
        setRemovePayment(true);
        setDeleteDataPayment({
            "cip_id": item?.cip_id,
            "contract_id": contract_id,
            "data": item
        })

   
    }

    const ConfirmDeleteInvoicePayment =async()=>{
        let param = {
            "cip_id": deleteDataPayment?.cip_id,
            "contract_id":  deleteDataPayment?.contract_id
        }
        const data = await dispatch(DeleteInvoicePaymentList(Axios, param))
        if (data?.code === 201) {
            setRemovePayment(false);
            toast.success('Deleted Payment SuccessFully');
        }
        else {
            toast.error(`${data?.message}`)
        }
    }


    const isValidPayment = (item) => {

        let success = true;
        let error = {};

        

        if (invoicePayment?.invoice_payment_date !== null && invoicePayment?.invoice_payment_date !== undefined && invoicePayment?.invoice_payment_date?.length !== 0) {
            if (item?.invoice_date !== null && item?.invoice_date !== undefined && item?.invoice_date?.length !== 0) {
              if (!(new Date(convertDate(invoicePayment?.invoice_payment_date)) >= new Date(convertDate(item?.invoice_date)) )) {
                success = false
                error.invoice_payment_date = "Cannot be before Invoice Date!!";
              }
             }
             else if (contractStartDate !== null && contractStartDate !== undefined && contractStartDate?.length !== 0){
                if ((new Date(convertDate(contractStartDate)) > new Date(convertDate(invoicePayment?.invoice_payment_date)) )) {
                    success = false
                    error.invoice_payment_date = "Cannot be before Contract Start Date!!";
                  }
             }
    }

        let InvoicePaymentTotal = invoicePayment?.invoice_payment_amount


        if (invoicePayment?.invoice_payment_amount <= 0) {
            success = false
            error.invoice_payment_amount = "Enter a valid Payment Amount!";
        }
        else if (invoicePayment?.invoice_payment_amount?.toString()?.length > 30) {
            success = false
            error.invoice_payment_amount = "*Only 30 characters Allowed!";
        }
        else if (!/^[0-9]\d*$/.test(invoicePayment?.invoice_payment_amount)) {
            success = false
            error.invoice_payment_amount = "Please Enter a valid Number!!";
        }
        else if (Number.isInteger(parseFloat(invoicePayment?.invoice_payment_amount)) === false) {
            success = false
            error.invoice_payment_amount = "Decimal not allowed!!";
        }
        else if (parseInt(invoicePayment?.invoice_payment_amount) > parseInt(item?.invoice_amount)) {
            success = false
            error.invoice_payment_amount = "*Cannot be more than Invoice Amount!!";
        }
        item?.payment_list?.map((elem) => {
            InvoicePaymentTotal = parseInt(InvoicePaymentTotal) + parseInt(elem?.invoice_amount)
            if (parseInt(InvoicePaymentTotal) > parseInt(item?.invoice_amount)) {
                success = false
                error.invoice_payment_amount = "Sum of payments cannot be more than Invoice Amount!";
            }
        })


        if (invoicePayment?.gst_included?.length === 0) {
            success = false
            error.gst_included = "Please select GST Included!";
        }
        if (item?.payment_list?.length >= 8) {
            success = false;
            toast.error("Only 8 Payments Allowed, Add new Invoice!!!")
        }


        setError(error)
        return success
    }

    const handleInvoicePaymentSubmit = async (item) => {
        if (isValidPayment(item)) {
            let param = {
                "ci_id": item?.ci_id,
                "contract_id": item?.contract_id,
                "invoice_amount": invoicePayment?.invoice_payment_amount,
                "invoice_date": invoicePayment?.invoice_payment_date !== '' && invoicePayment?.invoice_payment_date?.toString().length !== 0 ? invoicePayment?.invoice_payment_date : null,
                "gst_included": invoicePayment?.gst_included
            }

            const data = await dispatch(AddInvoicePaymentList(Axios, param))
            if (data?.code === 201) {
                toast.success('Added Payment SuccessFully');
                setInvoicePayment({
                    "ci_id": 0,
                    "contract_id": 0,
                    "invoice_payment_amount": 0,
                    "invoice_payment_date": '',
                    "gst_included": ""
                })
            }
            else {
                toast.error(`${data?.message}`)
            }
        }

    }

    const handleInvoiceFileUpload = async (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('ci_id', item?.ci_id);
        const Result = await dispatch(FileUploadContractorInvoice(formData, item?.ci_id, Axios));
        if (Result?.code === 200) {
            setInvoicePayment({
                ...invoicePayment,
                invoice_file: Result?.list
            })
            toast.success('File Uploaded SuccessFully');
        }
        else {
            toast.error('File Upload Failed')
        }
    }

    let handleClearInvoiceDocument = async (i) => {
         setRemoveFile(true);
         setDeleteDataFile({
            filename : i.file_path,
            ci_id : i?.ci_id,
            cid_id: i?.cid_id
         })
    }

    const confirmInvoiceDocumentDelete = async()=>{
        const formData = new FormData();
        formData.append('filename', deleteDataFile?.filename);
        formData.append("ci_id", deleteDataFile?.ci_id)
        formData.append("cid_id", deleteDataFile?.cid_id);
        const Result = await dispatch(InvoiceDocDeleteContractor(formData, Axios, deleteDataFile?.ci_id));
        if (Result?.code === 200) {
            setRemoveFile(false)
            setInvoicePayment({
                ...invoicePayment,
                invoice_file: Result?.list
            })
            toast.success('Deleted Invoice Document SuccessFully');
        }
        else {
            toast.error(`${Result?.message}`)
        }
    }


    let handleInvoicePaymentChange = (e) => {
        setInvoicePayment(
            {
                ...invoicePayment,
                [e.target.name]: e.target.value
            })
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.InvoiceBox}>
                <div className={styles.InvoiceBoxHeader}>
                    <h3 className={styles.InvoiceBoxHeaderTitle}>{item.ci_code}</h3>
                    <div className={styles.InvoiceBoxHeaderContent}>
                        <label>Invoice Amount</label>
                        <h5>{item.invoice_amount}</h5>
                    </div>
                    <div className={styles.InvoiceBoxHeaderContent}>
                        <label>Invoice Date</label>
                        <h5>{
                            (item.invoice_date === null || item.invoice_date === undefined || item.invoice_date?.length === 0 || NormalDateFormat(item.invoice_date) === "01-01-1970") ? "--/--/----" : NormalDateFormat(item.invoice_date)
                        }</h5>
                    </div>
                    <div className={styles.DocInvoiceUploadWrapper}>
                        {
                            invoicePayment?.invoice_file?.length === 0 && <div className={styles.DocInvoiceUploadButton}>
                                <label htmlFor={`invoiceFileUpload${index}`}><UploadOutlinedIcon />Upload Invoice</label>
                                <input type="file" id={`invoiceFileUpload${index}`} name='invoiceFileUpload' onChange={handleInvoiceFileUpload} />
                            </div>
                        }

                        {invoicePayment?.invoice_file?.length !== 0 && invoicePayment?.invoice_file?.map(i => (
                            <div className={styles.DocInvoiceUploaded}>
                                <a href={i.file_path} download="invoiceDoc" target={allowedExtensions.includes(i?.file_path?.substring(i?.file_path?.lastIndexOf('.') + 1)?.toLowerCase()) ?
                                    "_blank" : null}
                                >{i.file_path?.substring(i?.file_path?.lastIndexOf('e/') + 2, i?.file_path?.lastIndexOf('.'))}</a>

                                <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearInvoiceDocument(i)} />
                            </div>
                        ))}

                    </div>

                    <div className={styles.InvoiceBoxHeaderContent}>
                        <DeleteIcon onClick={() => handleDeleteInvoice(item)} sx={{ cursor: "pointer" }} fontSize="large" />
                    </div>

                </div>

                <div className={styles.InvoiceBoxAddPayment}>

                    <TextField
                        name="invoice_payment_amount"
                        value={invoicePayment.invoice_payment_amount}
                        onChange={handleInvoicePaymentChange}
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CurrencyRupeeIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }}
                        label="Payment Amount excluding GST"
                        type="text"
                        error={error.invoice_payment_amount ? true : false}
                        helperText={error.invoice_payment_amount ? error.invoice_payment_amount : null}
                        autoComplete='off'
                        className={styles.PopUpInput}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            name="invoice_payment_date"
                            value={invoicePayment.invoice_payment_date !== '' ? invoicePayment.invoice_payment_date : null}
                            onChange={handleInvoicePaymentDateChange}
                            inputFormat="dd/MM/yyyy"
                            label="Payment Date"
                            renderInput={(params) => <TextField helperText={error.invoice_payment_date ? error.invoice_payment_date : null} className={styles.PopUpInput} size="small" {...params} error={false} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                        />
                    </LocalizationProvider>


                    <FormControl error={error.gst_included ? true : false} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" } }} size="small" >
                        <InputLabel >GST Included</InputLabel>
                        <Select

                            name='gst_included'
                            label="GST Included"
                            value={invoicePayment.gst_included}
                            onChange={handleInvoicePaymentChange}
                            autoComplete='off'

                            className={styles.PopUpInput}>

                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }} >{error.gst_included ? error.gst_included : null}</FormHelperText>
                    </FormControl>
                    <button className={styles.AddPaymentBttn} onClick={() => handleInvoicePaymentSubmit(item)}>Add Payment</button>
                </div>

                <div className={styles.InvoiceBoxTableWrapper} >
                    {
                        invoicePaymentList?.length !== 0 ? <table className={styles.InvoiceBoxTable}>
                            <thead className={styles.InvoiceAddTableHead}>
                                <tr className={styles.InvoiceAddTableHeadItems}>
                                    <th className={styles.InvoiceAddTableTH}>Payment Amount</th>
                                    <th className={styles.InvoiceAddTableTH}>Payment Date</th>
                                    <th className={styles.InvoiceAddTableTH}>GST Included</th>
                                    <th className={styles.InvoiceAddTableTH}>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoicePaymentList?.map((paymentItem, i) => (
                                        <tr className={i % 2 === 1 ? styles.InvoiceAddTableTR : null}>
                                            <td className={styles.InvoiceAddTableTD}>{paymentItem.invoice_amount}</td>
                                            <td className={styles.InvoiceAddTableTD}>
                                                {
                                                    (paymentItem.invoice_date === null || paymentItem.invoice_date === undefined || paymentItem.invoice_date?.length === 0 || NormalDateFormat(paymentItem.invoice_date) === "01-01-1970") ? "--/--/----" : NormalDateFormat(paymentItem.invoice_date)

                                                }</td>
                                            <td className={styles.InvoiceAddTableTD}>{paymentItem.gst_included}</td>
                                            <td className={styles.InvoiceAddTableTD}><DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDeleteInvoicePayment(paymentItem, item?.contract_id)} /></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table> : null
                    }
                </div>
            </div>

            {removeFile && <DeleteInvoiceFile remove={removeFile} setRemove={setRemoveFile} file={deleteDataFile} ToastShow={confirmInvoiceDocumentDelete}/>}
            {
                removePayment && <DeletePromptInvoicePayment remove={removePayment} setRemove={setRemovePayment} file={deleteDataPayment} ToastShow={ConfirmDeleteInvoicePayment}/>
            }
        </>
    )
}

export default INVOICE