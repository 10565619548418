import React, { useState, useEffect } from 'react';
import styles from "./styles/addChildTable.module.css";
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BsPencil } from "react-icons/bs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertDate, NormalDateFormat } from '../../utility/DateFunction';
import { AddChildProjectList, GetChildDetailProject, UpdateProjectChildDetail } from '../../actions/ProjectManagement/ProjectAction';
import { SubmitButton } from '../../utility/button/button';

function AddChildTable({ invoiceData, setInvoiceData, projectDetail, setRootError }) {
    const [error, setError] = useState({});
    const store = useSelector(state => state.Project)
    const [toggleInvoiceEdit, setToggleInvoiceEdit] = useState(false)
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()



    useEffect(() => {
        const sorted = store?.projectChildDetail?.slice().sort((a, b) => new Date(a['deliverable_due_date']) - new Date(b['deliverable_due_date']))

        setInvoiceData(sorted)
    }, [store.projectChildDetail])


    const theadData = ["Project Title",
        "Deliverable Detail",
        "Deliverable Due Date",
        "Payment Due On Deliverable",
        "Actual Submission Date",
        "Actual Invoicing Date",
        "Invoice Amount(Excluding GST)",
        "Payment Reciept Date",
        "Payment Amount(Excluding GST)",
        "Edit"
    ]
    const [childTableInput, setChildTableInput] = useState(
        {
            "pc_id": 0,
            "project_id": 0,
            "deliverable_detail": '',
            "deliverable_due_date": '',
            "deliverable_payment": 0,
            "submission_date": '',
            "invoicing_date": '',
            "invoice_amount": 0,
            "payment_receipt_date": '',
            "payment_amount": 0
        })





    const isValid = () => {
        let success = true;
        let error = {};
        let rootError = {};
        if (!childTableInput.deliverable_detail.trim()) {
            success = false
            error.deliverable_detail = "Please enter Delivery Detail!!";
        } else if (childTableInput?.deliverable_detail?.toString()?.length > 50) {
            success = false
            error.deliverable_detail = "*Only 50 characters Allowed!";
        }

        if (childTableInput.deliverable_due_date !== null && childTableInput.deliverable_due_date !== undefined && childTableInput.deliverable_due_date?.toString()?.length !== 0) {

            if (projectDetail?.performance_security_date !== null && projectDetail?.performance_security_date !== undefined && projectDetail?.performance_security_date.toString()?.length !== 0) {
              if (new Date(childTableInput.deliverable_due_date) < new Date(projectDetail?.performance_security_date)) {
                    success = false
                    error.deliverable_due_date = "Cannot be Before Performance Security Date!!";
                }
            }
            else {
                success = false
                toast.error("Performance Security Date Cannot be Empty!!")
            }

            if (projectDetail?.contract_closure_date !== null && projectDetail?.contract_closure_date !== undefined && projectDetail?.contract_closure_date?.toString()?.length !== 0) {
                if (new Date(childTableInput.deliverable_due_date) > new Date(projectDetail?.contract_closure_date)) {
                    success = false
                    error.deliverable_due_date = "Cannot be After Contract Closure Date!!";
                }
            }
            // else {
            //     success = false
            //     toast.error("Contract Closure Date Cannot be Empty!!")
            // }

        }


        if (childTableInput.submission_date !== null && childTableInput.submission_date !== undefined && childTableInput.submission_date?.toString()?.length !== 0) {

            if (childTableInput.deliverable_due_date !== null && childTableInput.deliverable_due_date !== undefined && childTableInput.deliverable_due_date?.toString()?.length !== 0) {
                if ((new Date(childTableInput.submission_date) < new Date(childTableInput.deliverable_due_date))) {
                    success = false
                    error.submission_date = "Cannot be Before Deliverable Due Date!!";
                }
            }
            else {
                success = false
                error.deliverable_due_date = "Delivery Due Date Cannot be Empty!!";
            }

            if (projectDetail?.contract_closure_date !== null && projectDetail?.contract_closure_date !== undefined && projectDetail?.contract_closure_date?.toString()?.length !== 0) {
                if (new Date(childTableInput.submission_date) > new Date(projectDetail?.contract_closure_date)) {
                    success = false
                    error.deliverable_due_date = "Cannot be After Contract Closure Date Date!!";
                }
            }
            // else {
            //     success = false
            //     toast.error("Contract Closure Date Cannot be Empty!!")
            // }

        }



        if (childTableInput.invoicing_date !==null && childTableInput.invoicing_date!==undefined && childTableInput.invoicing_date?.toString()?.length!==0) {
            if (childTableInput.submission_date !== null && childTableInput.submission_date !== undefined && childTableInput.submission_date?.toString()?.length !== 0) {
                    if (new Date(childTableInput.submission_date) > new Date(childTableInput.invoicing_date)) {
                        success = false
                        error.submission_date = "Cannot be After Invoicing Date !!";
                        error.invoicing_date = "Cannot be Before Submission Date !!";
                    }
                if (projectDetail?.contract_closure_date !== null && projectDetail?.contract_closure_date !== undefined && projectDetail?.contract_closure_date?.toString()?.length !== 0) {
                    if (new Date(childTableInput.invoicing_date) > new Date(projectDetail?.contract_closure_date)) {
                        success = false
                        error.invoicing_date = "Cannot be After Contract Closure Date Date!!";
                    }
                }
                // else {
                //     success = false
                //     toast.error("Contract Closure Date Cannot be Empty!!")
                // }

            }
            else{
                success = false
                        error.submission_date = "Actual Submission Date Cannot be blank!!";
            }

        }

        if (childTableInput.payment_receipt_date !==null && childTableInput.payment_receipt_date!==undefined && childTableInput.payment_receipt_date?.toString()?.length!==0) {
            if (childTableInput.invoicing_date !== null && childTableInput.invoicing_date !== undefined && childTableInput.invoicing_date?.toString()?.length !== 0) {
                    if (!(new Date(childTableInput.payment_receipt_date) > new Date(childTableInput.invoicing_date))) {
                        success = false
                        error.payment_receipt_date = "Cannot be Before Invoicing Date!!";
                    }
         
                if (projectDetail?.contract_closure_date !== null && projectDetail?.contract_closure_date !== undefined && projectDetail?.contract_closure_date?.toString()?.length !== 0) {
                    if (new Date(childTableInput.payment_receipt_date) > new Date(projectDetail?.contract_closure_date)) {
                        success = false
                        error.payment_receipt_date = "Cannot be After Contract Closure Date Date!!";
                    }
                }
                // else {
                //     success = false
                //     toast.error("Contract Closure Date Cannot be Empty!!")
                // }

            }
            else{
                success = false
                        error.invoicing_date = "Invoicing Date Cannot be blank!!";
            }

        }



        if (childTableInput.deliverable_payment < 0) {
            success = false
            error.deliverable_payment = "Please Enter a valid Contract Value!!";
        }
        else if (childTableInput?.deliverable_payment?.toString()?.length > 30) {
            success = false
            error.deliverable_payment = "*Only 30 characters Allowed!";
        }else if (!/^[0-9]\d*$/.test(childTableInput?.deliverable_payment)) {
            success = false
            error.deliverable_payment = "Please Enter a valid Number!!";
        }
        else if (Number.isInteger(parseInt(childTableInput.deliverable_payment)) === false) {
            success = false
            error.deliverable_payment = "Decimal not allowed!!";
        }

        if (childTableInput.invoice_amount < 0) {
            success = false
            error.invoice_amount = "Please Enter a valid Invoice Amount!!";
        }
        else if (childTableInput?.invoice_amount?.toString()?.length > 30) {
            success = false
            error.invoice_amount = "*Only 30 characters Allowed!";
        }
        else if (!/^[0-9]\d*$/.test(childTableInput?.invoice_amount)) {
            success = false
            error.invoice_amount = "Please Enter a valid Number!!";
        }
        else if (Number.isInteger(parseInt(childTableInput.invoice_amount)) === false) {
            success = false
            error.invoice_amount = "Decimal not allowed!!";
        }
        else if (!(parseInt(childTableInput?.invoice_amount) <= parseInt(childTableInput.deliverable_payment))) {
            success = false
            error.invoice_amount = "Invoice Amount cannot be more than Due on Deliverable Payment!";
        }



        if (childTableInput.payment_amount < 0) {
            success = false
            error.payment_amount = "Please Enter a valid Payment Amount!!";
        }
        else if (childTableInput?.payment_amount?.toString()?.length > 30) {
            success = false
            error.payment_amount = "*Only 30 characters Allowed!";
        }
        else if (!/^[0-9]\d*$/.test(childTableInput?.payment_amount)) {
            success = false
            error.payment_amount = "Please Enter a valid Number!!";
        }
        else if (Number.isInteger(parseInt(childTableInput.payment_amount)) === false) {
            success = false
            error.payment_amount = "Decimal not allowed!!";
        }





        setError(error)
        return success
    }

    console.log(error)

    const handleChange = (e) => {
        setChildTableInput({
            ...childTableInput,
            [e.target.name]: e.target.value
        })
    }
    // const handleSelectChange = (e) => {
    //     setChildTableInput({
    //         ...childTableInput,
    //         [e.target.name]: e.target.value
    //     })
    // }

    let handleDeliveryDueDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setChildTableInput({
                ...childTableInput,
                deliverable_due_date: ''
            })
        }
        else {
            setChildTableInput({
                ...childTableInput,
                deliverable_due_date: e
            })
        }
    }
    let handleSubmissionDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setChildTableInput({
                ...childTableInput,
                submission_date: ''
            })
        }
        else {
            setChildTableInput({
                ...childTableInput,
                submission_date: e
            })
        }
    }
    let handleInvoicingDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setChildTableInput({
                ...childTableInput,
                invoicing_date: ''
            })
        }
        else {
            setChildTableInput({
                ...childTableInput,
                invoicing_date: e
            })
        }
    }
    let handlePaymentRecieptDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
            setChildTableInput({
                ...childTableInput,
                payment_receipt_date: ''
            })
        }
        else {
            setChildTableInput({
                ...childTableInput,
                payment_receipt_date: e
            })
        }
    }





    const handleChildTableAdd = async (e) => {
        console.log(childTableInput.deliverable_due_date)
        e.preventDefault()
        let paramChildList = {
            "project_id": store?.projectDetail?.project_id,
            "deliverable_detail": childTableInput.deliverable_detail,
            "deliverable_due_date": childTableInput.deliverable_due_date !== '' && childTableInput.deliverable_due_date?.toString().length !== 0 ? childTableInput.deliverable_due_date : null,
            "deliverable_payment": childTableInput.deliverable_payment,
            "submission_date": childTableInput.submission_date !== '' && childTableInput.submission_date?.toString().length !== 0 ? childTableInput.submission_date : null,
            "invoicing_date": childTableInput.invoicing_date !== '' && childTableInput.invoicing_date?.toString().length !== 0 ? childTableInput.invoicing_date : null,
            "invoice_amount": childTableInput.invoice_amount,
            "payment_receipt_date": childTableInput.payment_receipt_date !== '' && childTableInput.payment_receipt_date?.toString().length !== 0 ? childTableInput.payment_receipt_date : null,
            "payment_amount": childTableInput.payment_amount
        }
        if (isValid()) {
            const data = await dispatch(AddChildProjectList(Axios, paramChildList))
            if (data?.code === 201) {
                toast.success('Invoice Added SuccessFully');
                setChildTableInput({
                    "pc_id": 0,
                    "project_id": 0,
                    "deliverable_detail": '',
                    "deliverable_due_date": '',
                    "deliverable_payment": 0,
                    "submission_date": '',
                    "invoicing_date": '',
                    "invoice_amount": 0,
                    "payment_receipt_date": '',
                    "payment_amount": 0
                })
                let param = {
                    "project_id": store?.projectDetail?.project_id
                }
                dispatch(GetChildDetailProject(Axios, param))
            }
            else {
                toast.error(`${data?.message}`)
            }

        }
    }
    const handleChildTableEdit = (elem) => {
        setChildTableInput(
            elem
        )
        setToggleInvoiceEdit(true)
        console.log(elem)
    }
    const handleChildTableUpdate = async (e) => {
        e.preventDefault()

        let updateChildList = {
            "pc_id": childTableInput.pc_id,
            "project_id": store?.projectDetail?.project_id,
            "deliverable_detail": childTableInput.deliverable_detail,
            "deliverable_due_date": childTableInput.deliverable_due_date !== '' && childTableInput.deliverable_due_date?.toString().length !== 0 ? childTableInput.deliverable_due_date : null,
            "deliverable_payment": childTableInput.deliverable_payment,
            "submission_date": childTableInput.submission_date !== '' && childTableInput.submission_date?.toString().length !== 0 ? childTableInput.submission_date : null,
            "invoicing_date": childTableInput.invoicing_date !== '' && childTableInput.invoicing_date?.toString().length !== 0 ? childTableInput.invoicing_date : null,
            "invoice_amount": childTableInput.invoice_amount,
            "payment_receipt_date": childTableInput.payment_receipt_date !== '' && childTableInput.payment_receipt_date?.toString().length !== 0 ? childTableInput.payment_receipt_date : null,
            "payment_amount": childTableInput.payment_amount
        }
        if (isValid()) {
  const data = await dispatch(UpdateProjectChildDetail(Axios, updateChildList))
        if (data?.code === 200) {
            toast.success('Invoice Updated SuccessFully');
            setChildTableInput({
                "pc_id": 0,
                "project_id": 0,
                "deliverable_detail": '',
                "deliverable_due_date": '',
                "deliverable_payment": 0,
                "submission_date": '',
                "invoicing_date": '',
                "invoice_amount": 0,
                "payment_receipt_date": '',
                "payment_amount": 0
            })
            setToggleInvoiceEdit(!toggleInvoiceEdit)

            let param = {
                "project_id": store?.projectDetail?.project_id
            }
            dispatch(GetChildDetailProject(Axios, param))
        }
        else {
            toast.error(`${data?.message}`)
        }


        }
      
    }
    const handleCancelUpdate = (e) => {
        e.preventDefault()
        setToggleInvoiceEdit(!toggleInvoiceEdit)
        setChildTableInput({
            "pc_id": 0,
            "project_id": 0,
            "deliverable_detail": '',
            "deliverable_due_date": '',
            "deliverable_payment": 0,
            "submission_date": '',
            "invoicing_date": '',
            "invoice_amount": 0,
            "payment_receipt_date": '',
            "payment_amount": 0
        })
    }

    // console.log(store)
    // console.log(childTableInput)
    console.log(invoiceData)
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.invoiceContainer}>

                {(invoiceData.length !== 0) ?

                    <div className={styles.AddChildTableWrapper}>
                        <table className={styles.ChildTable}>
                            <thead className={styles.ProjectsTableHead}>
                                <tr>
                                    {/* <th className={styles.ProjectsTableTH}>Sl No.</th> */}
                                    {
                                        theadData?.map((list) =>
                                            <th className={styles.ProjectsTableTH}>{list.toUpperCase()}</th>
                                        )
                                    }
                                </tr>

                            </thead>
                            <tbody>
                                {
                                    invoiceData?.map((elem, index) => {
                                        return (
                                            <tr className={index % 2 === 1 ? styles.TableBodyAltBg : null} key={index} >
                                                <td className={styles.ProjectsTableTD} ><label className={styles.ProjectsTableTD} title={store?.projectDetail?.project_title}>{store?.projectDetail?.project_title}</label> </td>
                                                <td className={styles.ProjectsTableTD}>{elem.deliverable_detail}</td>
                                                <td className={styles.ProjectsTableTD}>{elem.deliverable_due_date===null || elem.deliverable_due_date===undefined ? "--/--/----" : NormalDateFormat(elem.deliverable_due_date)}</td>
                                                <td className={styles.ProjectsTableTD}>
                                                    {parseInt(elem.deliverable_payment)?.toLocaleString('en-IN')}
                                                    </td>
                                                <td className={styles.ProjectsTableTD}>{
                                                elem.submission_date===null || elem.submission_date===undefined ? "--/--/----" : NormalDateFormat(elem.submission_date)}</td>
                                                <td className={styles.ProjectsTableTD}>{
                                                elem.invoicing_date===null || elem.invoicing_date===undefined ? "--/--/----" : NormalDateFormat(elem.invoicing_date)}</td>
                                                <td className={styles.ProjectsTableTD}>{parseInt(elem.invoice_amount)?.toLocaleString('en-IN')}</td>
                                                <td className={styles.ProjectsTableTD}>{elem.payment_receipt_date===null || elem.payment_receipt_date===undefined ? "--/--/----" : NormalDateFormat(elem.payment_receipt_date)}</td>
                                                <td className={styles.ProjectsTableTD}>{parseInt(elem.payment_amount)?.toLocaleString('en-IN')}</td>
                                                <td className={styles.ProjectsTableTD}><div className={styles.editIcon} onClick={() => handleChildTableEdit(elem)} ><BsPencil /></div></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    </div>
                    : null
                }


                <form autoComplete='off'>
                    <div className={styles.ChildTableFormWrapper}>
                        <div >
                            {
                                toggleInvoiceEdit === true ? <h2 className={styles.ChildTableTitle}>Update Deliverable / Invoice / Payment</h2> : <h2 className={styles.ChildTableTitle}>Add Deliverable / Invoice / Payment</h2>
                            }

                        </div>
                        <div className={styles.ChildTableForm}>
                            {/* <div>
                            <FormControl sx={{ minWidth: '220px', maxWidth: '220px', '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" } }} size="small">
                                <InputLabel>Project ID</InputLabel>
                                <Select
                                     error={error.project_id ? true : false}
                                     value={childTableInput.project_id}
                                    label="Project ID"
                                    onChange={handleSelectChange}
                                    name="project_id"
                                >
                                     {
                                        store?.projectList?.map((item, index) => (
                                            <MenuItem value={item.project_id}>{item.project_title}</MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText sx={{ color: "#d32f2f" }} >{error.project_id ? error.project_id : null}</FormHelperText>
                            </FormControl>

                        </div> */}

                            <div>
                                <TextField label="Deliverable Detail" variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%" }} size="small" name="deliverable_detail" value={childTableInput.deliverable_detail} onChange={handleChange} error={error.deliverable_detail ? true : false} helperText={error.deliverable_detail ? error.deliverable_detail : null} />
                            </div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                    <DatePicker
                                        name="deliverable_due_date" value={childTableInput.deliverable_due_date !== '' ? childTableInput.deliverable_due_date : null}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={handleDeliveryDueDate}
                                        label="Deliverable Due Date"
                                        renderInput={(params) => <TextField helperText={error.deliverable_due_date ? error.deliverable_due_date : null} size="small" {...params} error={false} sx={{ width: "100%", '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>

                            </div>
                            <div>
                                <TextField label="Deliverable Payment" variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%" }} size="small" name="deliverable_payment" value={childTableInput.deliverable_payment} onChange={handleChange} error={error.deliverable_payment ? true : false} helperText={error.deliverable_payment ? error.deliverable_payment : null} />
                            </div>
                            <div>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="submission_date" value={childTableInput.submission_date !== '' ? childTableInput.submission_date : null}
                                        onChange={handleSubmissionDate}
                                        inputFormat="dd/MM/yyyy"
                                        label="Actual Submission Date"
                                        renderInput={(params) => <TextField helperText={error.submission_date ? error.submission_date : null} size="small" {...params} error={false} sx={{ width: "100%", '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="invoicing_date"
                                        value={childTableInput.invoicing_date !== '' ? childTableInput.invoicing_date : null}
                                        onChange={handleInvoicingDate}
                                        inputFormat="dd/MM/yyyy"
                                        label="Invoicing Date"
                                        renderInput={(params) => <TextField helperText={error.invoicing_date ? error.invoicing_date : null} size="small" {...params} error={false} sx={{ width: "100%", '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                                <TextField label="Invoice Amount" variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", }} size="small" name="invoice_amount" value={childTableInput.invoice_amount} onChange={handleChange} error={error.invoice_amount ? true : false} helperText={error.invoice_amount ? error.invoice_amount : null} />
                            </div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="payment_receipt_date" value={childTableInput.payment_receipt_date !== '' ? childTableInput.payment_receipt_date : null} onChange={handlePaymentRecieptDate}
                                        inputFormat="dd/MM/yyyy"
                                        label="Payment Receipt Date"
                                        renderInput={(params) => <TextField helperText={error.payment_receipt_date ? error.payment_receipt_date : null} size="small" {...params} error={false} sx={{ width: "100%", '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                                <TextField label="Payment Amount" variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", }} size="small" name="payment_amount" value={childTableInput.payment_amount} onChange={handleChange} error={error.payment_amount ? true : false} helperText={error.payment_amount ? error.payment_amount : null} />
                            </div>

                        </div>
                        <div >
                            {
                                toggleInvoiceEdit === true ?
                                    <div className={styles.UpdateButtonWrapper}>


                                        <SubmitButton onClick={handleChildTableUpdate}>Update</SubmitButton>

                                        <SubmitButton onClick={handleCancelUpdate}>Cancel</SubmitButton>

                                    </div>
                                    : <SubmitButton onClick={handleChildTableAdd}>Add Invoice</SubmitButton>
                            }

                        </div>

                    </div>
                </form>
            </div>



        </>
    );
}

export default AddChildTable
