import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/projectManagementDetails.module.css";
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { convertDate, NormalDateFormat } from '../../utility/DateFunction';


function MyVerticallyCenteredModal(props) {
    const [invoiceData, setInvoiceData] = useState([])
    const store = useSelector(state => state.Project)

    useEffect(()=>{
        const sorted = store?.projectChildDetail?.slice().sort((a, b) => new Date(a['deliverable_due_date']) - new Date(b['deliverable_due_date']))

        setInvoiceData(sorted)
    },[store.projectChildDetail])

    const { toggleSet , invoiceProjectTitle } = props
   
    const theadData = ["Project Title",
        "Deliverable Detail",
        "Deliverable Due Date",
        "Payment Due On Deliverable",
        "Actual Submission Date",
        "Actual Invoicing Date",
        "Invoice Amount(Excluding GST)",
        "Payment Reciept Date",
        "Payment Amount(Excluding GST)",
    ]
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.PopUpBox}
        >
            <Modal.Header closeButton className={styles.PopUpHeader}>
                <Modal.Title className={styles.PopUpTitle}>
                    Payment Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className={styles.ChildTableWrapper}>
                    <table className={styles.ChildTable}>
                        <thead className={styles.ProjectsTableHead}>
                            <tr>
                                <th className={styles.ProjectsTableTH}>Sl No.</th>
                                {
                                    theadData?.map((list) =>
                                        <th className={styles.ProjectsTableTH}>{list.toUpperCase()}</th>
                                    )
                                }
                            </tr>

                        </thead>
                        <tbody>
                        {
                            invoiceData?.map((elem, index) => {
                                return (
                                    <tr className={index % 2 === 1 ? styles.TableBodyAltBg : null} key={index} >
                                        <td className={styles.ProjectsTableTD}>
                                        {
                                           index+1
                                        }
                                        </td>
                                        <td className={styles.ProjectsTableTD}>{invoiceProjectTitle}</td>

                                        <td className={styles.ProjectsTableTD}>{elem.deliverable_detail}</td>
                                        <td className={styles.ProjectsTableTD}>{elem.deliverable_due_date===null || elem.deliverable_due_date===undefined ? "--/--/----" : NormalDateFormat(elem.deliverable_due_date)}</td>
                                        <td className={styles.ProjectsTableTD}>{parseInt(elem.deliverable_payment)?.toLocaleString('en-IN')}</td>
                                        <td className={styles.ProjectsTableTD}>{elem.submission_date===null || elem.submission_date===undefined ? "--/--/----" : NormalDateFormat(elem.submission_date)}</td>
                                        <td className={styles.ProjectsTableTD}>{elem.invoicing_date===null || elem.invoicing_date===undefined ? "--/--/----" : NormalDateFormat(elem.invoicing_date)}</td>
                                        <td className={styles.ProjectsTableTD}>{parseInt(elem.invoice_amount)?.toLocaleString('en-IN')}</td>
                                        <td className={styles.ProjectsTableTD}>{elem.payment_receipt_date===null || elem.payment_receipt_date===undefined ? "--/--/----" : NormalDateFormat(elem.payment_receipt_date)}</td>
                                        <td className={styles.ProjectsTableTD}>{parseInt(elem.payment_amount)?.toLocaleString('en-IN')}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    </table>
                </div>
            </Modal.Body>
            <div className={styles.PopUpSubmit}>
                <button className={styles.PopUpSubmitButton} onClick={() => toggleSet(false)} >Cancel</button>
            </div>

        </Modal>
    );
}
function ChildTable(props) {
    let { toggle, toggleSet, invoiceProjectTitle } = props
    return (
        <>
            <MyVerticallyCenteredModal
                show={toggle}
                onHide={() => toggleSet(!toggle)}
                toggleSet={toggleSet}
                invoiceProjectTitle={invoiceProjectTitle}
            />
        </>
    );
}

export default ChildTable
