import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './styles/UserMgtTable.module.css';
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AdminUserStatus } from '../../actions/admin/adminAction';
import { ALLOWEDUSEREDIT, LEVEL1, SUPERADMIN } from '../../hooks/AllowedRoles';
import { UserMgtTableDate } from '../../utility/DateFunction';
import { IOSSwitch } from '../../utility/Toggle';

const UserMgtTable = ({ handleToggle }) => {
    const dispatch = useDispatch()
    const Axios = useAxiosPrivate()
    const store = useSelector(state => state.Admin);
    const LoginStore = useSelector(state => state.Login)


   

    const handleStatusChange = async (e, item) => {
        if (parseInt(LoginStore?.role?.role_code)===SUPERADMIN) {

            let param = {
                "user_id": item?.user_id,
                "status": e.target.checked

            }

            const data = await dispatch(AdminUserStatus(Axios, param));

            if (data?.code === 200) {
                toast.success('Status User SuccessFully');
            }
            else {
                toast.error(`${data.message}`)
            }
        }
        else if(LoginStore?.role?.role_code===LEVEL1){
               if(parseInt(item?.role_code)=== (LEVEL1 || SUPERADMIN)){
                toast.error("Only Super Admin Can Change the Details")
               }
               else{
                let param = {
                    "user_id": item?.user_id,
                    "status": e.target.checked
    
                }
    
                const data = await dispatch(AdminUserStatus(Axios, param));
    
                if (data?.code === 200) {
                    toast.success('Status User SuccessFully');
                }
                else {
                    toast.error(`${data.message}`)
                }
               }
        }
        // //  else if (!ALLOWEDUSEREDIT.includes(LoginStore?.role_code)) {
        // //     console.log("djadjkwa")
        // //     toast.error("Only Super Admin Can Change the Details")
        // // }
        // else {
        //     let param = {
        //         "user_id": item?.user_id,
        //         "status": e.target.checked

        //     }

        //     const data = await dispatch(AdminUserStatus(Axios, param));

        //     if (data?.code === 200) {
        //         toast.success('Status User SuccessFully');
        //     }
        //     else {
        //         toast.error(`${data.message}`)
        //     }
        // }

    }
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div>
                <div className={Styles.tableContainer}>
                    <table striped hover className={Styles.usrMgtTable}>
                        <thead className={Styles.tableHead}>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>User ID</th>
                                <th>Permission Level</th>
                                <th>Created at</th>
                                <th>Status</th>
                                <th>Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                store?.userList?.map((elem, index) =>
                                    <tr key={index} className={index % 2 === 1 ? Styles.tableRow : null}>
                                        <td>{elem.full_name}</td>
                                        <td>{elem.email}</td>
                                        <td>{elem.user_code}</td>
                                        <td><button type="button"
                                            className={
                                                elem.role_name === "LEVEL 1" ? Styles.levelButton1 : elem.role_name === "LEVEL 2" ? Styles.levelButton2 : elem.role_name === "LEVEL 3" ? Styles.levelButton3 : null}
                                        >{elem.role_name}</button></td>
                                        <td>{UserMgtTableDate(elem.created_at)}</td>
                                        <td>
                                            <div className={Styles.StatusContainer}>

                                                <IOSSwitch checked={elem.status === true || elem.status === 'true' ? true : false} onChange={(e) => handleStatusChange(e, elem)} />
                                            </div>

                                        </td>
                                        <td>
                                            <div className={Styles.StatusContainer}>
                                                <div className={Styles.editIcon} onClick={() => handleToggle(elem)}><BsPencil /></div>
                                            </div>

                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default UserMgtTable