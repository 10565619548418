import React, { useEffect, useRef, useState } from 'react'
import signInImage from "./images/SignInImage.png"
import styles from "./styles/signIn.module.css"
import PersonIcon from '@mui/icons-material/Person';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { Login } from '../../actions/login/login';
import ChangePassword from './ChangePassword';
import { LOGINUSERDATA } from '../../reduxConstants/LoginReducerConstant';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import useToggle from '../../hooks/useToggle';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorButton, LoginButton, SuccessButton } from '../../utility/button/button';
import './styles/signin.scss';
import { SIGNINALLOWED } from '../../hooks/AllowedRoles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SignIn = ({ setSignIn }) => {
    const store = useSelector(state=>state.Login);
    const [showPassword,setShowPassword] = useState(false)
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const nameref = useRef();
    const passwordref = useRef();
    const [credentials, setCredentials] = useState({ uname: "", password: "" })
    const [resultText, setResultText] = useState("Sign In");
    const [error, setError] = useState({});
    const [code, setCode] = useState('00');
    const [id, setId] = useState(0);
    const [userData, setUserData] = useState({
        "user_id": 0,
        "email": ''
    })

    const [toggle, setToggle] = useToggle('persist', false)
    const [changePass, SetChangePass] = useState(false);
    const [forgotPass, setForgotPass] = useState(false)


    useEffect(() => {
        if (!changePass) {
            nameref.current.focus()
        }

    }, [])

    const handleLoginChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        })
    }

    const IsValid = () => {
        let success = true;
        let error = {};


        if (!credentials.uname.trim()) {
            error.uname = "Email cannot be empty";
            success = false
        }

        if (!credentials.password.trim()) {
            error.password = "Password cannot be empty";
            success = false
        }

        setError(error)
        return success
    }

 


    const handleSignIn = async (e) => {
        e.preventDefault()
        if (IsValid()) {
            setLoader(true)
            let param = {
                "email": credentials.uname.replace(/\s+/g, ' ').trim(),
                "password": credentials.password.replace(/\s+/g, ' ').trim()
            }
            const data = await dispatch(Login(param))

            if (data?.code === 200) {

                if (data?.first_time) {
                    setTimeout(() => {
                        setLoader(false);
                        setSuccess(true)
                    }, [500])
                    setTimeout(() => {
                        SetChangePass(true);
                        setSuccess(prev => {
                            return !prev
                        })
                    }, [1000])

                    setId(data?.user_id)
                }
                else {
                    dispatch({ type: LOGINUSERDATA, payload: data })
                    
                    setTimeout(() => {
                        setLoader(false);
                        setSuccess(true)
                    }, [500])

                    setTimeout(() => {
                        const from = location.state?.from?.pathname!==undefined && location.state?.from?.pathname!==null? location.state?.from?.pathname : (SIGNINALLOWED?.includes(data?.role?.role_code)? '/UserManagement' : "/Catalogue/Page:1") ;
                        // console.log(from)
                        navigate(from, { replace: true });
                        setSuccess(prev => {
                            return !prev
                        })
                    }, [1000])  

                }

            }
            else {
                setTimeout(() => {
                    setFail(true)
                    setError({
                        error: data?.message
                    })
                    setLoader(false)
                }, [500])
             
            }
        }
    }


    const handleFocus=()=>{
        setSuccess(false);
        setLoader(false);
        setError({});
        setFail(false)
    }

    return (
        <>
            <div className="SignInRootContainer">



                <div className={styles.SignInWrapper}>
                    <div className={styles.SignInImageWrapper}>
                        <img className={styles.SignInImage} src={signInImage} alt="Not Found" />
                    </div>
                    {
                        forgotPass ? (<ForgotPassword setCredentials={setCredentials} setId={setId} SetChangePass={SetChangePass} setForgotPass={setForgotPass} />) : (
                            changePass ? <ChangePassword email={credentials?.uname} user_id={id} /> : (
                                <>
                                    <div className={styles.SignInFormWrapper}>
                                        <form action="" onSubmit={handleSignIn}>
                                            <div className={styles.Container}>
                                                {error.error && <h2 className={styles.ErrorHeading}>{error.error}</h2>}

                                                <div className={styles.SignInForm}>
                                                    <h2 className={styles.SignInFormTitle}>Sign In</h2>
                                                    <div>
                                                        <div className={styles.SignInFormUname}>
                                                            <PersonIcon />
                                                            <input ref={nameref} className={styles.SignInFormInput} name="uname" onChange={handleLoginChange} type="text" placeholder='User Name' autoComplete='none'
                                                               onFocus={handleFocus} />
                                                        </div>
                                                        {
                                                            error.uname && <span className={styles.ErrorMessage}><ErrorIcon className={styles.ErrorIcon} />{error.uname}</span>
                                                        }
                                                    </div>

                                                    <div className={styles.SignInFormPass}>
                                                        <LockOutlinedIcon />
                                                        <input ref={passwordref} className={styles.SignInFormInput} name="password" onChange={handleLoginChange} type={showPassword? "text" : "password"} placeholder='Password'
                                                            onFocus={handleFocus} />
                                                            {showPassword ? <VisibilityIcon className={styles.visible} onClick={()=>setShowPassword(!showPassword)}/> : <VisibilityOffIcon className={styles.visible} onClick={()=>setShowPassword(!showPassword)}/>}
                                                    </div>
                                                    {
                                                        error.password && <span className={styles.ErrorMessage}><ErrorIcon className={styles.ErrorIcon} />{error.password}</span>
                                                    }
                                                    <div className={styles.SignInFormRememberMe}>
                                                        <input className={styles.SignInFormRmbrMeChkBx} type="checkbox" checked={toggle} onChange={setToggle} />

                                                        <h6 className={styles.SignInFormRmbrMeTxt}>Remember Me</h6>
                                                    </div>
                                                    <div className={styles.SignInFormSignWrapper}>
                                                        {
                                                            success ? (<SuccessButton>Success</SuccessButton>) : (fail ? (<ErrorButton>UnAuthorized</ErrorButton>) : (<LoginButton type='submit' loading={loader} >Submit</LoginButton>))
                                                        }

                                                    </div>
                                                    <div className={styles.SignInFormForgPass}>
                                                        <label className={styles.SignInFormForgPassLink} onClick={() => setForgotPass(true)}>Forgot Password ?</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            )
                        )
                    }

                </div>
            </div>

        </>
    )
}

export default SignIn