import { SetRefreshTokenLocalStorage } from "../../hooks/LoginLocalStorage"; 
import { Axios } from "../../api/axios";;



export const Login=(param)=>async(dispatch)=>{
      
    try {
           const {data} = await Axios.post('/login',param);
            console.log(data,"login")
           if(data?.code===200){
                 
               if(!data?.first_time){
                  SetRefreshTokenLocalStorage('jwt',data?.refresh_token);
               } 
                return data
           }
          
    } catch (error) {
         return error?.response?.data
          
    }
}



export const ResetPassword=(param)=>async(dispatch)=>{
      
   try {
          const {data} = await Axios.post('/reset_password',param);

          if(data?.code===200){
               SetRefreshTokenLocalStorage('jwt',data?.refresh_token);
               return data
          }
         
   } catch (error) {
         return error?.response?.data      
   }
}


export const SendEmail=(param)=>async(dispatch)=>{
      
   try {
          const {data} = await Axios.post('/forgot_password',param);
          if(data?.code===200){
               return data
          }
         
   } catch (error) {
         return error?.response?.data
         
   }
}


export const ValidateOtp=(param)=>async(dispatch)=>{
      
   try {
          const {data} = await Axios.post('/validate_otp',param);
          //console.log(data,"otp")
          if(data?.code===200){
               return data
          }
         
   } catch (error) {
      return error?.response?.data
         
   }
}