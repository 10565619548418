import React, { useState,useEffect } from 'react'
import styles from "./styles/ctrlFldsBidFnc.module.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddBidFunction from './AddBidFunction';
import { useDispatch, useSelector } from 'react-redux'
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateBidFunction from './UpdateBidFunction';
import { GetBidFunctionList, UpdateBidFunctionApi } from '../../../actions/controlFields/controlFieldsActions';
import { Card } from '@mui/material';
import { CancelButton, CtrlFldsSubmitButton } from '../../../utility/button/button';

const CtrlFldsBidFnc = () => {
    const store = useSelector(state => state.ControlFields);
    const [BidFunctionList, setBidFunctionList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
      dispatch(GetBidFunctionList(Axios))
    }, [])

    useEffect(
        ()=>{
        setBidFunctionList(store?.BidFunctionList)
    },[store.BidFunctionList])

    let toggleEdit = () => {
        setShow(!show)
    }
    const handleList = (bf, value) => {
        setBidFunctionList(BidFunctionList.map(item => item.bf_id === bf.bf_id ? {
            ...bf,
            bf_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setBidFunctionList(store?.BidFunctionList)
    }
    const IsValid = () => {
        let success = true;
        const data = BidFunctionList.map(item => ((item?.bf_name === '') || item?.bf_name?.length === 0 || item?.bf_name === undefined || item?.bf_name === null) ? true : false);

        if (data.includes(true)) {
            success = false;
            toast.error("Bid Function Fields Cannot Be Empty!!")
        } else {
            success = true
        }
        return success
    }

    const handleSubmit = async () => {
        if (IsValid()) {
            setLoading(true)
        let param = {
            "list": BidFunctionList
        }

        const data = await dispatch(UpdateBidFunctionApi(Axios, param));

        if (data?.code === 200) {
            setLoading(false)
            setShow(false)
            toast.success('Updated Bid Function SuccessFully')
        }
        else {
            setLoading(false)
            toast.error(`${data?.message}`)
        }
    }
    }
    return (
        <>
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.CtrlFields}>
            {/* <div className={styles.CtrlFldTitle}>Admin Control Fields</div> */}
            <div className={styles.CtrlFldsBodyContainer}>
                <div className={styles.CtrlFldsBodyContent}>
                    <div className={styles.CtrlFldsBodyTitle}>
                        <h2>Bid Functions</h2>
                    </div>
                    <AddBidFunction/>
                    <div className={styles.Seperator} >
                        <div className={styles.SeperatorLine}></div>
                        <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                    </div>
                    <div className={styles.CtrlFldsBody}>
                        <div className={styles.CtrlFldsBodyItems}>
                            {
                                BidFunctionList?.map(
                                    (item, key) =>
                                    (
                                        show ?
                                            <UpdateBidFunction item={item} handleList={handleList}/> :
                                            <Card className={styles.listCard}>{item.bf_name}</Card>
                                           
                                    )
                                )
                            }

                        </div>
                        {
                            show === true ?
                                <div className={styles.CtrlFldsSubmitEdit}>
                                      <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                </div> : null
                        }

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}


export default CtrlFldsBidFnc