import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './styles/DeletePromptContractFile.module.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton, SubmitButton } from '../../utility/button/button';
import { DeleteProject } from '../../actions/ProjectManagement/ProjectAction';


function MyVerticallyCenteredModal(props) {
    const [folderText, setFolderText] = useState('')
    const { setRemove,file,ToastShow } = props

    let handleDelete = async () => {
        ToastShow()
    }
    return (
        <>
           
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.PopUpBox}
            >
                <Modal.Header closeButton className={styles.PopUpHeader}>
                    <Modal.Title className={styles.PopUpTitle}>
                       {`Are You Sure to delete`} 
                    </Modal.Title>
                    <Modal.Title className={styles.PopUpTitle}>
                       {`invoice payment`} 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={styles.addFolderInputWrapper}>
{/*                          
                                <div style={{display:"flex",flexDirection:"column"}}>
                                <label>{` ${file?.filename?.substring(file?.filename?.lastIndexOf('/') + 1)}?`}</label>
                            
                                </div> */}
                         
                    </div>
                </Modal.Body>
                <div className={styles.PopUpSubmit}>
                    <SubmitButton onClick={handleDelete}>Delete</SubmitButton>
                    <CancelButton onClick={()=>setRemove(false)}>Cancel</CancelButton>
                </div>
            </Modal></>
    );
}
function DeletePromptInvoicePayment(props) {
    let { remove, setRemove,file,ToastShow} = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={remove}
                onHide={() => setRemove(!remove)}
                setRemove={setRemove}
                file={file}
                ToastShow={ToastShow}
            />
        </>
    );
}

export default DeletePromptInvoicePayment