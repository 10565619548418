import React, { useEffect, useState } from 'react'
import styles from './styles/catalougue.module.css';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import CatalougueColumnModal from './CatalougueColumnModal';
import { columnData, SelectALLData } from "./data";
import TenderTable from './TenderTable';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { GetTenderList } from '../../actions/Tender/tenderAction';
import { NormalDateFormat } from '../../utility/DateFunction';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import exportFromJSON from "export-from-json"
import { OPPORTUNITYEXPORTALLOWED } from '../../hooks/AllowedRoles';
import UpdateTender from './UpdateTender';
import { TextField } from '@mui/material';

const SearchFilter = (data, columns, q) => {
    if (data !== undefined && data !== null && data.length !== 0) {
        return data.filter((row) =>
            columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
    }
}

const Catalougue = () => {
    const navigate = useNavigate()
    const LoginStore = useSelector(state => state.Login)
    // console.log(LoginStore);
    const store = useSelector(state => state.Tender)
    const Axios = useAxiosPrivate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [state, setState] = useState(columnData)
    const [currentPage, setCurrentPage] = useState(parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf(':') + 1)))
    const [postsPerPage] = useState(10);
    let [show, setShow] = useState(false)
    const [tenderList, setTenderList] = useState([])
    const [searchText, setSearchText] = useState("")
    const IndexOfLastPost = currentPage * postsPerPage
    const IndexOfFirstPost = IndexOfLastPost - postsPerPage;


    const pageNumbers = Math.ceil(tenderList.length / postsPerPage)
    const currentPosts = SearchFilter(tenderList, ['bid_title', 'tender_code'], searchText)?.slice(IndexOfFirstPost, IndexOfLastPost);


    let [columns, setColumns] = useState(["tender_code", "bid_title", "submission_date_time", "selected_for_bidding", "bid_task_deadline", "nominated_user_name"]);
    const [sort, setSort] = useState('bid_published_date');
    const [selectAll, setSelectAll] = useState(false)

    // console.log(location?.pathname?.substring(0,location?.pathname?.lastIndexOf(':')+1)) 
    // let currentPageURL = location?.pathname?.substring(0,location?.pathname?.lastIndexOf(':')+1) + currentPage;
 
    useEffect(()=>{
        let Page = location?.pathname?.substring(location?.pathname?.lastIndexOf('Page:'));
        let PageNumber = Page?.substring(Page?.lastIndexOf(':')+1);
        console.log(PageNumber)
        setCurrentPage(parseInt(PageNumber))
    },[location])

    let arr = columns;
    useEffect(() => {
        let ObjectKeys = Object.keys(columnData);
        let CheckArr = []
        ObjectKeys.map((item, index) => {
            if (columnData[item]) {
                if (arr.includes(item)) {
                    CheckArr = [...CheckArr, item]
                    setColumns(
                        CheckArr
                    )
                }
                else {
                    arr.push(item);
                    setColumns(arr)
                }
            }
        }
        )
        // navigate(currentPageURL)
    }, [])
    //     console.log( currentPageURL)
    const handleSelectAll = (e) => {
        setSelectAll(!selectAll);
        if (e.target.checked) {
            setColumns(Object.keys(state));
            setState(SelectALLData)
        }
        else {
            setColumns(["tender_code", "bid_title", "submission_date_time", "selected_for_bidding", "bid_task_deadline", "nominated_user_name"])
            setState(columnData)
        }
    }
    const handleNavigate = () => {
        navigate('/AddTender')
    }
    const ExportToExcel = () => {
        if (OPPORTUNITYEXPORTALLOWED.includes(LoginStore?.role?.role_code)) {
            const data = store?.tenderList
            const fileName = 'tenderList'
            const exportType = exportFromJSON.types.csv
            exportFromJSON({ data, fileName, exportType })
        }
        else {
            toast.error("No Access to Download")
        }
    }
    let toggleColumn = () => {
        setShow(!show)
    }
    useEffect(() => {
        dispatch(GetTenderList(Axios))
    }, [])

    useEffect(() => {
        let key_name = "bid_published_date";
        const sorted = store?.tenderList?.slice().sort((a, b) => key_name.endsWith("date") || key_name.endsWith("time") ? new Date(a[key_name]) - new Date(b[key_name]) : a[key_name] - b[key_name]
        )
        setTenderList(sorted)

    }, [store])

    //let arr = columns;
    // useEffect(() => {
    //     let ObjectKeys = Object.keys(columnData);
    //     ObjectKeys.map((item, index) => {
    //         console.log(columnData[item], index)
    //         if (columnData[item]) {
    //             if (arr.includes(item)) {
    //                 let Arrindex = arr.indexOf(item);
    //                 setColumns(prev => {
    //                     return prev.filter((item, i) => i !== Arrindex)
    //                 })
    //             }
    //             else {
    //                 arr.push(item);
    //                 setColumns(arr)
    //             }
    //         }
    //     })
    // }, [])


   

    const handleSearch = (e) => {
        setSearchText(e.target.value)
        console.log(navigate('/Catalogue/Page:1'))
        navigate('/Catalogue/Page:1')
       // setTenderList(SearchFilter(tenderList, ['bid_title', 'tender_code'], e.target.value))
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        let path = location?.pathname.substring(0, location?.pathname.indexOf(':'));
        navigate(path + `:${value}`)
    };

    const handleSort = (e) => {
        let key_name = e.target.value;
        if (key_name?.toLowerCase() === "selected_for_bidding") {
            let sorted = [];;
            let Yes = [];
            let No = [];
            for (let i = 0; i < tenderList.length; i++) {
                if (tenderList[i]?.selected_for_bidding?.toLowerCase() === 'yes') {
                    Yes.push(tenderList[i])
                }
                else {
                    No.push(tenderList[i])
                }
            }
            sorted = [...Yes, ...No];
            console.log(sorted)
            sorted = sorted.flat();
            setTenderList(sorted)
        }
        else {
            const sorted = tenderList.slice().sort((a, b) =>
                key_name.endsWith("date") || key_name.endsWith("time") ? new Date(a[e.target.value]) - new Date(b[e.target.value]) : a[e.target.value] - b[e.target.value]
            )
            setTenderList(sorted)
        }
    }

    // console.log(store)

    return (
        <>
          <ToastContainer
                    position="bottom-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            <div className={styles.rootContainer}>

                <h2 className={styles.OppBidTitle}>Opportunity & Bidding Index</h2>

                <div className={styles.filterWrapper}>
                    <div className={styles.filterOptionsWrapper}>
                        <div className={styles.fltrOpnsColumn} onClick={toggleColumn}>
                            <TableChartOutlinedIcon color='success' />
                            <label>Column</label>
                        </div>{
                            OPPORTUNITYEXPORTALLOWED.includes(LoginStore?.role?.role_code) ?  
                             <div className={styles.fltrOpnsColumn} onClick={ExportToExcel} >
                            <IosShareOutlinedIcon />
                            <p>Export</p>
                        </div> : null
                        }
                    </div>
                    <div className={styles.ButtonWrapper}>
                        <button type="submit" className={styles.AddTenderButton} onClick={handleNavigate}>Add Tender</button>
                    </div>
                    <div className={styles.searchWrapper}>
                        <TextField variant="outlined" label="Tender Code/Title" className={styles.searchInputField} sx={{ minWidth: "120px", maxWidth: '170px' }} size="small" name="searchList" autoComplete='off' onChange={handleSearch} value={searchText} />
                    </div>


                    <div className={styles.fltrOpnsColumnRightWrapper}>
                        <Pagination shape="rounded" count={pageNumbers} page={currentPage} onChange={handlePageChange} className={styles.tablePagination} />
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={styles.sortTableButtonWrapper}>
                            <InputLabel >Sort By</InputLabel>
                            <Select className={styles.sortTableButton}
                                label="Sort By"
                                defaultValue={sort}
                                onChange={handleSort}
                            >
                                <MenuItem className={styles.sortTableButtonList} value="bid_published_date">Bid Published Date</MenuItem>
                                <MenuItem className={styles.sortTableButtonList} value="bid_tracking_date">Bid Tracking Date</MenuItem>
                                <MenuItem className={styles.sortTableButtonList} value="submission_date_time">Submission Date</MenuItem>
                                <MenuItem className={styles.sortTableButtonList} value="selected_for_bidding">Selected for Bidding</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                    </div>
                </div>

                <div className={styles.CatalougueTableWrapper}>
                    <TenderTable columns={columns} currentPosts={currentPosts} currentPage={currentPage} postsPerPage={postsPerPage} />
                </div>
                {/* <UpdateTender/> */}
                {show === true ? <CatalougueColumnModal toggle={show} toggleSet={setShow} columns={columns} setColumns={setColumns} state={state} setState={setState} selectAll={selectAll} handleSelectAll={handleSelectAll} /> : null}

            </div>
        </>
    )
}

export default Catalougue