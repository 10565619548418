import React, { useRef, useState } from 'react'
import styles from "./styles/changePass.module.css"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ResetPassword } from '../../actions/login/login';
import { useDispatch } from 'react-redux';
import { LOGINUSERDATA } from '../../reduxConstants/LoginReducerConstant';
import { ErrorButton, LoginButton, SuccessButton } from '../../utility/button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles/changePass.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const ChangePassword = ({ email, user_id }) => {
    const passRef = useRef();
    const conRef = useRef();
    const [showPassword,setShowPassword] = useState(false)
    const [showPasswordre,setShowPasswordre] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [passwordLoader, setPasswordLoader] = useState(false)
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false)
    const [newPassword, setNewPassword] = useState({ newPass: "", reTypeNewPass: "" });
    const [error, setNewError] = useState({})

    const handlePasswordChange = (e) => {
        newPassword[e.target.name] = e.target.value
    }

    const IsValid = () => {
        let success = true;
        let error = {};

        if (!newPassword.newPass.trim()) {
            error.newpass = "**Please type a new password**"
            success = false
        }

        if (!newPassword.reTypeNewPass.trim()) {
            error.reTypeNewPass = "**Please Re-type a new password**"
            success = false
        }
        else if (newPassword.reTypeNewPass !== newPassword.newPass) {
            error.reTypeNewPass = "**Your Password Doesnot Match!!**"
            success = false
        }

        setNewError(error)
        return success
    }

    const handleChangePassword = async (e) => {

        e.preventDefault()
        console.log("dubuk");

        if (IsValid()) {
            setPasswordLoader(true)
            let param = {
                "email": `${email}`,
                "user_id": `${user_id}`,
                "password": `${newPassword.newPass}`
            }

            const data = await dispatch(ResetPassword(param));
            if (data?.code === 200) {
                setTimeout(() => {
                    setSuccess(true);
                }, [500])

                setTimeout(() => {
                    setPasswordLoader(false);
                    setSuccess(prev => {
                        return !prev
                    })
                    // setSignIn(true)
                    navigate(from, { replace: true });
                }, [1000])
                dispatch({ type: LOGINUSERDATA, payload: data })
            }
            else {
                setFail(true)
                setNewError({
                    passError: data?.message
                })
                setPasswordLoader(false)
            }
        }
    }


    const handleFocus = () => {
        setSuccess(false)
        setNewError({})
        setPasswordLoader(false);
        setFail(false)
    }


    return (
        <div className='ChangePassWordRootContainer'>

            <div className={styles.ChangePassWrapper}>
                <form onSubmit={handleChangePassword} >
                    <div className={styles.Container}>
                        {error.passError && <h2 className={styles.ErrorHeading}>{error.passError}</h2>}

                        <div className={styles.ChangePassForm}>
                            <h2 className={styles.ChangePassFormTitle}>Change Password</h2>
                            <div>
                                <div className={styles.ChangePassPassword}>
                                    <LockOutlinedIcon />
                                    <input ref={passRef} className={styles.ChangePassFormInput} name="newPass" onChange={handlePasswordChange} type={showPassword? "text" : "password"} placeholder='Password' autoComplete='none' onFocus={handleFocus} />
                                    {showPassword ? <VisibilityIcon className={styles.visible} onClick={()=>setShowPassword(!showPassword)}/> : <VisibilityOffIcon className={styles.visible} onClick={()=>setShowPassword(!showPassword)}/>}
                                </div>
                                {error?.newpass && <span className={styles.Error}>{error?.newpass}</span>}
                            </div>

                            <div>
                                <div className={styles.ChangePassRePass}>
                                    <LockOutlinedIcon />
                                    <input ref={conRef} className={styles.ChangePassFormInput} name="reTypeNewPass" onChange={handlePasswordChange} type={showPasswordre? "text" : "password"} placeholder='Re-Type PassWord' autoComplete='none' onFocus={handleFocus} />
                                    {showPasswordre ? <VisibilityIcon className={styles.visible} onClick={()=>setShowPasswordre(!showPasswordre)}/> : <VisibilityOffIcon className={styles.visible} onClick={()=>setShowPasswordre(!showPasswordre)}/>}
                                </div>
                                {error?.reTypeNewPass && <span className={styles.Error}>{error?.reTypeNewPass}</span>}
                            </div>



                            <div className={styles.ChangePassSignInButtonWrapper}>
                                {
                                    success ? (<SuccessButton>Success</SuccessButton>) : (fail ? (<ErrorButton>UnAuthorized</ErrorButton>) : (<LoginButton type='submit' loading={passwordLoader} >Submit</LoginButton>))
                                }


                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default ChangePassword