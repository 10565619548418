import { TextField } from '@mui/material';
import React,{useState,useEffect} from 'react';
import styles from './styles/ctrlFldsDonor.module.css'

const UpdateDonor = ({item,handleList}) => {

    const [donorName,setDonorName]=useState(item);


    const handleChange=(e)=>{
        setDonorName({
            ...donorName,
            donor_name:e.target.value})
        handleList(donorName,e.target.value)
    }

    return (
        <div>
            {/* <input  className={styles.CtrlFldsItemInput} type="text" name="donor_name" value={donorName.donor_name} onChange={handleChange}/> */}
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" name="donor_name" autoComplete="off" value={donorName.donor_name} onChange={handleChange}/>       
        </div>
    )
}

export default UpdateDonor