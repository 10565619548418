import React, { useEffect, useState } from 'react'
import styles from './styles/documentsDownloads.module.css'
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { GetContractorDownloadList} from '../../actions/downloads/DownloadActions';
import ContractorCard from './ContractorCard';


const SearchFilter = (data, columns, q) => {
  if (data !== undefined && data !== null && data.length !== 0) {
    return data.filter((row) =>
      columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
  }
}

const ContractorDownloads = ({ searchText }) => {
 const [ContractorDownloadList,setContractorDownloadList] = useState([])
  let store = useSelector(state => state.Downloads);
  let dispatch = useDispatch()
  let Axios = useAxiosPrivate()
  let ContractorDownloadListSearched = SearchFilter(ContractorDownloadList, ['contract_code'], searchText)

  useEffect(() => {
    dispatch(GetContractorDownloadList(Axios))
  }, [])
  useEffect(()=>{
    setContractorDownloadList(store?.contractorDownloadList ? store?.contractorDownloadList : null)
  },[store?.contractorDownloadList])


  return (
    <div className={styles.rootContainer}>
      {ContractorDownloadListSearched?.map((elem, index) => {
        return <div className={styles.ListWrapper}>
          <div className={index % 2 === 0 ? styles.ListTitle : styles.ListTitleAlt}>
            <label>
              {elem?.contract_code}
            </label>
          </div>
          <div className={index % 2 === 0 ? styles.ListBody : styles.ListBodyAlt}>
          <ContractorCard contract_id = {elem?.contract_id}/>
          </div>
        </div>
      })}
    </div>
  )
}
export default ContractorDownloads
