import React, { useState, useEffect } from 'react'
import styles from "./styles/ctrlFldsStates.module.css";
import { BsPencil } from "react-icons/bs";
import AddStates from './AddStates';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { GetControlFieldStateList, UpdateStateControlField } from '../../../actions/controlFields/controlFieldsActions';
import UpdateState from './UpdateState';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '@mui/material/Card';
import { CancelButton, CtrlFldsSubmitButton, LoginButton, SuccessButton } from '../../../utility/button/button';

const CtrlFldsStates = () => {
    const store = useSelector(state => state.ControlFields);
    const [stateList, setStateList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        dispatch(GetControlFieldStateList(Axios))
    }, [])


    useEffect(() => {
        setStateList(store?.stateList)
    }, [store.stateList])


    let toggleEdit = () => {
        setShow(!show)
    }

    const handleList = (state, value) => {
        //.log(state)
        setStateList(stateList.map(item => item.state_id === state.state_id ? {
            ...state,
            state_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setStateList(store?.stateList)
    }

     const IsValid=()=>{
          let success = true;
          const data = stateList.map(item=> ((item?.state_name ==='') ||  item?.state_name?.length===0 || item?.state_name===undefined || item?.state_name===null) ? true : false);
           
          if(data.includes(true)){
            success = false;
            toast.error("State Fields Cannot Be Empty!!")
          }else{
            success = true
          } 
          return success
     }

    const handleSubmit = async () => {
        if(IsValid()){
            setLoading(true)
  
        let param = {
            "list": stateList
        }

        const data = await dispatch(UpdateStateControlField(Axios, param));

        if (data?.code === 200) {
            setLoading(false)
            setShow(false)
            toast.success('Updated States SuccessFully')
        }
        else {
            setLoading(false)
            toast.error(`${data?.message}`)
        }
        }
    }

    return (
        <>
        <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.CtrlFields}>
           
            <div className={styles.CtrlFldsBodyContainer}>
                <div className={styles.CtrlFldsBodyContent}>
                    <div className={styles.CtrlFldsBodyTitle}>
                        <h2>State/UT</h2>
                    </div>
                    <AddStates />
                    <div className={styles.Seperator} >
                        <div className={styles.SeperatorLine}></div>
                        <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                    </div>
                    <div className={styles.CtrlFldsBody}>
                        <div className={styles.CtrlFldsBodyItems}>
                            {
                                stateList?.map(
                                    (item, key) =>
                                    (

                                        show ? <UpdateState item={item} handleList={handleList} /> :
                                        <>
                                       
                                            <Card className={styles.listCard}>{item.state_name}</Card>
                                        </>
                                            

                                    )
                                )
                            }

                        </div>
                        {
                            show === true ?
                                <div className={styles.CtrlFldsSubmitEdit}>
                                    <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                </div> : null
                        }

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default CtrlFldsStates