import { TextField } from '@mui/material';
import React,{useState} from 'react'
import styles from './styles/ctrlFldsFinYear.module.css'

const UpdateFinancialYear = ({item,handleList}) => {  
    const [financialYear,setFinancialYear]=useState(item);


    const handleChange=(e)=>{
        setFinancialYear({
            ...financialYear,
            fy_name:e.target.value})
        handleList(financialYear,e.target.value)
    }

    return (
        <div>

            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" type="text" autoComplete="off" name="fy_name" value={financialYear.fy_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateFinancialYear
