
import React, { useState, useEffect } from 'react'
import styles from "./styles/contractManagementForm.module.css"
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CntrtMgtAddInvoiceModal from './CntrtMgtAddInvoiceModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom'
import FormHelperText from '@mui/material/FormHelperText';
import { SubmitButton } from "../../utility/button/button"
import ClearIcon from '@mui/icons-material/Clear';
import Select from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddContractList, AddInvoicePaymentList, DeleteInvoiceList, DeleteInvoicePaymentList, FileDeleteAddContractor, FileDeleteUpdateContractor, FileUploadContractor, FileUploadContractorInvoice, FileUploadContractorUpdate, GetContractorDetailList, GetDocumentsForContractorById, GetInvoiceFileListContractor, GetInvoiceList, GetProjectListContractor, InvoiceDocDeleteContractor, UpdateContractorDetail } from '../../actions/contractor/ContrractorActions';
import INVOICE from './Invoice';
import DeletePromptContractFile from './DeletePromptContractFile';
import DeletePromptInvoice from './DeletePromptInvoice';






const UpdateContractManagement = () => {
    const navigate = useNavigate()
    let [show, setShow] = useState(false)
    const store = useSelector(state => state.Contractor);
    const location = useLocation();
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']
    const [error, setError] = useState({});
    let [invoiceList, setInvoiceList] = useState([])
    let [formInput, setFormInput] = useState({
        "project_code": "",
        "project_id": 0,
        "email": "",
        "contractor_name": "",
        "pan_no": "",
        "gst_no": "",
        "cell_no": 0,
        "contract_start_date": '',
        "contract_end_date": '',
        "contract_value": 0,
        "pan": [{
            "type": "pan",
            "cd_id": 0,
            "contract_id": 0,
            "file_path": "",
            "created_at": "",
            "path": ""
        }],
        "gst": [{
            "cd_id": 0,
            "contract_id": 0,
            "file_path": "",
            "created_at": "",
            "type": "gst",
            "path": ""
        }],
        "contract": [{
            "cd_id": 0,
            "contract_id": 0,
            "type": "contract",
            "file_path": "",
            "created_at": "",
            "path": ""
        }],

    })
    const [removeFile,setRemoveFile]= useState(false);
    const [deleteDataFile,setDeleteDataFile]=useState({});
    const [removeInvoice,setRemoveInvoice] = useState(false);
    const [deleteDataInvoice,setDeleteDataInvoice] = useState({})

    useEffect(() => {
        let param = {
            "contract_id": parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1))
        }

        dispatch(GetProjectListContractor(Axios))
        dispatch(GetContractorDetailList(Axios, param))
        dispatch(GetInvoiceList(Axios, param))
        // dispatch(GetInvoicePaymentList(Axios,invoiceParam))
    }, [])

    useEffect(() => {
        setFormInput(store?.contractorDetailList ? store.contractorDetailList : {
            "project_code": "",
            "project_id": 0,
            "email": "",
            "contractor_name": "",
            "pan_no": "",
            "gst_no": "",
            "cell_no": 0,
            "contract_start_date": '',
            "contract_end_date": '',
            "contract_value": 0,
            "pan": [],
            "gst": [],
            "contract": [],

        });
        setInvoiceList(store?.contractorDetailList?.invoices_list)
    }, [store?.contractorDetailList])

    useEffect(() => {
        setInvoiceList(store?.invoiceList ? store?.invoiceList : [])
    }, [store?.invoiceList])

    let toggleInvoice = () => {
        setShow(!show)
    }

   

    let handleChange = (e) => {
        setFormInput(
            {
                ...formInput,
                [e.target.name]: e.target.value
            })
    }
    let handlePANChange = (e) => {
        setFormInput(
            {
                ...formInput,
                "pan_no": e.target.value.toUpperCase()
            })
    }
    let handleGSTChange = (e) => {
        setFormInput(
            {
                ...formInput,
                "gst_no": e.target.value.toUpperCase()
            })
    }
    let handleContractStartDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
          setFormInput({
            ...formInput,
            contract_start_date: ''
          })
        }
        else {
          setFormInput({
            ...formInput,
            contract_start_date: e
          })
        }
      }
      let handleContractEndDate = (e) => {
        if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
          setFormInput({
            ...formInput,
            contract_end_date: ''
          })
        }
        else {
          setFormInput({
            ...formInput,
            contract_end_date: e
          })
        }
      }
    let handleSelectChange = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }
  
    const handleFileSelect = async (event) => {
        // setFile(event.target.files[0]);
        // console.log(event.target.files[0],event.target.name)
        const Type = event.target.name;
        const KEY = Type === "pan" ? "pan" : Type === "gst" ? "gst" : "contract";


        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('type', event.target.name);
        formData.append('contract_id', store?.contractorDetailList?.contract_id)
        const Result = await FileUploadContractorUpdate(formData);
        if (Result?.code === 200) {
            let OBJ = {
                // message: Result?.message,
                name: Result?.name,
                type: KEY,
                file_path: Result?.path,
                cd_id: Result?.document_data[0]?.cd_id
            }
            if (KEY === 'contract') {
                setFormInput({
                    ...formInput,
                    [KEY]: [...formInput[KEY], OBJ]
                })
            }
            else {
                setFormInput({
                    ...formInput,
                    [KEY]: [OBJ]
                })
            }
            toast.success('File Uploaded SuccessFully');
        }
        else {
            toast.error('File Upload Failed')
        }
    }
    const handleClearDocument = async (type, fileName, contract_id) => {
        const KEY = type === "pan" ? "pan" : type === "gst" ? "gst" : "contract";

        setDeleteDataFile({
            fileName,
            KEY,
            cd_id : formInput?.[KEY][0]?.cd_id,
            contract_id
        })
        setRemoveFile(true)
    }

    const handleDeleteDocument=async()=>{
        const formData = new FormData();
        formData.append('filename', deleteDataFile?.fileName);
        formData.append('type', deleteDataFile?.KEY)
        formData.append('cd_id', deleteDataFile?.cd_id);
        const Result = await dispatch(FileDeleteUpdateContractor(formData,Axios,deleteDataFile?.contract_id));
        if (Result?.code === 200) {
            setRemoveFile(false)
            setFormInput({
                ...formInput,
                pan:Result?.pan,
                gst:Result?.gst,
                contract:Result?.contract
            })
            toast.success('Deleted Contractor SuccessFully');
        }
        else {
            toast.error(`${Result?.message}`)
        }
    }

    const handleDeleteInvoice = async (item) => {
        setRemoveInvoice(true);
        setDeleteDataInvoice(item)
        
    }

    const ConfirmDeleteInvoice=async(item)=>{
        let param = {
            "ci_id": item?.ci_id,
            "contract_id": item?.contract_id
        }
        const data = await dispatch(DeleteInvoiceList(Axios, param))
        if (data?.code === 201) {
            setRemoveInvoice(false)
            toast.success('Deleted Invoice SuccessFully');
        }
        else {
            toast.error(`${data?.message}`)
        }
    }

    const isValid = () => {
        let success = true;
        let error = {};
        if (!formInput.contractor_name.trim()) {
            success = false
            error.contractor_name = "Please type Name of Consultant!!";
        } else if (formInput?.contractor_name?.toString()?.length > 30) {
            success = false
            error.contractor_name = "*Only 30 characters Allowed!";
        }
        if (formInput.project_id === 0) {
            success = false
            error.project_id = "Please select Project Manager!!";
        }
        if (!formInput?.pan_no.trim()) {
            success = false
            error.pan_no = "Please Enter a valid Pan number!";
        }
        else if (formInput?.pan_no?.toString()?.length !== 10) {
            success = false
            error.pan_no = "*Only 10 characters Allowed!";
        }
        else if (/^[a-z0-9]+$/i.test(formInput?.pan_no) === false) {
            success = false
            error.pan_no = "*Only alpha-numeric Allowed!";
        }
        else if (!(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(formInput?.pan_no))) {
            success = false
            error.pan_no = "Please Enter valid Pan number! ex:AOTHY6378U";
        }

        if (!formInput?.gst_no.trim()) {
            error.gst_no = null
        }
        else if (!(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9a-zA-Z]){1}?$/.test(formInput?.gst_no))) {
            success = false
            error.gst_no = "*Please Enter a valid GST number ex:23QWERT5678A1ZA !";
        }


        if (formInput?.cell_no <= 0) {
            success = false
            error.cell_no = "Please Enter a valid Cell number!";
        }
        else if (formInput?.cell_no?.toString()?.length !== 10) {
            success = false
            error.cell_no = "*Only 10 characters Allowed!";
        }
        else if (!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g.test(formInput?.cell_no)) {
            success = false
            error.cell_no = "Please Enter a valid Cell number starting with 6/7/8/9!";
        }
        else if (Number.isInteger(parseFloat(formInput.cell_no)) === false) {
            success = false
            error.cell_no = "Decimal not allowed!!";
        }
        if (!formInput.email.trim()) {
            error.email = '*Email cannot be empty!';
            success = false
        }
        else if (formInput.email?.toString().length > 50) {
            error.email = '*Only 50 characters Allowed!';
            success = false
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formInput.email)) {
            error.email = "Enter a Valid Email ex:vijayravi45@gmail.com!!";
            success = false
        }  
         if (formInput.contract_end_date !== null && formInput.contract_end_date !== undefined &&formInput.contract_end_date?.toString()?.length !== 0) {
        if (formInput.contract_start_date !== null && formInput.contract_start_date !== undefined && formInput.contract_start_date?.toString()?.length !== 0) {
         
                if (!(new Date(formInput.contract_start_date) < new Date(formInput.contract_end_date))) {
                success = false
                error.contract_start_date = "Cannot be After End date!!";
                error.contract_end_date = "Cannot be Before Start date!!";
            }
            }
            else{
                success = false
                error.contract_start_date = "Please Enter Contract Start date!!";
            }
           
        }
      

        if (formInput?.contract_value < 0) {
            success = false
            error.contract_value = "Please Enter a valid Contract Value!";
        }
        else if (formInput?.contract_value?.toString()?.length > 30) {
            success = false
            error.contract_value = "*Only 30 characters Allowed!";
        }
        else if (Number.isInteger(parseFloat(formInput.contract_value)) === false) {
            success = false
            error.contract_value = "Decimal not allowed!!";
        }
        else if (!/^[0-9]\d*$/.test(formInput.contract_value)) {
            success = false
            error.contract_value = "Please Enter a valid Contract Value!!";
        }
        if (formInput?.pan?.length === 0) {
            success = false;
            toast.error("Please upload a PAN document!!!")
        }
        // if (formInput?.gst?.length === 0) {
        //     success = false;
        //     toast.error("Please upload a GST document!!!")
        // }
        if (formInput?.contract?.length === 0) {
            success = false;
            toast.error("Please upload a Contract document!!!")
        }
        setError(error)
        return success
    }
    const handleContractFormUpdateSubmit = async (e) => {
        e.preventDefault()

        if (isValid()) {

            const projectCode = store?.projectListContractor?.filter(item => parseInt(item.project_id) === parseInt(formInput.project_id));

            let param = {
                "project_code": projectCode?.length !== 0 ? projectCode[0]?.project_code : null,
                "project_id": formInput.project_id,
                "email": formInput.email,
                "contractor_name": formInput.contractor_name,
                "pan_no": formInput.pan_no,
                "gst_no": formInput.gst_no,
                "cell_no": formInput.cell_no,
                "contract_start_date": formInput.contract_start_date !=='' && formInput.contract_start_date?.toString().length!==0? formInput.contract_start_date : null,
                "contract_end_date": formInput.contract_end_date !=='' && formInput.contract_end_date?.toString().length!==0? formInput.contract_end_date : null,        
                "contract_value": formInput.contract_value,
                'contract_id': store?.contractorDetailList?.contract_id
            }
            const data = await dispatch(UpdateContractorDetail(Axios, param))
            if (data?.code === 200) {
                setTimeout(() => {
                    navigate(-1)
                }, [2000])

                toast.success('Updated Contract SuccessFully');
                // setFormInput({
                //     "project_code": "",
                //     "project_id": 0,
                //     "email": "",
                //     "contractor_name": "",
                //     "pan_no": "",
                //     "gst_no": "",
                //     "cell_no": 0,
                //     "contract_start_date": '',
                //     "contract_end_date": '',
                //     "contract_value": 0,
                //     "pan": [],
                //     "gst": [],
                //     "contract": [],
                // })
                // setInvoiceList([])
            }
            else {
                toast.error(`${data?.message}`)
            }
        }

    }


    console.log(removeInvoice)
   

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.rootContainer}>

                <div>
                    <h2 className={styles.AddContractTitle}>Contract Management Form</h2>
                </div>
                <form autoComplete='off'>
                    <div className={styles.AddContractBody}>

                        <div className={styles.AddContractItem}>
                            <label>Project Title</label>
                            <div className={styles.ContractorInputWrapper}>
                                <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                                    <Select
                                        error={error.project_id ? true : false} value={formInput.project_id}
                                        className={styles.AddProjectItemSelect}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleSelectChange}
                                        name="project_id"
                                    >
                                        {
                                            store?.projectListContractor?.map((item, index) => (
                                                <MenuItem value={item.project_id}>{item.project_title}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText sx={{ color: "#d32f2f" }} >{error.project_id ? error.project_id : null}</FormHelperText>
                                </FormControl>
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>Name of Contractor / Consultant*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="contractor_name" value={formInput.contractor_name} onChange={handleChange} error={error.contractor_name ? true : false} helperText={error.contractor_name ? error.contractor_name : null} />
                            </div>
                        </div>

                        <div className={styles.AddContractItem}>
                            <label>PAN No*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="pan_no" value={formInput.pan_no} inputProps={{ maxLength: 10 }} onChange={handlePANChange} error={error.pan_no ? true : false} helperText={error.pan_no ? error.pan_no : null} />
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>GST No</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="gst_no" value={formInput.gst_no} inputProps={{ maxLength: 15 }} onChange={handleGSTChange} error={error.gst_no ? true : false} helperText={error.gst_no ? error.gst_no : null} />
                            </div>
                        </div>

                        <div className={styles.AddContractItem}>
                            <label>Cell No*</label>
                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="cell_no" value={formInput.cell_no} inputProps={{ maxLength: 10 }} onChange={handleChange} error={error.cell_no ? true : false} helperText={error.cell_no ? error.cell_no : null} />
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>
                            <label>Email*</label>

                            <div className={styles.ContractorInputWrapper}>
                                <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="email" value={formInput.email} onChange={handleChange} error={error.email ? true : false} helperText={error.email ? error.email : null} />
                            </div>
                        </div>


                        <div className={styles.AddContractItem}>
                            <label>Contract Start Date</label>
                            <div className={styles.ContractorInputWrapper}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="contract_start_date" value={formInput.contract_start_date}
                                        onChange={handleContractStartDate}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <TextField helperText={error.contract_start_date ? error.contract_start_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>

                            <label>Contract End Date</label>
                            <div className={styles.ContractorInputWrapper}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        name="contract_end_date" value={formInput.contract_end_date}
                                        onChange={handleContractEndDate}
                                        inputFormat="dd/MM/yyyy"
                                        renderInput={(params) => <TextField helperText={error.contract_end_date ? error.contract_end_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={styles.AddContractItem}>
                            <label>Contract value excluding GST*</label>

                            <div className={styles.ContractorInputWrapper}>
                                <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="contract_value" value={formInput.contract_value} onChange={handleChange} error={error.contract_value ? true : false} helperText={error.contract_value ? error.contract_value : null} />
                            </div>
                        </div>


                    </div>
                </form>

                <div className={styles.AddDocsPANGSTWrapper}>
                    <div className={styles.AddPANGSTDocs}  >
                        <div className={styles.AddDocsHeader}>
                            <label>PAN Number</label>
                        </div>
                        <div className={styles.DocUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst1"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst1" name='pan' onChange={handleFileSelect} />
                            </div>
                            {
                                (formInput?.pan?.length === 0 || formInput?.pan === undefined || formInput?.pan === null) ? null :
                                    <div className={styles.DocUploaded}>
                                        <a href={formInput?.pan[0]?.file_path} target={allowedExtensions.includes((formInput?.pan[0]?.file_path?.substring(formInput?.pan[0]?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                                            "_blank" : null
                                        }>{formInput?.pan[0]?.file_path?.substring(formInput?.pan[0]?.file_path?.lastIndexOf('/') + 1)}</a>
                                        <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("pan", formInput?.pan[0]?.file_path?.substring(formInput?.pan[0]?.file_path?.lastIndexOf('/') + 1), store?.contractorDetailList?.contract_id)} />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={styles.AddPANGSTDocs}  >
                        <div className={styles.AddDocsHeader}>
                            <label>GST Number</label>
                        </div>
                        <div className={styles.DocUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst2"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst2" name='gst' onChange={handleFileSelect} />
                            </div>
                            {
                                (formInput?.gst?.length === 0 || formInput?.gst === undefined || formInput?.gst === null) ? null :
                                    <div className={styles.DocUploaded}>
                                        <a href={formInput?.gst[0]?.file_path} target={allowedExtensions.includes((formInput?.gst[0]?.file_path?.substring(formInput?.gst[0]?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                                            "_blank" : null
                                        }>{formInput?.gst[0]?.file_path?.substring(formInput?.gst[0]?.file_path?.lastIndexOf('/') + 1)}</a>
                                        <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("gst", formInput?.gst[0]?.file_path?.substring(formInput?.gst[0]?.file_path?.lastIndexOf('/') + 1), store?.contractorDetailList?.contract_id)} />
                                    </div>
                            }

                        </div>
                    </div>
                </div>
                <div className={styles.AddContractDocsWrapper}>
                    <div className={styles.AddContractDocs}  >
                        <div className={styles.AddDocsContractTitle}>
                            <label>Contract</label>
                        </div>
                        <div className={styles.DocContractUploadWrapper}>
                            <div className={styles.DocUploadButton}>
                                <label htmlFor="uploadChckLst3"><UploadOutlinedIcon />Upload</label>
                                <input type="file" id="uploadChckLst3" name='contract' onChange={handleFileSelect} />
                            </div>
                            <div className={styles.DocUploadedContractWrapper}>
                                {
                                    (formInput?.contract?.length === 0 || formInput?.contract === undefined || formInput?.contract === null) ? null :
                                        (
                                            formInput?.contract?.map((elem) => {
                                                return <div className={styles.DocUploaded}>
                                                    <a href={elem?.file_path} download="contract" target={allowedExtensions.includes(elem?.file_path?.substring(elem?.file_path?.lastIndexOf('.') + 1).toLowerCase()) ?
                                                        "_blank" : null}
                                                    >{elem?.file_path?.substring(elem?.file_path?.lastIndexOf('/') + 1)}</a>

                                                    <ClearIcon style={{ cursor: "pointer" }} fontSize='small' color='warning' onClick={() => handleClearDocument("contract", elem?.file_path?.substring(elem?.file_path?.lastIndexOf('/') + 1), store?.contractorDetailList?.contract_id)} />
                                                </div>
                                            })
                                        )

                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className={styles.AddInvoiceButtonWrapper}>
                    <button className={styles.AddInvoiceButton} onClick={toggleInvoice}>Add Invoice</button>
                </div>


                <div className={styles.InvoiceBoxWrapper}>
                    {
                        invoiceList?.length !== 0 ?

                            invoiceList?.map((item, index) => (
                                <INVOICE item={item} contractStartDate={formInput?.contract_start_date} handleDeleteInvoice={handleDeleteInvoice} index={index} />
                            ))
                            : null
                    }
                </div>
            </div>
            <div className={styles.SubmitButtonWrapper}><SubmitButton onClick={handleContractFormUpdateSubmit}>Submit</SubmitButton>
            </div>
            <div>
                {show === true ? <CntrtMgtAddInvoiceModal toggle={show} toggleSet={setShow} contractId={store?.contractorDetailList
                    ?.contract_id} projectCode={store?.contractorDetailList
                        ?.project_code} contractStartDate={formInput?.contract_start_date} /> : null}

            </div>

            {removeFile && <DeletePromptContractFile remove={removeFile} setRemove={setRemoveFile} file={deleteDataFile} ToastShow={handleDeleteDocument}/>}
            {removeInvoice && <DeletePromptInvoice remove={removeInvoice} setRemove={setRemoveInvoice} file={deleteDataInvoice} ToastShow={ConfirmDeleteInvoice} />}
        </>

    )
}

export default UpdateContractManagement