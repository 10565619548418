import { EDITUSERDATA, GETUSERLISTUSERMGT } from "../reduxConstants/adminReducerConstants"
import { GETROLELISTUSERMGT } from "../reduxConstants/adminReducerConstants"
import { ADDUSERUSERMGT } from "../reduxConstants/adminReducerConstants"

const initialState={
       userList:[],
       roleList:[],
       userData:{}
}

export default(state=initialState,action)=>{

    switch(action.type){
        case GETUSERLISTUSERMGT:
            return {
                ...state,
                userList : action.payload!==undefined && action.payload!==null ? (action.payload): ([])
            }
        case GETROLELISTUSERMGT:
            return {
                ...state,
                roleList : action.payload!==undefined && action.payload!==null ? (action.payload): ([])
            }

        case EDITUSERDATA:
        return { 
            ...state,
            userData : action.payload
        }
        default:
            return state
    }
}