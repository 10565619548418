import React, { useEffect, useState } from 'react'
import styles from './styles/documentsDownloads.module.css'
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { GetConsultantDownloadList, GetTenderDownloadList } from '../../actions/downloads/DownloadActions';
import ConsultantCard from './ConsultantCard';


const SearchFilter = (data, columns, q) => {
  if (data !== undefined && data !== null && data.length !== 0) {
    return data.filter((row) =>
      columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
  }
}

const ConsultantDownloads = ({ searchText }) => {
 const [ConsultantDownloadList,setConsultantDownloadList] = useState([])
  let store = useSelector(state => state.Downloads);
  let dispatch = useDispatch()
  let Axios = useAxiosPrivate()
  let ConsultantDownloadListSearched = SearchFilter(ConsultantDownloadList, ['name'], searchText)

  useEffect(() => {
    dispatch(GetConsultantDownloadList(Axios))
  }, [])
  useEffect(()=>{
    setConsultantDownloadList(store?.consultantDownloadList ? store?.consultantDownloadList : null)
  },[store?.consultantDownloadList])

console.log(store)
  return (
    <div className={styles.rootContainer}>
      {ConsultantDownloadListSearched?.map((elem, index) => {
        return <div className={styles.ListWrapper}>
          <div className={index % 2 === 0 ? styles.ListTitle : styles.ListTitleAlt}>
            <label>
              {elem?.name}
            </label>
          </div>
          <div className={index % 2 === 0 ? styles.ListBody : styles.ListBodyAlt}>
          <ConsultantCard cr_id = {elem?.cr_id}/>
          </div>
        </div>
      })}
    </div>
  )
}
export default ConsultantDownloads 