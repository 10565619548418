import React,{useState} from 'react'
import styles from './styles/ctrlFldsBidSector.module.css'

const UpdateBidSector = ({item,handleList}) => {  
    const [bidSector,setBidSector]=useState(item);


    const handleChange=(e)=>{
        setBidSector({
            ...bidSector,
            bs_name:e.target.value})
        handleList(bidSector,e.target.value)
    }

    return (
        <div>
            <input className={styles.CtrlFldsItemInput} type="text" name="bs_name" value={bidSector.bs_name} autoComplete="off" onChange={handleChange}/>
        </div>
    )
}

export default UpdateBidSector