import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Divider from '@mui/material/Divider';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import styles from "./styles/drawer.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LOGOUTUSER } from '../reduxConstants/LoginReducerConstant';
import { useDispatch, useSelector } from 'react-redux'
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ADMIN, OPPORTUNITY, PROJECTEXPORTALLOWED } from '../hooks/AllowedRoles';




const data = [
    {
        "title": "ADMIN",
        "options": [
            { "icon": <AccountCircleOutlinedIcon />, optionTitle: "User Management", path: "/UserManagement", AllowedUser: ADMIN },
            { "icon": <TuneOutlinedIcon />, optionTitle: "Control Fields", path: "/ControlFields", AllowedUser: ADMIN },
            // { "icon": <CloudDownloadIcon />, optionTitle: "Downloads", path: "/Downloads", AllowedUser: ADMIN }
            
        ],
        AllowedUser: ADMIN
    },
    {
        "title": "OPPORTUNITY & BIDDING",
        "options": [
            { "icon": <ClassOutlinedIcon />, optionTitle: "Catalogue", path: "/Catalogue/Page:1", AllowedUser: OPPORTUNITY },
            { "icon": <AppsOutlinedIcon />, optionTitle: "Add Tender", path: "/AddTender", AllowedUser: OPPORTUNITY }
        ],
        AllowedUser: OPPORTUNITY
    },
    {
        "title": "PROJECT MANAGEMENT",
        "options": [
            { "icon": <AppsOutlinedIcon />, optionTitle: "Projects Index", path: "/ProjectsIndex/Page:1", AllowedUser: OPPORTUNITY },
            { "icon": <PlaylistAddIcon />, optionTitle: "Add Project", path: "/AddProject", AllowedUser: PROJECTEXPORTALLOWED }
        ]

        , AllowedUser: OPPORTUNITY
    },
    {
        "title": "CONTRACTS",
        "options": [
            { "icon": <AppsOutlinedIcon />, optionTitle: "Contractor Detail", path: "/ContractorDetail/Page:1", AllowedUser: OPPORTUNITY },
            { "icon": <PlaylistAddIcon />, optionTitle: "Add Contractor", path: "/AddContractor", AllowedUser: OPPORTUNITY }
        ],
        AllowedUser: OPPORTUNITY
    },
    {
        "title": "CONSULTANTS",
        "options": [
            { "icon": <AppsOutlinedIcon />, optionTitle: "Consultants Detail", path: "/ConsultantsDetail", AllowedUser: OPPORTUNITY },
            { "icon": <PlaylistAddIcon />, optionTitle: "Add Consultant", path: "/AddConsultant", AllowedUser: OPPORTUNITY }
        ],
        AllowedUser: OPPORTUNITY
    },
    {
        "title": "DOCUMENTS",
        "options": [
            { "icon": <FolderOpenOutlinedIcon />, optionTitle: "Documents", path: "/documents", AllowedUser: OPPORTUNITY },
        ],
        AllowedUser: OPPORTUNITY
    }
]

const SideDrawer = ({setMobileOpen}) => {
    const store = useSelector(state => state.Login)
    const dispatch = useDispatch();
    let location = useLocation();
    const navigate = useNavigate();

    let LogoutLocation = {
        hash: "",
        key: "iow979v9",
        pathname: undefined,
        search: "",
        state: null
    }

    const handleLogout = () => {
        navigate('/Login', { state: LogoutLocation });
        localStorage.removeItem('jwt');
        localStorage.setItem('persist', false);
        dispatch({ type: LOGOUTUSER })
    }



    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", minHeight: "100vh" }}>
                <div>
                    {data.map((elem, i) => (
                        <>


                            <div className={styles.OptionWrapper}>
                                {elem?.AllowedUser?.includes(parseInt(store?.role?.role_code)) ? <label className={styles.drawerTitle}>{elem.title}</label> : null}
                                {
                                    elem.options.map((item, index) => (
                                        item?.AllowedUser?.includes(parseInt(store?.role?.role_code)) ? (
                                            <div className={styles.DrawerOptions} onClick={()=>setMobileOpen(false)}  >
                                                <NavLink to={item.path} className={!location.pathname.indexOf(item.path) ? styles.activelink : styles.link}>
                                                    <div className={
                                                        location.pathname === item.path ? styles.DrawerListOptionsIconActive : styles.DrawerListOptionsIcon
                                                    }>{item.icon}</div>
                                                    <div className={
                                                        location.pathname === item.path ? styles.DrawerListOptionsTitleActive : styles.DrawerListOptionsTitle}>{item.optionTitle}</div>
                                                </NavLink>
                                            </div>
                                        ) :
                                            null
                                    ))
                                }
                            </div>



                        </>
                    ))}

                    <Divider />


                </div>

                <div className={styles.Version}>

                    <Fab size="small" variant="extended" className={styles.Conta} onClick={handleLogout}>
                        <ArrowBackIcon className={styles.arrowIcon} />
                        <label className={styles.LogoutText}>LOGOUT</label>
                    </Fab>
                    {/* <label>{store.role_code}{store.user_id}</label> */}
                        
                    
                    <label style={{ fontSize: "12px", fontWeight: "bold", padding: "0px 0px 20px 0px" }}>v0.0.25</label>
                </div>
            </div>
        </>
    )
}



export default SideDrawer