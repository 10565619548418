import React,{useState,useEffect} from 'react'
import editIcon from "./images/editIconNew.png";
import styles from './styles/contractManagementDetails.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NormalDateFormat, UserMgtTableDate } from '../../utility/DateFunction';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {useLocation, useNavigate} from 'react-router-dom';
import { DeleteContractor, GetContractorDetailList, GetContractorList } from '../../actions/contractor/ContrractorActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteContractsTablePrompt from './DeleteContractsTablePrompt';

const ContractorTable = ({columns,currentPosts,currentPage,postsPerPage}) => {

    const navigate = useNavigate()
    const store = useSelector(state=>state.Contractor);
    const dispatch = useDispatch();
    const Axios = useAxiosPrivate();
    const location = useLocation();
    const count = currentPage*postsPerPage-postsPerPage;
    const [remove,setRemove]=useState(false)
    const [deleteData,setDeleteData] =useState({})

    const handleContractorDetail = async(item) => {
        navigate(`${location?.pathname}/updateContractor/${item?.contract_id}`)
        let param = {
    
            "contract_id":item?.contract_id
        
        }
        dispatch(GetContractorDetailList(Axios , param))
        console.log(store)
    }

    const handleDeleteContractor = async(item) => {
        setDeleteData(item);
        setRemove(true)
    
     
       
        
    }

    const ToastShow =(data)=>{
        if (data?.code === 200) {
            setRemove(false)
            toast.success('Deleted Contractor SuccessFully');
        }
        else {
            toast.error(`${data?.message}`)
        }
    }
  return (
    <>
     <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
              <table className={styles.ContractsDetailTable}>
                <thead className={styles.ContractsDetailTableHead}>
                    <tr>
                    <th className={styles.ContractsDetailTableTH}>Sl no.</th>
                        {
                            columns.map((list) =>
                                <th className={styles.ContractsDetailTableTH}>{list.replace(/_/g,' ').toUpperCase()}</th>
                            )
                        }
                        <th className={styles.ContractsDetailTableTH}>EDIT</th>
                        <th className={styles.ContractsDetailTableTH}>DELETE</th>
                    </tr>

                </thead>
                <tbody>
                    {
                        currentPosts?.map((item, index) => (
                            <tr className={index % 2 === 1 ? styles.TableBodyAltBg : null} key={index} >
                                 <td className={styles.ContractsDetailTableTD}>{count+index+1}</td>
                                {
                                    columns?.map((column) =>
                                        <td className={styles.ContractsDetailTableTD} title={item[column]}>
                                        {column?.endsWith('date') || (column?.endsWith('time')) || (column?.endsWith('at')) ? (item[column] === null || item[column] === undefined || NormalDateFormat(item[column])==="01-01-1970" ? "--/--/----" : NormalDateFormat(item[column]))  : item[column]}
                                        </td>
                                    )
                                }
                                <td className={styles.ContractsDetailTableTD}
                                onClick={()=>handleContractorDetail(item)}
                                >
                                <img src={editIcon} style={{ width: 24, height: 24, cursor: "pointer" }} /></td>

                                <td className={styles.ContractsDetailTableTD}
                                onClick={()=>handleDeleteContractor(item)}><DeleteIcon style={{cursor: "pointer"}}/></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {remove && <DeleteContractsTablePrompt remove={remove} setRemove={setRemove} file={deleteData} ToastShow={ToastShow}/>}
    </>
  )
}

export default ContractorTable