import { TextField } from '@mui/material';
import React,{useState,useEffect} from 'react';
import styles from './styles/ctrlFldsStates.module.css'

const UpdateState = ({item,handleList}) => {

    const [stateName,setStateName]=useState(item);


    const handleChange=(e)=>{
        setStateName({
            ...stateName,
            state_name:e.target.value})
        handleList(stateName,e.target.value)
    }

    return (
        <div style={{width:"100%"}}>
            {/* <input  className={styles.CtrlFldsItemInput} type="text" name="state_name" value={stateName.state_name} onChange={handleChange}/> */}
            <TextField variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}} size="small" type="text" name="state_name" autoComplete="off" value={stateName.state_name} onChange={handleChange}/>
        </div>
    )
}

export default UpdateState