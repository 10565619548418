import { TextField } from '@mui/material'
import React, { useState } from 'react'
import ConsultantDownloads from './ConsultantDownloads'
import ContractorDownloads from './ContractorDownloads'
import ProjectDownloads from './ProjectDownloads'
import styles from './styles/downloadsIndex.module.css'
import TenderDownloads from './TenderDownloads'



const DownloadIndex = () => {
    const [selected, setSelected] = useState('tender')
    const [searchText, setSearchText] = useState("")

    const handleToggle = (menuTitle) => {
        setSelected(menuTitle)
        setSearchText('')
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }
    return (
        <>

            <div className={styles.rootContainer}>

                <div>
                    <h2 className={styles.DownloadsTitle}>Downloads</h2>
                </div>
                <div className={styles.DownloadHeaderWrapper}>
                    <div className={styles.Menu}>
                        <div className={selected === "tender" ? styles.MenuClicked : styles.MenuItem} onClick={() => handleToggle("tender")}>Tender</div>
                        <div className={selected === "project" ? styles.MenuClicked : styles.MenuItem} onClick={() => handleToggle("project")}>Projects</div>
                        <div className={selected === "contractor" ? styles.MenuClicked : styles.MenuItem} onClick={() => handleToggle("contractor")}>Contractors</div>
                        <div className={selected === "consultant" ? styles.MenuClicked : styles.MenuItem} onClick={() => handleToggle("consultant")}>Consltants</div>
                    </div>
                    <div className={styles.searchWrapper}>
                        <TextField variant="outlined" label="Search" className={styles.searchInputField} sx={{ minWidth: "200px", maxWidth: '250px' }} size="small" name="searchList" autoComplete='off'
                            onChange={handleSearch} value={searchText}
                        />
                    </div>
                </div>

                <div>
                    {
                        selected === "project" ? <ProjectDownloads searchText={searchText} /> : selected === "contractor" ? <ContractorDownloads searchText={searchText} /> : selected === "consultant" ? <ConsultantDownloads searchText={searchText} /> : selected === "tender" ? <TenderDownloads searchText={searchText} /> : null
                    }

                </div>

            </div>

        </>
    )
}

export default DownloadIndex