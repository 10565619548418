import React, { useEffect,useState } from 'react'
import styles from './styles/projectManagementDetails.module.css';
import { NormalDateFormat, TableconvertDate } from '../../utility/DateFunction';
import { DeleteProject, GetProjectDetail } from '../../actions/ProjectManagement/ProjectAction';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useLocation, useNavigate} from 'react-router-dom';
import DeleteProjectTablePrompt from './DeleteProjectTablePrompt';

const ProjectManagementTable = ({columns, currentPosts,handleChildProjectDetail, editIcon,postsPerPage,currentPage }) => {
    let count =currentPage*postsPerPage-postsPerPage
    const navigate = useNavigate()
    const store = useSelector(state=>state.Project);
    const dispatch = useDispatch();
    const Axios = useAxiosPrivate();
    const location = useLocation();
    const [remove,setRemove]=useState(false)
    const [deleteData,setDeleteData] =useState({})

    const handleProjectDetail = async(item) => {
        navigate(`${location?.pathname}/updateProject/${item?.project_id}`)
        let param = {
    
            "project_id":item?.project_id
        
        }
        dispatch(GetProjectDetail(Axios , param))
    }

    const handleDeleteProject = async(item) => {
         setDeleteData(item);
         setRemove(true)
       
        
    }


    const ToastShow=(data)=>{
        
        if (data?.code === 200) {
            setRemove(false)
            toast.success('Deleted Project SuccessFully');
        }
        else {
            toast.error(`${data?.message}`)
        }
    }

  return (
    <>
     <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
     <table className={styles.ProjectsTable}>
    <thead className={styles.ProjectsTableHead}>
        <tr > 
            <th className={styles.ProjectsTableTH}>Sl No.</th>
            {
                columns?.map((list) =>
                    <th className={styles.ProjectsTableTH}>{
                        list === "project_manager_name"? "PROJECT MANAGER" :
                        list === "updated_by_name"? "UPDATED BY" :
                        list.replace(/_/g,' ').toUpperCase()
                        }</th>
                )
            }
            <th className={styles.ProjectsTableTH}>INVOICE</th>
            <th className={styles.ProjectsTableTH}>EDIT</th>
            <th className={styles.ProjectsTableTH}>DELETE</th>
        </tr>

    </thead>
    <tbody>
        {   

            currentPosts?.map((item, index) => (
                <tr className={index % 2 === 1 ? styles.TableBodyAltBg : null} key={index} >
                    <td className={styles.ProjectsTableTD}>
                        {
                            index+1+count
                        }
                   </td>
                    {

                        columns?.map((column) =>
                            <td className={styles.ProjectsTableTD} title={item[column]}>{

                                column?.endsWith('date') || (column?.endsWith('time')) || (column?.endsWith('at')) ? (item[column] === null || item[column] === undefined || NormalDateFormat(item[column])==="01-01-1970" ? "--/--/----" : NormalDateFormat(item[column])) : (column === 'budgeted_cost' || column === 'target_net_revenue')? parseInt(item[column])?.toLocaleString('en-IN') :
                                item[column]
                        }</td>
                        )
                    }
                    <td  className={styles.ProjectsTableTD}><button onClick={()=>handleChildProjectDetail(item)} className={styles.InvoiceButton}>Invoice/Payment</button></td>

                    <td  className={styles.ProjectsTableTD} onClick={()=>handleProjectDetail(item)}><img src={editIcon} style={{ width: 24, height: 24,cursor:"pointer" }} /></td>

                    <td className={styles.ProjectsTableTD}
                                onClick={()=>handleDeleteProject(item)}><DeleteIcon style={{cursor: "pointer"}}/></td>
                </tr>
            ))
        }
    </tbody>
</table>

{remove && <DeleteProjectTablePrompt remove={remove} setRemove={setRemove} file={deleteData} ToastShow={ToastShow}/>}
    </>
   
  )
}

export default ProjectManagementTable