export const columnData = {
    contract_code: false,
    project_title: true,
    contractor_name: true,
    pan_no: true,
    gst_no: true,
    cell_no: true,
    email: true,
    contract_start_date: true,
    contract_end_date: true,
    contract_value: false,
    no_of_invoices:false
}

export const SelectALLData = {
    contract_code: true,
    project_title: true,
    contractor_name: true,
    pan_no: true,
    gst_no: true,
    cell_no: true,
    email: true,
    contract_start_date: true,
    contract_end_date: true,
    contract_value: true,
    no_of_invoices:true
}