import { GETCONTROLFIELDSTATELIST, GETCONTROLFIELDDONORLIST, GETCONTROLFIELDNOTICELIST, GETCONTROLFIELDMODELIST, GETCONTROLFIELDFINANCIALLIST, GETCONTROLFIELDBIDCATEGORYLIST, GETCONTROLFIELDBIDFUNCTIONLIST, GETCONTROLFIELDBIDSECTORLIST, GETCONTROLFIELDEXPERTISELiST } from "../../reduxConstants/controlFieldsConstants";



export const GetControlFieldStateList = (Axios) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.get("/control_field_state_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDSTATELIST,payload:data?.state_list})
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const AddStateControlField = (Axios,param) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/control_field_add_state",param);
      
        if(data?.code===201){
             dispatch(GetControlFieldStateList(Axios))
             return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const UpdateStateControlField = (Axios,param) =>async(dispatch) => {
   
    try{
        const {data} = await Axios.post("/control_field_update_state",param);
      
        if(data?.code===200){
             dispatch(GetControlFieldStateList(Axios))
             return data
        }
    }
    catch(error){
               
        return error?.response?.data
    }
}

export const GetControlFieldsDonorList = (Axios) =>async(dispatch)=>{
    try{
        const {data} = await Axios.get("/control_field_donor_list");
       // console.log(data)
        if(data?.code === 200){
            dispatch({type:GETCONTROLFIELDDONORLIST, payload:data?.donor_list})
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const AddDonorControlField = (Axios,param) =>async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_donor",param);

        if(data?.code === 201){
            dispatch(GetControlFieldsDonorList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateDonorControlField = (Axios,param) =>async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_donor",param);
        //    console.log(data)
        if(data?.code === 200){
            
           const data = await dispatch(GetControlFieldsDonorList(Axios));
            // console.log(data1,"data1")
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const NoticeList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_notice_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDNOTICELIST,payload:data?.notice_list})
        }
      
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddNoticeApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_notice",param)
        if(data?.code === 201){
            dispatch(NoticeList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateNoticeApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_notice",param)
        //console.log(data)
        if(data?.code === 200){
            await dispatch(NoticeList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetModeList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_mode_list");

        if(data?.code===200){
           dispatch({type:GETCONTROLFIELDMODELIST,payload:data?.mode_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddModeApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_mode",param)
        if(data?.code === 201){
            dispatch(GetModeList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateModeApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_mode",param)
        if(data?.code === 200){
           await dispatch(GetModeList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetFinancialList =(Axios)=>async(dispatch) => {
    try{
        
        const {data} = await Axios.get("/control_field_financial_list");
        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDFINANCIALLIST,payload:data?.financial_year_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddFinancialApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_financial",param)
        if(data?.code === 201){
            dispatch(GetFinancialList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateFinancialApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_financial",param)
        if(data?.code === 200){
            await dispatch(GetFinancialList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetBidCategoryList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_bid_category_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDBIDCATEGORYLIST,payload:data?.Bid_Category_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddBidCategoryApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_bid_category",param)
        if(data?.code === 201){
            dispatch(GetBidCategoryList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateBidCategoryApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_bid_category",param)
        if(data?.code === 200){
            await dispatch(GetBidCategoryList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}





export const GetBidFunctionList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_bid_function_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDBIDFUNCTIONLIST,payload:data?.Bid_Function_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddBidFunctionApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_bid_function",param)
        if(data?.code === 201){
            dispatch(GetBidFunctionList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateBidFunctionApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_bid_function",param)
        if(data?.code === 200){
            await dispatch(GetBidFunctionList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}

export const GetBidSectorList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_bid_sector_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDBIDSECTORLIST,payload:data?.Bid_Sector_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddBidSectorApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_bid_sector",param)
        if(data?.code === 201){
            dispatch(GetBidSectorList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateBidSectorApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_bid_sector",param)
        if(data?.code === 200){
            await dispatch(GetBidSectorList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}


export const GetExpertiseList =(Axios)=>async(dispatch) => {
    try{

        const {data} = await Axios.get("/control_field_expertise_list");

        if(data?.code===200){
            dispatch({type:GETCONTROLFIELDEXPERTISELiST,payload:data?.expertise_list})
        } 
    }
    catch(error){
        return error?.response?.data
    }
}
export const AddExpertiseApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_add_expertise",param)
        if(data?.code === 201){
            dispatch(GetExpertiseList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}
export const UpdateExpertiseApi = (Axios,param) => async(dispatch)=>{
    try{
        const {data} = await Axios.post("/control_field_update_expertise",param)
        if(data?.code === 200){
            await dispatch(GetExpertiseList(Axios))
            return data
        }
    }
    catch(error){
        return error?.response?.data
    }
}




