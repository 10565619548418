import React, { useState, useEffect } from 'react'
import styles from "./styles/ctrlFldsStates.module.css";
import { useDispatch } from 'react-redux'
import { AddStateControlField } from '../../../actions/controlFields/controlFieldsActions';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddLoadingButton } from '../../../utility/button/button';


const AddStates = () => {
    const Axios = useAxiosPrivate();
    const dispatch = useDispatch()
    const [stateName, setStateName] = useState('');
    const [error, setError] = useState({})
    const [loading,setLoading] = useState(false)


    const IsValid = () => {
        let success = true;
        let error = {};


        if (!stateName.trim()) {
            error.state = "Please type a State!!!!";
            success = false
        }
        else if(stateName?.toString().length>50){
            error.state = '*Only 50 characters Allowed!';
            success = false
        }

        setError(error)

        return success
    }
    const handleAddState = async (e) => {
        e.preventDefault();

        if (IsValid()) {
            setLoading(true)
            let param = {
                "state_name": stateName.replace(/\s+/g, ' ').trim()
            }
            const data = await dispatch(AddStateControlField(Axios, param))
            

            if (data?.code === 201) {
            setLoading(false)
                setStateName('');
                toast.success('Added State SuccessFully')
            }
            else {
            setLoading(false)
                toast.error(`${data?.message}`)
            }
        }
    }



    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <form onSubmit={handleAddState}>

                <div className={styles.CtrlFldsBodyAdd}>
                    <div className={styles.CtrlFldsInputWrapper}>
                        <input onChange={(e) => setStateName(e.target.value)} className={styles.CtrlFldsBodyAddInput}  autoComplete="off" value={stateName} type="text" placeholder='Add Item' />
                        {error.state && <label className={styles.emptyMessage}>{error.state}</label>}
                    </div>
            <div>
            <AddLoadingButton type="submit" size="small"  loading={loading} className={styles.SubmitButton}>Add</AddLoadingButton>
            </div>
                </div>


            </form>

        </>
    )
}

export default AddStates