import React, { useEffect, useState } from 'react'
import styles from "./styles/addProject.module.css"
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { AddProjectList, DocumentDeleteProject, FileUploadProject, GetChildDetailProject, GetDocumentsForProjectById, GetProjectDetail, GetTenderListProject, GetUserListProject, updateFileUploadProject, UpdateProjectDetail } from '../../actions/ProjectManagement/ProjectAction';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AddChildTable from './AddChildTable';
import { PROJECTUPDATEALLOWED } from '../../hooks/AllowedRoles';
import { SubmitButton } from '../../utility/button/button';
import DeletePromptProjectDocument from './DeletePromptProjectDocument';


const UpdateProject = () => {

  const store = useSelector(state => state.Project);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']
  let Axios = useAxiosPrivate()
  const location = useLocation();
  const [error, setError] = useState({});
  const [invoiceData, setInvoiceData] = useState([]);
  let [formInput, setFormInput] = useState(
    {
      "tender_id": 0,
      "tender_code": "",
      "bid_title": "",
      "project_id": 0,
      "project_code": "",
      "project_title": "",
      "project_manager_id": 0,
      "internal_team_member": "",
      "contract_start_date": "",
      "contract_closure_date": "",
      "contract_value": 0,
      "performance_security_date": "",
      "performance_security_amount": 0,
      "pip_approved": "No",
      "budgeted_cost": 0,
      "target_net_revenue": 0,
      "project_sheet_submitted": "No",
      "deliverables_no": 0,
      "project_certificate": "No",
      "performance_security_refund": "No",
      "comments": "",
      "full_name": "",
      "user_id": 0,
      "project_pipdocument_url": [],
      "project_projectsheet_url": []
    }

  )
  const [remove, setRemove] = useState(false);
  const [deleteData, setDeleteData] = useState({})


  const ROLE_LIST = PROJECTUPDATEALLOWED;


  useEffect(() => {

    let paramDetail = {
      "project_id": parseInt(location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1))
    }


    dispatch(GetProjectDetail(Axios, paramDetail))
    dispatch(GetUserListProject(Axios))
    dispatch(GetTenderListProject(Axios))
  }, [])

  useEffect(() => {
    setFormInput(store?.projectDetail ? store?.projectDetail :
      {
        "tender_id": 0,
        "tender_code": "",
        "bid_title": "",
        "project_id": 0,
        "project_code": "",
        "project_title": "",
        "project_manager_id": 0,
        "internal_team_member": "",
        "contract_start_date": '',
        "contract_closure_date": '',
        "contract_value": 0,
        "performance_security_date": '',
        "performance_security_amount": 0,
        "pip_approved": "No",
        "budgeted_cost": 0,
        "target_net_revenue": 0,
        "project_sheet_submitted": "No",
        "deliverables_no": 0,
        "project_certificate": "No",
        "performance_security_refund": "No",
        "comments": "",
        "created_at": "",
        "updated_by": null,
        "updated_at": "",
        "created_by": null,
        "full_name": "",
        "user_id": 0,
      }
    )

    let param = {
      "project_id": parseInt(store?.projectDetail?.project_id)
    }
    // dispatch(GetDocumentsForProjectById(Axios, param))
    dispatch(GetChildDetailProject(Axios, param))
  }, [store.projectDetail])


  useEffect(() => {
    let param = {
      "project_id": parseInt(store?.projectDetail?.project_id)
    }
    dispatch(GetDocumentsForProjectById(Axios, param))
  }, [store?.projectDetail?.project_id])

  useEffect(() => {
    const sorted = store?.projectChildDetail?.slice().sort((a, b) => new Date(a['deliverable_due_date']) - new Date(b['deliverable_due_date']))

    setInvoiceData(sorted)
  }, [store.projectChildDetail])



  const isValid = () => {
    let success = true;
    let error = {};

    if (!formInput.project_title.trim()) {
      success = false
      error.project_title = "Please type Project Title!!";
    } else if (formInput?.project_title?.toString()?.length >= 100) {
      success = false
      error.project_title = "*Only 100 characters Allowed!";
    }
    if (formInput.project_manager_id === 0) {
      success = false
      error.project_manager_id = "Please select Project Manager!!";
    }

    console.log(formInput.contract_start_date)
    console.log(formInput.contract_start_date?.toString()?.length !== 0);



    if (formInput.performance_security_date !== null && formInput.performance_security_date !== undefined && formInput.performance_security_date?.toString()?.length !== 0) {
      if (formInput.contract_start_date !== null && formInput.contract_start_date !== undefined && formInput.contract_start_date?.toString()?.length !== 0) {

        if ((new Date(formInput.contract_start_date) >= new Date(formInput.performance_security_date))) {
          success = false
          error.contract_start_date = "Cannot be After Performance Security Date!!";
          error.performance_security_date = "Cannot be Before Contract Start Date!!"
        }
      }
      else {
        success = false
        error.contract_start_date = "Contract Start Date Cannot Be Empty!!";
      }

      if (formInput.contract_closure_date !== null && formInput.contract_closure_date !== undefined && formInput.contract_closure_date?.toString()?.length !== 0) {

        if ((new Date(formInput.contract_closure_date) <= new Date(formInput.performance_security_date))) {
          success = false
          error.contract_closure_date = "Cannot be Before Performance Security Date!!";
          error.performance_security_date = "Cannot be After Contract End Date!!"
        }
      }
      // else {
      //   success = false
      //   error.performance_security_date = "Performance Security Date Cannot Be Empty!!";
      // }

    }
    else {
      if (formInput.contract_closure_date !== null && formInput.contract_closure_date !== undefined && formInput.contract_closure_date?.toString()?.length !== 0) {
        success = false
        error.performance_security_date = "Performance Security Date Cannot Be Empty!!";
      }

    }
    console.log(formInput.contract_closure_date)
    if (formInput.contract_closure_date !== null && formInput.contract_closure_date !== undefined && formInput.contract_closure_date?.toString()?.length !== 0) {
      if (formInput.contract_start_date !== null && formInput.contract_start_date !== undefined && formInput.contract_start_date?.toString()?.length !== 0) {
        if ((new Date(formInput.contract_start_date) > new Date(formInput.contract_closure_date))) {
          success = false
          error.contract_start_date = "Cannot be After Closure date!!";
        }
      }
      else {


        success = false
        error.contract_start_date = "Contract Start Cannot be Empty!!"
      }
    }





    if (formInput.contract_start_date !== null && formInput.contract_start_date !== undefined && formInput.contract_start_date?.toString()?.length !== 0) {
      if (formInput.performance_security_date !== null && formInput.performance_security_date !== undefined && formInput.performance_security_date?.toString()?.length !== 0) {
        // if (!(new Date(formInput.performance_security_date) < new Date(formInput.contract_closure_date))) {
        //   success = false
        //   error.performance_security_date = "Cannot be After Contract End Date!!";
        //   error.contract_closure_date = "Cannot be Before Performance Security Date!!"
        // }
      }
      if (formInput.contract_closure_date !== null && formInput.contract_closure_date !== undefined && formInput.contract_closure_date?.toString()?.length !== 0) {
        if (!(new Date(formInput.contract_start_date) < new Date(formInput.contract_closure_date))) {
          success = false
          error.contract_start_date = "Cannot be After Closure date!!";
        }
      }
    }


    if (formInput.contract_closure_date !== null && formInput.contract_closure_date !== undefined && formInput.contract_closure_date?.toString()?.length !== 0) {
      if (!(new Date(formInput.contract_closure_date) > new Date(formInput.contract_start_date))) {
        success = false
        error.contract_closure_date = "Cannot be Before Start date!!";
      }
    }


    if (formInput?.internal_team_member?.toString()?.length > 30) {
      success = false
      error.internal_team_member = "*Only 30 characters Allowed!";
    }

    if (formInput.contract_value < 0) {
      success = false
      error.contract_value = "Please Enter a valid Contract Value!!";
    }
    else if (formInput?.contract_value?.toString()?.length > 30) {
      success = false
      error.contract_value = "*Only 30 characters Allowed!";
    }
    else if (Number.isInteger(parseInt(formInput.contract_value)) === false) {
      success = false
      error.contract_value = "Decimal not allowed!!";
    }
    else if (!/^[0-9]\d*$/.test(formInput.contract_value)) {
      success = false
      error.contract_value = "Please Enter a valid Number!!";
    }

    if (formInput?.performance_security_amount?.toString()?.length > 30) {
      success = false
      error.performance_security_amount = "*Only 30 characters Allowed!";
    }
    else if (Number.isInteger(parseInt(formInput.performance_security_amount)) === false) {
      success = false
      error.performance_security_amount = "Decimal not allowed!!";
    }
    else if (!/^[0-9]\d*$/.test(formInput.performance_security_amount)) {
      success = false
      error.performance_security_amount = "Please Enter a valid Number!!";
    }


    if (formInput?.pip_approved?.length === 0) {
      success = false
      error.pip_approved = "Please select PIP Approved!!";
    }

    if (formInput.budgeted_cost < 0) {
      success = false
      error.budgeted_cost = "Please Enter a valid Budgeted Cost!!";
    }
    else if (formInput?.budgeted_cost?.toString()?.length > 30) {
      success = false
      error.budgeted_cost = "*Only 30 characters Allowed!";
    }
    else if (Number.isInteger(parseInt(formInput.budgeted_cost)) === false) {
      success = false
      error.budgeted_cost = "Decimal not allowed!!";
    }
    else if (!/^[0-9]\d*$/.test(formInput.budgeted_cost)) {
      success = false
      error.budgeted_cost = "Please Enter a valid Number!!";
    }
    if (parseInt(formInput.budgeted_cost) > formInput?.contract_value) {
      success = false
      error.budgeted_cost = "Cannot be more than Contract Value!!";
      error.contract_value = "Cannot be less than Budgeted Cost!!";
    }

    // if (formInput.target_net_revenue < 0) {
    //   success = false
    //   error.target_net_revenue = "Please Enter a valid Net Revenue!!";
    // } else if (formInput?.target_net_revenue?.toString()?.length > 30) {
    //   success = false
    //   error.target_net_revenue = "*Only 30 characters Allowed!";
    // }
    // else if (Number.isInteger(parseInt(formInput.target_net_revenue)) === false) {
    //   success = false
    //   error.target_net_revenue = "Decimal not allowed!!";
    // }

    if (formInput?.deliverables_no?.toString()?.length > 30) {
      success = false
      error.deliverables_no = "*Only 30 characters Allowed!";
    }
    else if (!/^[0-9]\d*$/.test(formInput.deliverables_no)) {
      success = false
      error.deliverables_no = "Please Enter a valid Number!!";
    }
    else if (Number.isInteger(parseInt(formInput.deliverables_no)) === false) {
      success = false
      error.deliverables_no = "Decimal not allowed!!";
    }

    if (formInput?.project_sheet_submitted?.length === 0) {
      success = false
      error.project_sheet_submitted = "Please select Sheet Submitted!!";
    }
    if (formInput?.project_certificate?.length === 0) {
      success = false
      error.project_certificate = "Please select Project Certificate!!";
    }
    if (formInput?.performance_security_refund?.length === 0) {
      success = false
      error.performance_security_refund = "Please select Security Refund!!";
    }
    if (formInput?.project_pipdocument_url?.length === 0) {
      success = false;
      toast.error("Please upload a PIP document!!!")
    }
    if (formInput?.project_projectsheet_url?.length === 0) {
      success = false;
      toast.error("Please upload a Project Sheet!!!")
    }

    console.log(error)
    setError(error)
    return success
  }


  const handleFileSelect = async (event) => {
    const Type = event.target.name;
    const KEY = Type === "pipDocument" ? "project_pipdocument_url" : Type === "projectSheet" ? "project_projectsheet_url" : null;
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('type', event.target.name);
    formData.append('project_id', parseInt(store?.projectDetail?.project_id))
    const Result = await updateFileUploadProject(formData);

    if (Result?.code === 200) {
      let OBJ = {
        type: event.target.name,
        file_path: Result?.path,
        pd_id: Result?.document_data[0]?.pd_id,
        project_id: parseInt(store?.projectDetail?.project_id)
      }
      await setFormInput({
        ...formInput,
        [KEY]: [...formInput[KEY], OBJ]
      })
      toast.success('File Uploaded SuccessFully');
    }
    else {
      toast.error('File Upload Failed')
    }
  }

  const handleClearDocument = async (type, item, index) => {

    setRemove(true);
    setDeleteData({
      "project_id": item?.project_id,
      "type": type,
      "filename": item?.file_path,
      "pd_id": item.pd_id,
      "item":item
    })

  }

  const handleConfirmDeleteDocument = async() => {
    let param = {
      "project_id": deleteData?.project_id,
      "type": deleteData?.type,
      "filename": deleteData?.filename,
      "pd_id": deleteData.pd_id
    }
    const data = await dispatch(DocumentDeleteProject(Axios, param));
    if (deleteData?.type === "pipDocument") {
      if (data?.code === 200) {
        setRemove(false)
        toast.success("Deleted Document SuccessFully");
      }
      else {
        toast.error(data?.message)
      }
    }
    else {
      if (data?.code === 200) {
        toast.success("Deleted Document SuccessFully");
      }
      else {
        toast.error(data?.message)
      }
    }
  }

  // const handleNavigateToProject = () => {
  //   navigate('/ProjectsIndex')
  // }

  let handleProjectSubmit = async (e) => {

    e.preventDefault()
    // console.log(invoiceData)

    // // console.log(IsValid())
    if (isValid()) {

      let paramProjectList = {

        "tender_id": formInput.tender_id,
        "tender_code": formInput.tender_code,
        "bid_title": formInput.bid_title,
        "project_id": formInput.project_id,
        "project_code": formInput.project_code,
        "project_title": formInput.project_title,
        "project_manager_id": formInput.project_manager_id,
        "internal_team_member": formInput.internal_team_member,
        "contract_start_date": formInput.contract_start_date !== '' && formInput.contract_start_date?.toString().length !== 0 ? formInput.contract_start_date : null,
        "contract_closure_date": formInput.contract_closure_date !== '' && formInput.contract_closure_date?.toString().length !== 0 ? formInput.contract_closure_date : null,
        "contract_value": formInput.contract_value,
        "performance_security_date": formInput.performance_security_date !== '' && formInput.performance_security_date?.toString().length !== 0 ? formInput.performance_security_date : null,
        "performance_security_amount": formInput.performance_security_amount,
        "pip_approved": formInput.pip_approved,
        "budgeted_cost": formInput.budgeted_cost,
        "target_net_revenue": formInput.target_net_revenue,
        "project_sheet_submitted": formInput.project_sheet_submitted,
        "deliverables_no": formInput.deliverables_no,
        "project_certificate": formInput.project_certificate,
        "performance_security_refund": formInput.performance_security_refund,
        "comments": formInput.comments,
        "created_at": formInput.created_at,
        "updated_by": formInput.updated_by,
        "updated_at": formInput.updated_at,
        "created_by": formInput.created_at,
        "full_name": formInput.full_name,
        "user_id": formInput.user_id
      }
      const data = await dispatch(UpdateProjectDetail(Axios, paramProjectList))

      if (data?.code === 200) {
        toast.success('Updated Project SuccessFully');
        setTimeout(() => {
          navigate(-1)
        }, [2000])
      }
      else {
        toast.error(`${data?.message}`)
      }
    }
  }

  let handleChangeClosureDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        contract_closure_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        contract_closure_date: e
      })
    }
  }


  let handleChangeStartDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        contract_start_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        contract_start_date: e
      })
    }
  }
  let handleSecurityDate = (e) => {
    if (e === null || e === 'Invalid Date' || e === undefined || e === '') {
      setFormInput({
        ...formInput,
        performance_security_date: ''
      })
    }
    else {
      setFormInput({
        ...formInput,
        performance_security_date: e
      })
    }
  }

  let handleSelectChange = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    })
  }

  let handleChange = (e) => {
    console.log(e.target.name, e.target.value)

    setFormInput(
      {
        ...formInput,
        [e.target.name]: e.target.value
      })
  }

  // console.log(formInput, "formInput")
  // console.log(store)
  return (
    <>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={styles.rootContainer}>

        <div>
          <h2 className={styles.AddProjectTitle}>Project Management Form</h2>
        </div>

        <form autoComplete='off'><div className={styles.AddProjectBody}>

          <div className={styles.AddProjectItem}>
            <label>Project Title*</label>
            <div className={styles.ProjectInputWrapper}>

              <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="project_title" value={formInput.project_title} onChange={handleChange} error={error.project_title ? true : false} helperText={error.project_title ? error.project_title : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Project Manager*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.project_manager_id ? true : false}
                  value={formInput.project_manager_id}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="project_manager_id"
                >
                  {
                    store?.userListProject?.map((item, index) => (
                      <MenuItem value={item.user_id}>{item.full_name + ` (${item.user_code})`}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.project_manager_id ? error.project_manager_id : null}</FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Choose Tender*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl className={styles.ChoseTenderInput} sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.tender_id ? true : false} value={formInput.tender_id}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="tender_id"
                >
                  {
                    store?.tenderListProject?.map((item, index) => (
                      <MenuItem className={styles.ChoseTenderInput} value={item.tender_id}>{item.bid_title + ` (${item.tender_code})`}</MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.tender_id ? error.tender_id : null}</FormHelperText>
              </FormControl>
            </div>
          </div>


          <div className={styles.AddProjectItem}>
            <label>Internal Team Members</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField variant="outlined" className={styles.InputField} sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, '& .MuiFormHelperText-root': { fontSize: "10px" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="internal_team_member" value={formInput.internal_team_member} onChange={handleChange} error={error.internal_team_member ? true : false} helperText={error.internal_team_member ? error.internal_team_member : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Contract Start Date</label>
            <div className={styles.ProjectInputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  name="contract_start_date" value={formInput.contract_start_date}
                  onChange={handleChangeStartDate}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField helperText={error.contract_start_date ? error.contract_start_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}

                />
              </LocalizationProvider>
            </div>

          </div>

          <div className={styles.AddProjectItem}>
            <label>Contract Closure Date</label>
            <div className={styles.ProjectInputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  name="contract_closure_date" value={formInput.contract_closure_date}
                  onChange={handleChangeClosureDate}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField helperText={error.contract_closure_date ? error.contract_closure_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}


                />
              </LocalizationProvider>
            </div>

          </div>


          <div className={styles.AddProjectItem}>
            <label>Contract Value excluding GST*</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="contract_value" value={formInput.contract_value} onChange={handleChange} error={error.contract_value ? true : false} helperText={error.contract_value ? error.contract_value : null} />

            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Performance Security Date</label>
            <div className={styles.ProjectInputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  name="performance_security_date" value={formInput.performance_security_date}
                  onChange={handleSecurityDate}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField helperText={error.performance_security_date ? error.performance_security_date : null} size='small' {...params} error={false} sx={{ width: "100%", minWidth: '150px', '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" } }} />}


                />
              </LocalizationProvider>
            </div>

          </div>

          <div className={styles.AddProjectItem}>
            <label>Performance Security Amount</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="performance_security_amount" value={formInput.performance_security_amount} onChange={handleChange} error={error.performance_security_amount ? true : false} helperText={error.performance_security_amount ? error.performance_security_amount : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>PIP Approved*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.pip_approved ? true : false} helperText={error.pip_approved ? error.pip_approved : null}
                  value={formInput.pip_approved}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="pip_approved"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.pip_approved ? error.pip_approved : null}</FormHelperText>
              </FormControl>
            </div>
          </div>


          <div className={styles.AddProjectItem}>
            <label>Budgeted Cost*</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="budgeted_cost" value={formInput.budgeted_cost} onChange={handleChange} error={error.budgeted_cost ? true : false} helperText={error.budgeted_cost ? error.budgeted_cost : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Target Net Revenue*</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField className={styles.InputField} disabled={true} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="target_net_revenue"
                value={
                  (formInput.contract_value - formInput.budgeted_cost >= 0) ? formInput.contract_value - formInput.budgeted_cost : 0
                }
                onChange={handleChange} error={error.target_net_revenue ? true : false} helperText={error.target_net_revenue ? error.target_net_revenue : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Project Sheet Submitted*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.project_sheet_submitted ? true : false} helperText={error.project_sheet_submitted ? error.project_sheet_submitted : null}
                  value={formInput.project_sheet_submitted}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="project_sheet_submitted">
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }}>{error.project_sheet_submitted ? error.project_sheet_submitted : null}</FormHelperText>
              </FormControl>
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Deliverables No.</label>
            <div className={styles.ProjectInputWrapper}>
              <TextField className={styles.InputField} variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="deliverables_no" value={formInput.deliverables_no} onChange={handleChange} error={error.deliverables_no ? true : false} helperText={error.deliverables_no ? error.deliverables_no : null} />
            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Project Completion Certificate*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.project_certificate ? true : false} helperText={error.project_certificate ? error.project_certificate : null}
                  value={formInput.project_certificate}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="project_certificate">
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.project_certificate ? error.project_certificate : null}</FormHelperText>
              </FormControl>

            </div>
          </div>

          <div className={styles.AddProjectItem}>
            <label>Performance Security Refund*</label>
            <div className={styles.ProjectInputWrapper}>
              <FormControl sx={{ width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold" }, }} size="small" >
                <Select
                  error={error.performance_security_refund ? true : false} helperText={error.performance_security_refund ? error.performance_security_refund : null}
                  value={formInput.performance_security_refund}
                  className={styles.AddProjectItemSelect}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={handleSelectChange}
                  name="performance_security_refund">
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="N/A">N/A</MenuItem>
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }} >{error.performance_security_refund ? error.performance_security_refund : null}</FormHelperText>
              </FormControl>
            </div>
          </div>
        </div></form>



        <div className={styles.Seperator}></div>
        <div className={styles.AddProjectCheckListWrapper}>
          <div className={styles.AddProjectCheckList}>
            <div className={styles.AddProjectCheckListTitleWrapper}>
              <h2 className={styles.AddProjectCheckListTitle}>PIP Document</h2>
            </div>
            <div className={styles.AddProjectCheckListUploaded}>
              <div className={styles.AddProjectCkLstUpldBtn}>
                <label htmlFor="uploadChckLst1"><UploadOutlinedIcon />Upload</label>
                <input type="file" id="uploadChckLst1" name="pipDocument" onChange={handleFileSelect} />
              </div>

              <div className={styles.AddProjectCkLstUpldDocsWrapper}>
                {
                  formInput?.project_pipdocument_url?.map((item, index) => (
                    <div className={styles.AddProjectCkLstUpldDocs} key={index}>
                      <a href={item.file_path} download="pipDocument" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                        "_blank" : null
                      }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                      <ClearIcon fontSize='small' color='warning' style={{ cursor: "pointer" }} onClick={() => handleClearDocument("pipDocument", item, index)} />
                    </div>
                  ))
                }

              </div>
            </div>

          </div>
          <div className={styles.AddProjectCheckList}>
            <div className={styles.AddProjectCheckListTitleWrapper}>
              <h2 className={styles.AddProjectCheckListTitle}>Project Sheet</h2>
            </div>
            <div className={styles.AddProjectCheckListUploaded}>
              <div className={styles.AddProjectCkLstUpldBtn}>
                <label htmlFor="uploadChckLst2"><UploadOutlinedIcon />Upload</label>
                <input type="file" id="uploadChckLst2" name="projectSheet" onChange={handleFileSelect} />
              </div>

              <div className={styles.AddProjectCkLstUpldDocsWrapper}>
                {
                  formInput?.project_projectsheet_url?.map((item, index) => (
                    <div className={styles.AddProjectCkLstUpldDocs} key={index}>
                      <a href={item.file_path} download="projectSheet" target={allowedExtensions.includes((item?.file_path?.substring(item?.file_path?.lastIndexOf('.') + 1)).toLowerCase()) ?
                        "_blank" : null
                      }>{item?.file_path?.substring(item?.file_path?.lastIndexOf('/') + 1)}</a>
                      <ClearIcon fontSize='small' color='warning' style={{ cursor: "pointer" }} onClick={() => handleClearDocument("projectSheet", item, index)} />
                    </div>
                  ))
                }

              </div>



            </div>



          </div>
        </div>
        <div className={styles.InvoiceWrapper}>
          <AddChildTable invoiceData={invoiceData} setInvoiceData={setInvoiceData} projectDetail={formInput} setRootError={setError} />
        </div>


        <div className={styles.AddProjectWrapper}>
          <div className={styles.AddProjectLabel}><label>Notes</label></div>
          <input type="textarea" name='comments' autoComplete='off' className={styles.AddProjectNotes} onChange={handleChange} value={formInput.comments} />
        </div>
        <div className={styles.submitButtonWrapper}>
          <SubmitButton onClick={handleProjectSubmit}>Submit</SubmitButton>
        </div>
      </div>


      <DeletePromptProjectDocument remove={remove} setRemove={setRemove} file={deleteData} ToastShow={handleConfirmDeleteDocument}/>
    </>

  )
}

export default UpdateProject