import React, { useState, useEffect } from 'react'
import styles from "./styles/ctrlFldsMode.module.css";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddMode from './AddMode';
import { useDispatch, useSelector } from 'react-redux'
import { GetModeList, UpdateModeApi } from '../../../actions/controlFields/controlFieldsActions';
import { BsPencil } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateMode from './UpdateMode';
import { Card } from '@mui/material';
import { CancelButton, CtrlFldsSubmitButton } from '../../../utility/button/button';

const CtrlFldsMode = () => {
    const store = useSelector(state => state.ControlFields);
    const [modeList, setModeList] = useState([])
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    let [show, setShow] = useState(false)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        dispatch(GetModeList(Axios))
    }, [])

    useEffect(
        () => {
            setModeList(store?.modeList)
        }, [store.modeList])

    let toggleEdit = () => {
        setShow(!show)
    }
    const handleList = (mode, value) => {
        setModeList(modeList.map(item => item.mode_id === mode.mode_id ? {
            ...mode,
            mode_name: value
        } : item))

    }

    const handleCancel = () => {
        setShow(false)
        setModeList(store?.modeList)
    }
    const IsValid = () => {
        let success = true;
        const data = modeList.map(item => ((item?.mode_name === '') || item?.mode_name?.length === 0 || item?.mode_name === undefined || item?.mode_name === null) ? true : false);

        if (data.includes(true)) {
            success = false;
            toast.error("Mode Fields Cannot Be Empty!!")
        } else {
            success = true
        }
        return success
    }

    const handleSubmit = async () => {
        if (IsValid()) {
            setLoading(true)
            let param = {
                "list": modeList
            }

            const data = await dispatch(UpdateModeApi(Axios, param));

            if (data?.code === 200) {
            setLoading(false)
            setShow(false)
                toast.success('Updated modes SuccessFully')
            }
            else {
            setLoading(false)
            toast.error(`${data?.message}`)
            }

        }
    }
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={styles.CtrlFields}>
                {/* <div className={styles.CtrlFldTitle}>Admin Control Fields</div> */}
                <div className={styles.CtrlFldsBodyContainer}>
                    <div className={styles.CtrlFldsBodyContent}>
                        <div className={styles.CtrlFldsBodyTitle}>
                            <h2>Modes</h2>
                        </div>
                        <AddMode />
                        <div className={styles.Seperator} >
                            <div className={styles.SeperatorLine}></div>
                            <div onClick={toggleEdit} className={styles.editIcon} ><BsPencil /></div>
                        </div>
                        <div className={styles.CtrlFldsBody}>
                            <div className={styles.CtrlFldsBodyItems}>
                                {
                                    modeList?.map(
                                        (item, key) =>
                                        (
                                            show ?
                                                <UpdateMode item={item} handleList={handleList} /> :

                                                <Card className={styles.listCard}>{item.mode_name}</Card>
                                        )
                                    )
                                }

                            </div>
                            {
                                show === true ?
                                    <div className={styles.CtrlFldsSubmitEdit}>
                                         <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                    <CtrlFldsSubmitButton onClick={handleSubmit} loading={loading}>SUBMIT</CtrlFldsSubmitButton>
                                    </div> : null
                            }

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default CtrlFldsMode