import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from "./styles/addFolderModal.module.css";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddFolderDocument } from '../../actions/Documents/document.action';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';


function MyVerticallyCenteredModal(props) {
    const location = useLocation();
    const [folderText, setFolderText] = useState('')
    const dispatch = useDispatch()
    let Axios = useAxiosPrivate()
    const { toggleSet } = props

    const [error, setError] = useState({})

    let handleChange = (e) => {
        setFolderText(e.target.value)

    }
    const isValid = () => {
        let success = true;
        let error = {};
        if (!folderText?.trim()) {
            success = false
            error.folderName = "Please type a valid folder name!!";
        }
        setError(error)
        return success
    }


    let createNewFolder = async () => {
        if (isValid()) {
            let param = {
                "folder_name": folderText,
                "path": location?.pathname.substring(location?.pathname.indexOf('/') + 1)
            }
            console.log(param)
            const data = await dispatch(AddFolderDocument(Axios, param))
            if (data?.code === 201) {
                toast.success('Created Folder SuccessFully');
                setTimeout(() => {
                    setFolderText('');
                    toggleSet(false)
                }, [2000])

            }
            else {
                toast.error(`${data?.message}`)
            }
        }


    }
    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={styles.PopUpBox}
            >
                <Modal.Header closeButton className={styles.PopUpHeader}>
                    <Modal.Title className={styles.PopUpTitle}>
                        New Folder
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className={styles.addFolderInputWrapper}>
                        <TextField variant="outlined" sx={{ '& .MuiFormHelperText-root': { fontSize: "10px", fontWeight: "bold", color: "#d32f2f" }, width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} size="small" name="folderName" value={folderText} onChange={handleChange} error={error.folderName ? true : false} helperText={error.folderName ? error.folderName : null} />
                    </div>
                </Modal.Body>
                <div className={styles.PopUpSubmit}>
                    <button className={styles.PopUpSubmitButton} onClick={createNewFolder} >Create</button>
                </div>
            </Modal></>
    );
}
function AddFolderModal(props) {
    let { toggle, toggleSet, newFolderName, setnewFolderName } = props

    return (
        <>
            <MyVerticallyCenteredModal
                show={toggle}
                onHide={() => toggleSet(!toggle)}
                toggleSet={toggleSet}
            />
        </>
    );
}

export default AddFolderModal