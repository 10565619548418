import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, AddLoadingButton } from '../../utility/button/button'
import { AddAdminUser } from '../../actions/admin/adminAction.js'
import styles from './styles/AddUser.module.css'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddUser = () => {

    const dispatch = useDispatch()
    const Axios = useAxiosPrivate()
    const store = useSelector(state => state.Admin);
    const [roleList, setRoleList] = useState([]);
    const [loading,setLoading] = useState(false)
    const [role, setRole] = useState('')
    const [error, setError] = useState({})
    let [newUser, setNewUser] = useState({ full_name: "", email: "", role_id: 0 });
    const handleChange = (e) => {
        setNewUser({
            ...newUser,
            [e.target.name]: e.target.value
        })
    }

    const IsValid = () => {
        let success = true;
        let error = {}
        if (!newUser.full_name.trim()) {
            error.full_name = '*Username cannot be empty!';
            success = false
        }
        else if(newUser?.full_name?.toString()?.length>50){
            error.full_name = '*Only 50 characters Allowed!';
            success = false
        }

        if (!newUser.email.trim()) {
            error.email = '*Email cannot be empty!';
            success = false
        }
        else if(newUser.email?.toString().length>50){
            error.email = '*Only 50 characters Allowed!';
            success = false
        }
        else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newUser.email)){
            error.email = "Enter a Valid Email!!";
            success = false
        }
      
        
        if (!role.trim()) {
            error.level = '*please select a level';
            success = false
        }
        setError(error)
        return success
    }

    const handleAddNewUser = async (e) => {
        e.preventDefault()
        if (IsValid()) {
            setLoading(true)
            let param = {
                "full_name": newUser.full_name.replace(/\s+/g, ' ').trim(),
                "email": newUser.email.replace(/\s+/g, ' ').trim(),
                "role_id": newUser.role_id,
                "first_time": true
            }
          
           const data = await dispatch(AddAdminUser(Axios,param));

            if(data?.code===201){
                setLoading(false)
                setNewUser({ full_name: "", email: "", role_id: 0 })
                setRole('')
                   toast.success('Added User SuccessFully')
            }
            else{ 
                setLoading(false)
                 toast.error(`${data.message}`)
            }

        }
    }


    useEffect(() => {
        setRoleList(store?.roleList);
    }, [store])

    const handleLevelChange = (e) => {
        setRole(e.target.value);
        const role = roleList?.filter(item => item.role_name === e.target.value)
        setNewUser({
            ...newUser,
            [e.target.name]: role[0]?.role_id
        })
    }

    // console.log(store)
    return (
        <>
        <ToastContainer
    position="bottom-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
/>
    <form onSubmit={handleAddNewUser}>
        <div className={styles.Container} >
            <div className={styles.TextFieldContainer}>
                <div className={styles.TextWrapper}>
                    <TextField
                        name="full_name"
                        value={newUser.full_name}
                        size='small'
                        label="Name"
                        type="text"
                        autoComplete="off"
                        
                        onChange={handleChange}
                        className={styles.TextField}
                    />
                    {error.full_name && <label className={styles.error}>{error.full_name}</label>}
                </div>
                <div className={styles.TextWrapper}>
                    <TextField
                      
                        name="email"
                        value={newUser.email}
                        size='small'
                        label="Email"
                        type="email"
                        autoComplete="off"
                        onChange={handleChange}
                        className={styles.TextField}
                    />
                    {error.email && <label className={styles.error}>{error.email}</label>}
                </div>
                <div className={styles.TextWrapper}>
                    <FormControl  size="small" >
                        <InputLabel >Level</InputLabel>
                        <Select
                        sx={{ minWidth: 150 }}
                            value={role}
                            label="Level"
                            onChange={handleLevelChange}
                            className={styles.TextField}
                            name="role_id"
                        >
                            {
                                roleList?.map((item, index) => (
                                    <MenuItem value={item.role_name}>{item.role_name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {error.level && <label className={styles.error}>{error.level}</label>}
                </div>
                {/* <div className={styles.surpriseButton}>
                     <AddLoadingButton type="submit" size="small" loading={loading}>Add User</AddLoadingButton>
                </div> */}
            </div>
            <div className={styles.ButtonContainer}>
            <AddLoadingButton type="submit" size="small"  loading={loading}>Add User</AddLoadingButton>
            </div>
        </div>
    </form>
    </>
    )
}

export default AddUser