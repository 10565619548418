import React, { useEffect, useState } from 'react'
import styles from './styles/documentsDownloads.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import { Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Skeleton from '@mui/material/Skeleton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DocumentDeleteProjectDownloadsByID, GetProjectDocumentListByID, GetProjectDownloadList } from '../../actions/downloads/DownloadActions';
import DeleteAlert from './DeleteAlert';

const ProjectCard = ({ project_id }) => {
    let [ProjectDocs, setProjectDocs] = useState()
    let [loading, setLoading] = useState(false)
    let [deleteFileName,setDeleteFileName] = useState('')
    let Axios = useAxiosPrivate()
    let [fileDocDelete,setFileDocDelete] = useState({})
    let Arr = [1, 2, 3]
    let allowedExtensions = ['json', 'jpeg', 'img', 'pdf', 'txt', 'png', "jpg", 'xml', 'docx', 'doc', 'pptx', 'csv']
    const [open, setOpen] = useState(false);
   
    const handleClickOpen = (file) => {
        setOpen(true);
        setDeleteFileName(file?.file_path?.substring(file?.file_path?.lastIndexOf("/") + 1))
        setFileDocDelete(file)
      };
    
      const handleClose = () => {
        setOpen(false);
      };
  
      const ConfirmDelete = () => {
          handleClearDocument(fileDocDelete)
      }
      const handleClearDocument = async (item) => {
     
        let param = {
            "project_id": item?.project_id,
            "type": item?.type,
            "filename": item?.file_path,
            "pd_id": item.pd_id
          }
     
        const data = await DocumentDeleteProjectDownloadsByID(Axios, param);
          if (data?.code === 200) {
            GetProjectDocument(item?.project_id)
                handleClose()
              toast.success("Deleted Document SuccessFully");
            }
            else {
              toast.error(data?.message)
            }
        }

   
      useEffect( () => {
        GetProjectDocument(project_id)
    }, [project_id])


    const GetProjectDocument =async (projectId) => {
        let param = {
            'project_id': projectId
        }
        setLoading(true)
        let data = await GetProjectDocumentListByID(Axios, param)
        if (data?.code === 200) {
            setLoading(false)
            setProjectDocs(data?.project_document_list)
        }
    } 


    return (
        <>
            {
                loading && Arr?.map((item) => {
                    return (
                        <Card sx={{ padding: 1, width: "100%", display: "flex", justifyContent: "space-between", gap: "30px", border: "solid var(--color-border-blue) 1px" }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100%" animation="wave" />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={20} />
                        </Card>
                    )
                })
            }
            {

                loading===false ? ((ProjectDocs?.length!==0) ? ProjectDocs?.map(docItem => <div className={styles.ListDocument}>
                    <Card sx={{ padding: 1, width: "100%", display: "flex",flexDirection:"column",gap:"0px", border: "solid var(--color-border-blue) 1px" }}>
                    <div className={styles.DocLabelWrapper}>
                        <a href={docItem?.file_path} download="projectDoc" className={styles.DocLabel}
                            target={allowedExtensions.includes(docItem?.file_path?.substring(docItem?.file_path?.lastIndexOf('.') + 1).toLowerCase()) ? "_blank" : null}
                        >{docItem?.file_path?.substring(docItem?.file_path?.lastIndexOf("/") + 1)}
                        </a>
                        <ClearIcon color='warning'
                                onClick={() => { handleClickOpen(docItem) }}
                                className={styles.xMark}
                            />
                        </div>
                        <div className={styles.doctypeLabel}>{docItem.type}</div>
                    </Card>
                </div>)
                : 
                <label className={styles.NoDocsMessage}>NO DOCUMENTS FOUND</label>) : null
            }
<ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
            {open ? <DeleteAlert handleClose={handleClose} open={open} fileName={deleteFileName} ConfirmDelete={ConfirmDelete}/> : null} 
        </>
    )
}

export default ProjectCard