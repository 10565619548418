import React from 'react'
import { SubmitButton } from '../../utility/button/button'
import UnAuthorizedImage from "./images/UnAuthorizedLogo.png"
import styles from "./styles/unauthorized.module.css" 

const UnAuthorized = () => {
  return (
  <div className = {styles.unAuthorizedWrapper}>
      <div >
        <img className = {styles.unAuthorizedImage} src={UnAuthorizedImage} alt="Not Found"/>
    </div>
    <h3 className = {styles.unAuthorizedWarning}>No Authorization Found</h3>
    <h3 className = {styles.unAuthorizedWarning}>This Page is not Publically Available</h3>
    <h3 className = {styles.unAuthorizedWarning}>To Access it Please Login First</h3>
    
    <SubmitButton>Return to Home</SubmitButton>
  </div>
  )
}

export default UnAuthorized