import React, { useEffect, useState } from 'react'
import styles from './styles/documentsDownloads.module.css'
import { useDispatch, useSelector } from 'react-redux'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { GetProjectDownloadList} from '../../actions/downloads/DownloadActions';
import ProjectCard from './ProjectCard';


const SearchFilter = (data, columns, q) => {
  if (data !== undefined && data !== null && data.length !== 0) {
    return data.filter((row) =>
      columns.some(column => row[column] && row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : ('')))
  }
}

const ProjectDownloads = ({ searchText }) => {
 const [ProjectDownloadList,setProjectDownloadList] = useState([])
  let store = useSelector(state => state.Downloads);
  let dispatch = useDispatch()
  let Axios = useAxiosPrivate()
  let ProjectDownloadListSearched = SearchFilter(ProjectDownloadList, ['project_code'], searchText)

  useEffect(() => {
    dispatch(GetProjectDownloadList(Axios))
  }, [])
  useEffect(()=>{
    setProjectDownloadList(store?.projectDownloadList ? store?.projectDownloadList : null)
  },[store?.projectDownloadList])


  return (
    <div className={styles.rootContainer}>
      {ProjectDownloadListSearched?.map((elem, index) => {
        return <div className={styles.ListWrapper}>
          <div className={index % 2 === 0 ? styles.ListTitle : styles.ListTitleAlt}>
            <label>
              {elem?.project_code}
            </label>
          </div>
          <div className={index % 2 === 0 ? styles.ListBody : styles.ListBodyAlt}>
          <ProjectCard project_id = {elem?.project_id}/>
          </div>
        </div>
      })}
    </div>
  )
}

export default ProjectDownloads




